import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { translate, selectors as coreSelectors } from '../../../core';
import {
    getDevicesList,
    getRecommendedDevices,
    isAnyRecommendedConnected,
    getNumberOfConnectedDevices,
    getPlatformDevice
} from '../../selectors';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { actions as onboardingActions, selectors as onboardingSelectors } from '../../../onboarding';
import { actions as rewardsActions } from '../../../rewards';
import { getAppsDevices, syncDevice, submitHealthKitData } from '../../actions';
import { services as challengeServices } from '../../../challenges';

export default function WithChallengeRecommendedDevicesBase(WrappedComponent) {
    class ChallengeRecommendedDevicesBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            isAnyRecommendedConnected: PropTypes.bool.isRequired,
            recommendedDevices: PropTypes.array.isRequired,
            isAppOrDeviceConnected: PropTypes.bool,
            currentUser: PropTypes.object.isRequired,
            appsDevicesPoints: PropTypes.number,
            hasAppsDevicesTask: PropTypes.bool,
            device: PropTypes.object,
            challenge: PropTypes.object.isRequired,
        };

        static defaultProps = {
            isAppOrDeviceConnected: false,
            appsDevicesPoints: 0,
            hasAppsDevicesTask: false,
            device: null
        };

        constructor(props) {
            super(props);
            props.actions.getAppsDevices();
        }

        componentDidUpdate(prevProps) {
            const { isAppOrDeviceConnected, currentUser, hasAppsDevicesTask, appsDevicesPoints, actions } = this.props;
            if (isAppOrDeviceConnected && !prevProps.isAppOrDeviceConnected && hasAppsDevicesTask) {
                const userPoints = _.toInteger(_.get(currentUser, 'points', 0));
                const newPoints = userPoints + _.toInteger(appsDevicesPoints);
                actions.completeAppsDevicesStep();
                actions.updateUserPoints(newPoints);
            }
        }

        get challengeTypeLabel() {
            const { i18n, challenge } = this.props;
            return _.toLower(i18n.t(this.isChallengeType ? 'challenge' : challenge.challengeType));
        }

        get isChallengeType() {
            const { challenge } = this.props;
            const { isPersonal, isGoalType } = challengeServices.helper;
            return !isPersonal(challenge) && isGoalType(challenge);
        }

        get title() {
            return this.props.i18n.t('appsAndDevices.header');
        }
        get linkedText() {
            return this.props.i18n.t('appsAndDevices.linked', { challengeType: this.challengeTypeLabel });
        }

        get noLinkedCardTitle() {
            return this.props.i18n.t('appsAndDevices.noLinked');
        }

        get noLinkedCardText() {
            return this.props.i18n.t('appsAndDevices.link');
        }
        render() {
            return this.props.recommendedDevices.length > 0 ? (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    linkedText={this.linkedText}
                    noLinkedCardTitle={this.noLinkedCardTitle}
                    noLinkedCardText={this.noLinkedCardText}
                    challengeTypeLabel={this.challengeTypeLabel}
                />
            ) : null;
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        isAppOrDeviceConnected: !!getNumberOfConnectedDevices(state),
        currentUser: coreSelectors.getCurrentUser(state),
        appsDevicesPoints: onboardingSelectors.getAppsDevicesPoints(state),
        hasAppsDevicesTask: onboardingSelectors.hasAppsDevicesTask(state),
        devicesList: getDevicesList(state)[0].data,
        recommendedDevices: getRecommendedDevices(state, ownProps.recommendedTrackingApps),
        isAnyRecommendedConnected: isAnyRecommendedConnected(state, ownProps.recommendedTrackingApps),
        device: getPlatformDevice(state)
    });

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators({ ...onboardingActions,
            ...rewardsActions,
            getAppsDevices,
            submitHealthKitData,
            syncDevice
        }, dispatch)
    });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ChallengeRecommendedDevicesBase));
}

const IMAGE_SIZE = spacing.s3;
export const WATCH_ICON_WIDTH = 18;
export const MOBILE_ICON_WIDTH = 15;
export const ENTITY_CARD_BORDER_RADIUS = 8;
export const SHADOW = { opacity: 0.15, height: 8 };

export const styles = {
    title: {
        ...appFonts.lgBold,
        marginBottom: spacing.s1,
    },
    withBorder: {
        borderTopWidth: 1,
        paddingTop: spacing.s5,
        borderTopColor: baseColors.grey70,
    },
    linkedText: {
        ...appFonts.smRegular,
        color: baseColors.grey20,
        marginBottom: spacing.s5,
    },
    topListSeparator: {
        height: 1,
        backgroundColor: baseColors.grey70,
    },
    noLinkedCardTitle: {
        ...appFonts.mdMedium,
        color: baseColors.black,
    },
    noLinkedCardText: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        marginTop: spacing.s1,
    },
    deviceImage: {
        width: IMAGE_SIZE,
        height: IMAGE_SIZE,
        marginRight: spacing.s1,
    },
    imagesWrapper: {
        marginTop: spacing.s3,
        marginBottom: spacing.s0,
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: spacing.s3,
        marginBottom: spacing.s7,
    },
    mRight: {
        marginRight: spacing.s3,
    },
    bonusMarkContainer: {
        marginBottom: spacing.s3,
    },
    deviceBonus: {
        ...appFonts.smRegular,
    },
};
