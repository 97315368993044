import React, { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { spacing } from '../../../../styles';
import { parsers, translate, selectors as coreSelectors } from '../../../core';
import { REGISTRATION_STEPS, USER_REGISTRATION_STEPS } from '../../constants';
import { getCompany, isRetail, getDisclaimer, getTemporaryAuthentication, isLoadingDisclaimer } from '../../selectors';

export default function WithDisclaimerBase(WrappedComponent) {
    class DisclaimerBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            company: PropTypes.object.isRequired,
            standalone: PropTypes.bool,
            isRetail: PropTypes.bool,
            isLoading: PropTypes.bool,
            callback: PropTypes.func.isRequired,
            avoidDataSubmissionCallback: PropTypes.func,
            disclaimer: PropTypes.object.isRequired,
            temporaryAuthentication: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            hasTopBackButton: PropTypes.bool
        };

        static defaultProps = {
            standalone: false,
            isRetail: false,
            isLoading: false,
            avoidDataSubmissionCallback: undefined,
            hasTopBackButton: false
        };

        static getDerivedStateFromProps(nextProps, prevState) {
            if (nextProps.disclaimer.content && prevState.disclaimerContent !== nextProps.disclaimer.content) {
                const result = parsers.replaceBrAndATagsWithItsHrefAndReturnLinks(nextProps.disclaimer.content);
                return {
                    disclaimerContent: nextProps.disclaimer.content,
                    disclaimerParsedText: result.text,
                    disclaimerParsedLinks: result.links
                };
            }
            return null;
        }

        constructor(props) {
            super(props);
            this.state = { checked: false, disclaimerContent: '', disclaimerParsedText: '', disclaimerParsedLinks: {} };
            this.getDisclaimer();
            this._initActions();
        }

        _initActions() {
            if (!this.props.avoidDataSubmissionCallback) {
                setTimeout(() => {  this.props.actions.updateUser({ currentRegistrationStep: USER_REGISTRATION_STEPS.disclaimer }); }, 1000);
            }
        }

        getDisclaimer = () => {
            if (this.props.isRetail) {
                this.props.actions.getDisclaimer({ disclaimerId: this.props.company.partnerId, isRetail: true });
            } else if (!this.props.standalone) {
                this.props.actions.getDisclaimer({ disclaimerId: this.props.company.companyId });
            } else {
                this.props.actions.getDisclaimer({ disclaimerId: this.props.temporaryAuthentication.companyId });
            }
        };

        submit = () => {
            if (this.props.standalone) {
                this.props.actions.verifyEmail();
            } else if (this.props.avoidDataSubmissionCallback) {
                this.props.avoidDataSubmissionCallback({ disclaimerVerified: true }, REGISTRATION_STEPS.disclaimer);
            } else {
                this.props.callback(REGISTRATION_STEPS.disclaimer);
            }
        };

        toggle = () => {
            this.setState(({ checked }) => ({ checked: !checked }));
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    toggle={this.toggle}
                    disclaimerText={this.state.disclaimerParsedText}
                    disclaimerContent={this.state.disclaimerContent}
                    checked={this.state.checked}
                    links={this.state.disclaimerParsedLinks}
                    submit={this.submit}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            hasTopBackButton: ownProps.hasTopBackButton || _.get(ownProps, 'match.params.hasTopBackButton') || _.get(ownProps, 'route.params.hasTopBackButton'),
            temporaryAuthentication: getTemporaryAuthentication(state),
            disclaimer: getDisclaimer(state),
            company: getCompany(state),
            isLoading: isLoadingDisclaimer(state),
            isRetail: isRetail(state),
            isTelus: coreSelectors.isTelus(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(DisclaimerBase));
}

export const styles = {
    footer: {
        marginBottom: spacing.s3
    }
};
