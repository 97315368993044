import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { autoLogin } from '../../services/sso';
import { LOGIN_ITEM_IDS as ITEM_IDS, LOGIN_STEPS as STEPS, REGISTRATION_STEPS, SAML_SSO_STATUSES } from '../../constants';
import * as actions from '../../actions';
import { getLocalLoginStep, getSsoDetails, isPartnerDomain, hasSsoLoginUrl, isSso, isMultipleSignIn,
    isLogining, getCompany } from '../../selectors';
import { selectors as coreSelectors, Platform, PLATFORMS } from '../../../core';

export default function WithSignInFlowBase(WrappedComponent) {
    class SignInFlowBase extends PureComponent {
        static propTypes = {
            isPartnerDomain: PropTypes.bool.isRequired,
            step: PropTypes.string,
            company: PropTypes.object.isRequired,
            ssoDetails: PropTypes.object.isRequired,
            hasSsoLoginUrl: PropTypes.bool.isRequired,
            actions: PropTypes.object.isRequired,
            isMultipleSignIn: PropTypes.bool.isRequired,
            isLogining: PropTypes.bool,
            isLiveBetter: PropTypes.bool.isRequired
        };

        static defaultProps = {
            step: undefined,
            isLogining: false
        };

        constructor(props) {
            super(props);
            this.state = {};
            this.step = props.step || this.defaultStep;
        }

        get isWeb() {
            return Platform.OS === PLATFORMS.web;
        }

        get defaultStep() {
            return !this.isNewLoginFlow ? STEPS.signInSelection : STEPS.signInEmail;
        }

        get isNewLoginFlow() {
            return !this.props.isLiveBetter && !this.isWeb;
        }

        // eslint-disable-next-line react/sort-comp
        determineRegistrationStep = (completedStep, userSelection, props) => {
            switch (completedStep) {
                case STEPS.programDomain: {
                    if (userSelection === ITEM_IDS.findProgramDomain) {
                        return STEPS.findProgramDomain;
                    }
                    if (this.props.isPartnerDomain) {
                        return STEPS.corporatePin;
                    }
                    return STEPS.signInSelection;
                }
                case STEPS.findProgramDomain: {
                    return STEPS.programDomain;
                }
                case STEPS.corporatePin: {
                    return STEPS.signInSelection;
                }
                case STEPS.signInSelection: {
                    if (userSelection === ITEM_IDS.advanced) {
                        return STEPS.programDomain;
                    }
                    if (userSelection === ITEM_IDS.facebook || userSelection === ITEM_IDS.google || userSelection === ITEM_IDS.apple) {
                        return STEPS.autoLogin;
                    }
                    if (userSelection === ITEM_IDS.saml) {
                        if (_.get(this.props.ssoDetails, 'status') === SAML_SSO_STATUSES.login) {
                            return STEPS.autoLogin;
                        } else if (_.get(this.props.ssoDetails, 'status') === SAML_SSO_STATUSES.registration) {
                            return STEPS.register;
                        }
                        return STEPS.signInSelection; // if error stay on same page ?
                    }
                    return STEPS.signIn;
                }
                case STEPS.signInEmail: {
                    if (userSelection === ITEM_IDS.saml) {
                        if (_.get(this.props.ssoDetails, 'status') === SAML_SSO_STATUSES.login) {
                            return STEPS.autoLogin;
                        } else if (_.get(this.props.ssoDetails, 'status') === SAML_SSO_STATUSES.registration) {
                            return STEPS.register;
                        }
                        return STEPS.signInEmail; // if error stay on same page
                    }
                    else if (userSelection === ITEM_IDS.register) {
                        return STEPS.register;
                    }
                    return STEPS.signInPassword;
                }
                default:
                    break;
            }
        };

        openNextPage = (step, userSelection = '', props = {}) => {
            const nextStep = this.determineRegistrationStep(step, userSelection, props);
            console.log(`SignInFlowBase::openNextPage: ${step} userSelection: ${userSelection} nextStep: ${nextStep}`);
            if (nextStep === STEPS.autoLogin) {
                autoLogin(_.isEmpty(props) ? this.props.ssoDetails : props, this.props.actions, this.props.company, true);
                return;
            } else if (nextStep === STEPS.register) {
                this.switchToSignUp();
                return;
            }
            if (!this.wrapped || !this.wrapped.openNextPage) {
                throw new Error('openNextPage should be implemented in wrapped component');
            }
            this.wrapped.openNextPage(nextStep, userSelection, { ...props, isAdvancedShown: false });
        };

        switchToSignUp = () => _.has(this, 'wrapped.switchToSignUp') &&
            this.wrapped.switchToSignUp({ step: REGISTRATION_STEPS.register });

        saveRef = ref => (this.wrapped = ref);

        updateLocalLoginStep = nextStep => {
            this.props.actions.updateLocalLoginStep(nextStep);
        };

        get isLogining() {
            return this.props.isLogining && this.step !== STEPS.signIn;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    step={this.step}
                    openNextPage={this.openNextPage}
                    updateLocalLoginStep={this.updateLocalLoginStep}
                    isLogining={this.isLogining}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            step: ownProps.step || _.get(ownProps, 'match.params.step') || _.get(ownProps, 'route.params.step'),
            ssoDetails: getSsoDetails(state),
            isSSO: isSso(state),
            company: getCompany(state),
            hasSsoLoginUrl: hasSsoLoginUrl(state),
            isPartnerDomain: isPartnerDomain(state),
            localStep: getLocalLoginStep(state),
            isMultipleSignIn: isMultipleSignIn(state),
            isLogining: isLogining(state),
            isAdvancedShown: ownProps.isAdvancedShown || _.get(ownProps, 'location.state.isAdvancedShown'),
            isLiveBetter: coreSelectors.isLiveBetter(state),
        };
    }
    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(SignInFlowBase);
}