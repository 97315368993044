import axios from 'axios';
import _ from 'lodash';
import { getBaseUrl } from '../core';

const API_ENDPOINTS = {
    devices: 'users/apps_devices',
    addLocalDevice: 'users/apps_devices'
};

export function getAppsDevices() {
    return axios.get(getBaseUrl(API_ENDPOINTS.devices));
}

export function addIosDevice(params) {
    return axios.post(getBaseUrl(API_ENDPOINTS.addLocalDevice), params);
}

export function submitHealthKitData(params) {
    return axios.put(getBaseUrl(API_ENDPOINTS.devices), params);
}

export function disconnectDevice(vendorId) {
    const deleteURI = getBaseUrl(`${API_ENDPOINTS.devices}/${vendorId}`);
    return axios.delete(deleteURI, { timeout: 12000 });
}

export function syncDevice(device) {
    const item = _.pick(device, ['data', 'deviceId', 'vendorId', 'vendorName']);
    return axios.put(getBaseUrl(API_ENDPOINTS.devices), item);
}
