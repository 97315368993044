const ROUTES_CORE = Object.freeze({
    home: () => '/',
    userProfile: (id = ':id') => `/users/${id}`,
    toast: () => 'Core.Toast',
    selectModal: () => 'Core.SelectModal',
    actionSheet: () => 'Core.ActionSheetModal',
    webView: () => 'Core.WebView',
    infoModal: () => 'Core.InfoModal',
    helperModal: () => 'Core.HelperModal',
    deletionWarning: () => 'Core.DeletionWarning',
    appRatingAlerts: () => 'Core.AppRatingAlerts',
    noConnection: () => 'Core.NoConnection',
    createFlow: () => 'Core.CreateFlow',
    icon: () => 'Core.Icon',
    videoPlayer: () => 'Core.VideoPlayer',
});

const ROUTES_OTHER = Object.freeze({
    explore: () => '/explore',
    blank: () => '/blank',
    plusTab: () => 'Other.PlusTab',
    plusTabModal: () => 'Other.PlusTabModal',
    earnPointsInfo: () => 'Other.DashboardEarnPointsInfo'
});

const ROUTES_AUTH = Object.freeze({
    welcome: () => '/welcome',
    signInFlow: () => '/signin-flow',
    signInStep: (step = ':step') => `/signin-flow/${step}`,
    signup: () => '/signup',
    signUpWeb: () => '/sign-up',
    signupStep: (step = ':step') => `/signup/${step}`,
    signout: () => '/signout',
    resetPassword: () => '/reset-password',
    changePassword: () => '/change-password',
    linkAccounts: () => '/link-accounts',
    corporateAccountLink: () => '/link-corporate-account',
    corporateAccountLinkStep: (step = ':step') => `/link-corporate-account/${step}`,
    generalLoggedIn: () => '/general-logged-in',
    generalLoggedOut: () => '/general-logged-out',
    employeeID: () => '/employeeID',
    disclaimer: () => '/disclaimer',
    HRISCountrySelection: () => '/HRISCountrySelection'
});

const ROUTES_FEEDS = Object.freeze({
    feeds: () => '/feeds',
    feedCreate: () => '/feeds/create',
    feedDetailsEdit: (id = ':streamItemId') => `/feeds/${id}/edit`,
    feedDetails: (id = ':streamItemId') => `/feeds/${id}`,
    score: () => '/score',
    leaderboard: () => '/leaderboard',
    yourProgress: () => '/your-progress',
    feedPostEditor: () => 'Feeds.FeedPostEditor',
});

const ROUTES_COMMENTS_LIKES = Object.freeze({
    commentEdit: () => '/comment/edit',
    likes: () => '/likes',
    reactionsModal: () => '/reactionsModal',
    reactionsList: () => '/reactionsList'
});

const ROUTES_NOTIFICATIONS = Object.freeze({
    notifications: () => '/notifications',
    priorityNotifications: () => '/priority-notifications',
    priorityNotificationsItem: () => '/priority-notification',
});

const ROUTES_ONBOARDING = Object.freeze({
    interests: () => '/interests',
    objectives: () => '/objectives',
    topics: () => '/topics',
    EAPSelectOptions: () => '/EAP-select-options',
    EAPPrivacyPolicy: () => '/EAP-privacy-policy',
    EAPContact: () => '/EAP-contact',
    userGoals: () => '/user-goals',
    reachGoal: () => '/reach-goal',
    healthSurvey: () => '/health-survey',
    welcomeUser: () => '/welcome-user',
    pointsDescription: () => '/points-description',
    sampleTaskBreathe: () => '/sample-task',
    chooseGoal: () => '/choose-goal',
    onboardingFlow: () => '/onboardingFlow',
    onboarding: () => '/onboarding',
    onboardingFlowStep: (step = ':step') => `/onboarding/${step}`,
    onboardingDeviceDetails: (vendorId = ':vendorId') => `/onboarding/apps-devices/${vendorId}`,
    onboardingChallengeDetails: (challengeId = ':challengeId') => `/onboarding/challenges/${challengeId}`,
    onboardingGoalActivityHistory: (challengeId = ':challengeId') => `/onboarding/challenges/${challengeId}/goal-activity-history`,
    updatedPolicyPopup: () => '/updatedPolicyPopup',
});

const ROUTES_SETTINGS = Object.freeze({
    me: () => '/me',
    settings: () => '/settings',
    notificationsEmailsSettings: () => '/settings/notifications_emails',
    legals: (type = ':type') => `/legals/${type}`,
    privacySettingsItem: (slug = ':slug') => `/settings/privacy/${slug}`,
    privacySettings: () => '/settings/privacy',
    feedback: (slug = ':slug') => '/feedback',
    profileEditor: () => '/me/editor',
    nameEditor: () => '/me/editor/name',
    personalEditor: () => '/me/editor/personal',
    locationEditor: () => '/me/editor/location',
    biometricsEditor: () => '/me/editor/biometrics',
    wellbeingInfo: () => '/me/wellbeingInfo',
    wellbeingScore: () => '/me/wellbeingScore',
    unsubscribeEmailsWithoutLogin: () => '/unsubscribe-emails',
    signoutSSO: () => '/signout-sso',
    smartModeSettings: () => '/settings/smartMode',
    account: () => '/settings/account',
    healthScoreFeedbackModal: () => '/settings/HealthScoreFeedbackModal',
    more: () => '/more',
    helpAndSupport: () => '/support',
    familyMembers: () => '/settings/familyMembers',
    inviteFamilyMember: () => '/settings/inviteFamilyMember',
});

const ROUTES_APPS_DEVICES = Object.freeze({
    appsDevices: () => '/apps-devices',
    deviceDetails: (vendorId = ':vendorId') => `/apps-devices/${vendorId}`,
    deviceActivities: (vendorId = ':vendorId') => `/apps-devices/${vendorId}/activites`,
    onboardingDeviceActivities: (vendorId = ':vendorId') => `/onboarding/apps-devices/${vendorId}/activites`,
    deviceStatus: () => '/deviceStatus'
});

const ROUTES_COMMUNITIES = Object.freeze({
    communities: () => '/communities',
    createCommunity: () => '/communities/create',
    createCommunityStep: (step = ':step') => `/communities/create/${step}`,
    communityDetails: (id = ':id') => `/communities/${id}`,
    communitiesList: (type = ':type') => `/communities/list/${type}`,
    inviteToCommunity: (entityId = ':entityId', entity = ':entity') => `/${entity}/${entityId}/invite`,
    editCommunity: (id = ':id') => `/communities/${id}/edit`,
    communityMembers: (id = ':id') => `/communities/${id}/members`,
});

const ROUTES_REWARDS = Object.freeze({
    achievementDetails: (achievementId = ':achievementId') => `/achievements/details/${achievementId}`,
    myAchievements: () => '/achievements',
    rewards: () => '/rewards',
    rewardsDetails: () => '/rewards/details', // where is id?
    productDetails: (productId = ':productId') => `/rewards/product/${productId}`,
    productsList: () => '/rewards/products',
    orderRedemptionConfirmation: () => '/rewards/order-redemption-confirmation',
    orderShippingAddress: () => '/rewards/order-shipping-address',
    orderDetails: (orderId = ':orderId') => `/rewards/order/${orderId}`,
    earnPartnersDetails: (partnerId = ':partnerId') => `/rewards/earn-partners/${partnerId}`,
    earnPartnersList: () => '/rewards/earn-partners',
    rewardsLearn: () => '/rewards/learn',
    howToEarn: () => '/rewards/how-to-earn'
});

const ROUTES_EVENTS = Object.freeze({
    events: () => '/events',
    eventsAll: () => '/eventsAll',
    createEvent: () => '/events/create',
    eventDetails: (id = ':id') => `/events/${id}`,
    eventsList: (type = ':type', userId = ':userId') => `/events/${type}/${userId}`,
    inviteToEvent: (entityId = ':entityId', entity = ':entity') => `/${entity}/${entityId}/invite`,
    editEvent: (id = ':id') => `/events/${id}/edit`,
    eventMembers: (id = ':id') => `/events/${id}/members`,
});

const ROUTES_CHALLENGES = Object.freeze({
    previousGoals: () => '/previous-goals',
    challengeDetails: (challengeId = ':challengeId') => `/challenges/${challengeId}`,
    goalActivityHistory: (challengeId = ':challengeId') => `/challenges/${challengeId}/goal-activity-history`,
    goalActivityHistoryDetails: (challengeId = ':challengeId') => `/challenges/${challengeId}/goal-activity-history-item`,
    competitionActivityHistory: (challengeId = ':challengeId') => `/challenges/${challengeId}/competition-activity-history`,
    competitionActivityHistoryDetails: (challengeId = ':challengeId') => `/challenges/${challengeId}/competition-activity-history-item`,
    challengeMembers: (challengeId = ':challengeId') => `/challenges/${challengeId}/members`,
    joinTeam: (challengeId = ':challengeId') => `/challenges/${challengeId}/join-team`,
    teamDetails: (challengeId = ':challengeId', entityId = ':entityId') => `/challenges/${challengeId}/teams/${entityId}`,
    teamEdit: (challengeId = ':challengeId', teamId = ':teamId') => `/challenges/${challengeId}/teams/${teamId}/edit`,
    teamCreate: (challengeId = ':challengeId') => `/challenges/${challengeId}/teams/create`,
    inviteToTeam: (challengeId = ':challengeId', entityId = ':entityId', entity = ':entity') => `/challenges/${challengeId}/${entity}/${entityId}/invite`,
    inviteToChallenge: (entityId = ':entityId', entity = ':entity') => `/${entity}/${entityId}/invite`,
    challenges: () => '/challenges',
    challengesByType: (type = ':type') => `/challenges-type/${type}`,
    activityLog: () => '/activity-log',
    activityLogModal: () => '/activity-log-modal',
    createChallenge: () => '/create-challenge',
    createChallengeStep: (step = ':step') => `/create-challenge/${step}`,
    challengeRewardDetails: (challengeId = ':challengeId') => `/challenges/${challengeId}/reward`,
    leaderboardChallengesEmpty: () => 'Challenges.LeaderboardChallengesEmpty',
    activityTrackingModal: () => 'Challenges.ActivityTrackingModal',
    activityTrackingList: () => 'Challenges.ActivityTrackingList',
    activityTrackingCalendar: () => 'Challenges.ActivityTrackingCalendar',
    trackActivityForm: () => 'Challenges.TrackActivityForm',
    trackActivityDatepicker: () => 'Challenges.TrackActivityDatepicker',
    overTrackingModal: () => 'Challenges.OverTrackingModal',
    chooseActivityList: () => 'Challenges.ChooseActivityList',
    congratsFirstTrackChallenge: () => 'Challenges.CongratsFirstTrackChallenge',
    goalInfo: () => 'Challenges.GoalInfo',
    joinTeamList: () => 'Challenges.TeamList',
    editChallengeImage: (challengeId = ':challengeId') => `/challenges/${challengeId}/editImage`,
    setPersonalGoal: () => '/setPersonalGoal',
    teamChallengeActionSheet: (challengeId = ':challengeId') => `/teamChallengeActionSheet/${challengeId}`,
});

const ROUTES_CONTENT = Object.freeze({
    content: () => '/libraries',
    contentList: (category = ':category', type = ':type') => `/libraries/${type}/${category}`,
    contentDetails: (id = ':id') => `/libraries/${id}`,
});

const ROUTES_RESOURCES = Object.freeze({
    resourcesList: () => '/resources'
});

const ROUTES_INVITATIONS = Object.freeze({
    invitationsList: () => '/invitationsList',
    findFriendsAndFamily: () => '/find-friends-and-family',
    inviteByEmail: () => '/inviteByEmail'
});

const ROUTES_TOURS = Object.freeze({
    tourLayout: () => '/tour',
    newFeatureModal: () => 'Tours.NewFeatureModal',
});

const ROUTES_DEEPLINKS = Object.freeze({
    // mediback requirements list
    streams: () => '/streams',
    userProf: () => '/user/profile',
    userDevices: () => '/user/devices',
    userObjectives: () => '/user/objectives',
    userStats: () => '/user/stats',
    userWellbeing: () => '/user/wellbeing',
    userWellbeingSurvey: () => '/user/wellbeingsurvey',
    userAccounts: () => '/user/accounts',
    partners: () => '/partners',
    partnerView: (id = ':partnerId') => `/partners/view/${id}`,
    groups: () => '/groups',
    groupView: (id = ':id') => `/groups/view/${id}`,
    groupAccept: (id = ':id') => `/groups/accept/${id}`,
    // challenges: () => '/challenges',
    challengeView: (id = ':challengeId') => `/challenges/view/${id}`,
    // rewards: () => '/rewards',
    rewardsTransactions: () => '/rewards/transactions',

    // other links where defined in screens.js for mobile are not included to sublist above
    userDevicesRedirect: () => '/user/devices_redirect',
    library: () => '/library',
    libraryView: (id = ':id') => `/library/view/${id}`,
    // events: () => '/events',
    eventView: (id = ':id') => `/events/view/${id}`,
    streamView: (id = ':streamItemId') => `/streams/view/${id}`,
    userAchievementsRewards: () => '/user/achievements_rewards',
    homeLegals: (type = ':type') => `/home/${type}`,
    emailsUnsubscribe: () => '/emails/unsubscribe',
    userPrivacy: () => '/users/index/privacy',
});

const ROUTES = Object.freeze({
    ...ROUTES_CORE,
    ...ROUTES_OTHER,
    ...ROUTES_AUTH,
    ...ROUTES_FEEDS,
    ...ROUTES_COMMENTS_LIKES,
    ...ROUTES_NOTIFICATIONS,
    ...ROUTES_ONBOARDING,
    ...ROUTES_SETTINGS,
    ...ROUTES_APPS_DEVICES,
    ...ROUTES_COMMUNITIES,
    ...ROUTES_REWARDS,
    ...ROUTES_CHALLENGES,
    ...ROUTES_EVENTS,
    ...ROUTES_CONTENT,
    ...ROUTES_RESOURCES,
    ...ROUTES_INVITATIONS,
    ...ROUTES_TOURS,
    ...ROUTES_DEEPLINKS
});

export default ROUTES;
