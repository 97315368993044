import moment from 'moment';
import _ from 'lodash';
import { healthKit, openUrl, Platform, PLATFORMS, tracker } from '../../../core';
import { HEALTH_KIT } from '../../constants';
import systemBuildVariant, { BUILDS } from '../../../../config/buildVariant';
import i18n from '../../../../i18n';

export function onDeviceConnect(device, addIosDevice, startConnecting = null, callback = null) {
    const vendor = _.get(device, 'vendor');
    if (Platform.OS === PLATFORMS.web) {
        tracker.logEvent('apps_devices', { event: 'connect', vendor, platform: 'web' });
        if (vendor !== HEALTH_KIT) {
            const origin = encodeURIComponent(window.location.origin);
            const connectURI = `${device.loginUrl}?isMobileApp=0&origin=${origin}`;
            window.open(connectURI, 'popup', 'width=600,height=1000,scrollbars=yes');
        }
    }
    else {
        tracker.logEvent('apps_devices', { event: 'connect', vendor, platform: 'mobile' });

        if (vendor !== HEALTH_KIT) {
            const loginUrl = _.get(device, 'loginUrl');
            if (loginUrl) {
                const origin = encodeURIComponent((systemBuildVariant === BUILDS.telus) ? 'teluswellbeing://' : 'sproutatwork://');
                const connectURI = `${loginUrl}?isMobileApp=1&origin=${origin}`;
                openUrl(connectURI);
            }
        }
        else {
            if (startConnecting) {
                startConnecting();
            }
            if (vendor === HEALTH_KIT) {
                healthKit.connectHealthKit(device, addIosDevice, callback);
            }
        }
    }
}

export function onDeviceDisconnect(device) {
    tracker.logEvent('apps_devices', { event: 'disconnect', vendor: _.get(device, 'vendor') });
}

export function onDeviceSync(device, syncDevice, submitHealthKitData, showToast = true) {
    const vendor = _.get(device, 'vendor');
    tracker.logEvent('apps_devices', { event: 'sync', vendor });
    const isManualSync = true;
    if (vendor !== HEALTH_KIT) syncDevice(device);
    else if (vendor === HEALTH_KIT) {
        submitHealthKitData(device, isManualSync, showToast);
    }
}

export function deviceHasWarning(device) {
    if (!device) return false;

    if (device.notifyWarning !== undefined) {
        return device.notifyWarning;
    }

    return (moment().diff(moment(device.lastSyncDate), 'hours') >= 72);
}

export function deviceHasError(device) {
    return (device.notifyDisconnected === '1');
}

export function deviceStatusMuteHasExpired(device, deviceWarning) {
    if (deviceWarning && deviceWarning.nextWarningDate !== undefined) {
        return (moment().diff(moment(deviceWarning.nextWarningDate), 'days') >= 0);
    }

    return true;
}

export function filterUnsupportedDevices(allDevices) {
    let supported = allDevices;
    if (!supported) return [];

    if (Platform.OS === PLATFORMS.android) {
        supported = filterDevicesByVendor(supported, HEALTH_KIT);
    }
    return supported;
}

export function filterDevicesByVendor(devices, vendor) {
    return _.filter(devices, device => _.get(device, 'vendor') !== vendor);
}

export function deviceStatusString(device) {
    let statusText = '';
    if (device.isSyncing) {
        statusText = `${i18n.t('syncing')}...`;
    } else if (deviceHasError(device) || deviceHasWarning(device)) {
        statusText = i18n.t('appsDevices.deviceItem.unableToSync');
    } else {
        const syncDate = device.lastSyncDate;
        if (syncDate) {
            const timestamp = syncDate ? moment(syncDate).fromNow() : '--';
            statusText = `${i18n.t('lastSync')} ${timestamp}`;
        } else {
            statusText = `${i18n.t('syncing')}...`;
        }
    }

    return statusText;
}
