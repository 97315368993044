import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ActivityLogItem from '../ActivityLogItem';
import { layoutStyle, spacing, baseColors } from '../../../../styles';
import tracker from '../../../core/services/tracker/tracker';
import { components as Core, AsyncComponent, Modal } from '../../../core';
import WithActivityLogListBase, { styles as baseStyles } from './ActivityLogListBase';

class ActivityLogList extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        canLoadMore: PropTypes.bool.isRequired,
        onEndReached: PropTypes.func.isRequired,
        getDateString: PropTypes.func.isRequired,
        trackButtonText: PropTypes.string.isRequired,
        noActivitiesTitle: PropTypes.string.isRequired,
        activitiesToRender: PropTypes.array.isRequired,
        encouragementText: PropTypes.string.isRequired,
        itemHasSwipeout: PropTypes.bool,
        emptyModalComponent: PropTypes.element,
        overflow: PropTypes.bool,
        isInModal: PropTypes.bool,
        scrollParentRef: PropTypes.oneOfType([PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ])
    };

    static defaultProps = {
        itemHasSwipeout: true,
        emptyModalComponent: null,
        overflow: undefined,
        isInModal: undefined,
        scrollParentRef: undefined
    };

    get emptyComponent() {
        const { noActivitiesTitle, trackButtonText, emptyModalComponent, encouragementText } = this.props;
        return (
            emptyModalComponent ? emptyModalComponent : (
                <div className={css(layoutStyle.flexColumnCenter, styles.emptyComponent)}>
                    <Core.Icon
                        type="regular"
                        size={spacing.s9}
                        name="walking"
                        fill="solid"
                        color={baseColors.grey60}
                    />
                    <p className={css(styles.emptyStateTitle)}>{noActivitiesTitle}</p>
                    <p className={css(styles.encouragementText)}>{encouragementText}</p>
                    <Core.Button onPress={this.onTrackActivity}>
                        <Core.Icon
                            type="regular"
                            size={spacing.s5}
                            name="plus-circle"
                            fill="regular"
                            className={css(styles.plusCircle)}
                        />
                        {trackButtonText}
                    </Core.Button>
                </div>
            )
        );
    }

    onTrackActivity = () => {
        tracker.logEvent('track_activity');
        Modal.open(AsyncComponent(() => import('../TrackActivityForm')), {
            isModal: true,
            trackActivityTitle: this.props.i18n.t('trackActivity.text'),
        }, {
            isNoPadding: true,
            isContainer: true,
            modalHidden: true,
            isMaxHeight: true,
            isMaxWidthLimited: true,
        });
    };

    renderItem = ({ item }) => (
        <div key={item.date}>
            <div className={css(styles.subtitleContainer, layoutStyle.flex)}>
                <span className={css(styles.dateName)}>{this.props.getDateName(item.date)}</span>
                <span className={css(styles.date)}>{this.props.getDateString(item.date)}</span>
            </div>
            {this.renderActivitiesList(item.logs)}
        </div>
    );

    renderActivitiesList = activities => (
        _.map(activities, item => (
            <ActivityLogItem key={item.activityLogId} activityLogId={item.activityLogId} itemHasSwipeout={this.props.itemHasSwipeout} />
        ))
    );

    render() {
        const { onEndReached, canLoadMore, isLoading, activitiesToRender, overflow, scrollParentRef, isInModal } = this.props;
        return (
            <div>
                {isLoading && !activitiesToRender.length? <Core.ListLoading key="Loading" color={baseColors.secondary} /> : (
                    <Core.InfiniteLazyListFadeIn
                        hasMore={canLoadMore}
                        overflow={overflow}
                        isLoading={isLoading}
                        data={activitiesToRender}
                        hasMarginHorizontal={false}
                        onEndReached={onEndReached}
                        onEndReachedThreshold={0.1}
                        renderItem={this.renderItem}
                        keyExtractor={item => item.id}
                        ListEmptyComponent={this.emptyComponent}
                        isInModal={isInModal}
                        scrollParentRef={scrollParentRef}
                    />
                )}
            </div>
        );
    }
}

export default WithActivityLogListBase(ActivityLogList);
const styles = StyleSheet.create({
    ...baseStyles,
    subtitleContainer: {
        ...baseStyles.subtitleContainer,
        marginBottom: spacing.s1,
        display: 'flex'
    },
});