import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';

import { getDeviceByVendorId } from '../../selectors';
import { spacing, appFonts } from '../../../../styles';
import { translate, selectors as coreSelectors, BUILDS, Platform, PLATFORMS } from '../../../core';
import { selectors as authSelectors } from '../../../auth';
import * as actions from '../../actions';
import { HEALTH_KIT, GOOGLE_FIT } from '.././../constants';

const MOBILE_APPS_ONLY = [HEALTH_KIT];

export default function WithDevicesModalsLogicBase(WrappedComponent) {
    class DevicesModalsLogicBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            device: PropTypes.object.isRequired,
            programName: PropTypes.string.isRequired,
            isLiveBetter: PropTypes.bool,
            isTelus: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            customPointsName: PropTypes.string.isRequired
        };

        static defaultProps = {
            isLiveBetter: false,
            isTelus: false
        };

        get isWeb() {
            return Platform.OS === PLATFORMS.web;
        }

        onDevicePress = (connectFunc, showConnectModal, showSyncModal=null) => {
            if (this.isWeb && this.hasSyncModal) return showSyncModal();
            else if (this.props.isLiveBetter || (this.props.isTelus && this.props.device.vendor === GOOGLE_FIT)) return showConnectModal();
            connectFunc();
        };

        get isSproutProgram() {
            return this.props.programName.toLowerCase() === BUILDS.sprout;
        }

        get hasSyncModal() {
            return _.includes(MOBILE_APPS_ONLY, _.get(this.props.device, 'vendor'));
        }

        get deviceName() {
            return _.get(this.props.device, 'displayName');
        }

        infoModalProps = (okHandler, cancelHandler) => {
            const { i18n } = this.props;
            return {
                isButtonVisible: false,
                iconName: 'watch-fitness',
                title: i18n.t('appsDevices.connectModal.title'),
                isAppsDevicesModal: true,
                buttons: [
                    { text: i18n.t('continue'), onPress: () => okHandler(), isBoldText: true },
                    { text: i18n.t('button_cancel'), onPress: cancelHandler ? () => cancelHandler() : null }
                ],
            };
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onDevicePress={this.onDevicePress}
                    infoModalProps={this.infoModalProps}
                    deviceName={this.deviceName}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const vendorId = _.get(ownProps, 'vendorId') || _.get(ownProps, 'device.vendorId') || _.get(ownProps, 'match.params.vendorId');
        return {
            device: getDeviceByVendorId(state, vendorId),
            programName: authSelectors.getProgramName(state),
            customPointsName: coreSelectors.getCustomPointsName(state),
            isLiveBetter: coreSelectors.isLiveBetter(state),
            isTelus: coreSelectors.isTelus(state)
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(DevicesModalsLogicBase));
}

export const styles = {
    modalText: {
        ...appFonts.smRegular,
    },
    textFirstParagraph: {
        marginBottom: spacing.s3
    },
    boldText: {
        ...appFonts.mdBold
    }
};
