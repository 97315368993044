import { constants as coreConstants } from '../../../core';

const statusCodes = {
    popup_closed_by_user: 'popup_closed_by_user',
    access_denied: 'access_denied',
    immediate_failed: 'immediate_failed'
};

class Google {
    constructor() {
        this.init();
    }

    init = () => {
        // Load the SDK asynchronously
        (function (d, s, id) {
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            const js = d.createElement(s);
            js.id = id;
            js.src = 'https://apis.google.com/js/platform.js';
            js.onload = () => {
                window.gapi.load('auth2', () => {
                    if (coreConstants.IS_LIVE_BETTER) {
                        window.gapi.auth2.init({
                            //client_id: '661205309888-5vv8joh36o0hlev5d4n7bvssnj84m1fd.apps.googleusercontent.com',
                            client_id: '800863396957-cdrr1qqni4pts1l59mf1p8qu8tupn7dd.apps.googleusercontent.com', // LiveBetter
                        });
                    }
                });
            };
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-jssdk'));
    };

    get auth() {
        return window.gapi.auth2.getAuthInstance();
    }

    get user() {
        return this.auth.currentUser.get();
    }

    getToken() {
        return this._accessToken;
    }

    login = async (callback, errorCallback) => {
        try {
            await this.auth.signIn({ scope: 'profile email' });
            this._accessToken = JSON.stringify(this.user.getAuthResponse());   // Pass the entire object down
            callback({ accessToken: this._accessToken });
        } catch (error) {
            if (error.error === statusCodes.popup_closed_by_user) {
                // The user closed the popup before finishing the sign in flow.
            } else if (error.error === statusCodes.access_denied) {
                // The user denied the permission to the scopes required.
                errorCallback && errorCallback(error);
            } else if (error.error === statusCodes.immediate_failed) {
                // No user could be automatically selected without prompting the consent flow. Error raised when using
                // signIn with prompt: 'none' option. This option should not be required to use, as gapi.auth2.init
                // will automatically sign in the user if previously signed in during a previous session.
                errorCallback && errorCallback(error);
            } else {
                // some other error happened
                errorCallback && errorCallback(error);
            }
        }
    };

    me = callback => {
        const profile = this.user.getBasicProfile();
        callback({
            firstName: profile.getGivenName(),
            lastName: profile.getFamilyName(),
            email: profile.getEmail(),
        });
    };

    isSignedIn() {
        return this.user.isSignedIn();
    }

    logout = async () => {
        try {
            this.auth.signOut();
        } catch (error) {
            console.error(error);
        }
    };
}

const google = new Google();

export default google;
