import _ from 'lodash';
import moment from 'moment';
import * as types from './actionTypes';
import * as api from './api';
import { CHALLENGE_ENTITY_TYPES, CHALLENGE_TYPES, DISPLAY_TYPES, FEATURED_CHALLENGES } from './constants';
import * as selectors from './selectors';
import i18n from '../../i18n/i18n';
import {
    normalize, actions as coreActions, constants as coreConstants, Storage, getRequestId,
    getInapropriateErrors, DATE_FORMATS, selectors as coreSelectors,
} from '../core';
import { actions as feedsActions, constants as feedsConstants } from '../feeds';
import { actions as rewardsActions } from '../rewards';
import { getTotalPointsEarned, getTotalActivityNames } from './services/activitiesHelper';
import { getChallengeToastData, isPersonal, isGoalType, isMember, isChallengeUpdating } from './services/helper';
import { validateError } from '../../config';

export function getPersonalGoalsAndChallenges(parameters = {}, type = DISPLAY_TYPES.UNCOMPLETED_CAROUSEL, isReplace = true) {
    return function (dispatch) {
        dispatch({ type: types.GET_CHALLENGES.REQUEST });
        return api.getChallenges(parameters)
            .then(res => {
                dispatch({
                    type: types.GET_CHALLENGES.SUCCESS,
                    payload: {
                        ...normalize.normalizeArray(res.data.challenges, 'challengeId'),
                        count: res.data.totalNumberOfChallenges,
                        type,
                        isReplace
                    }
                });
            })
            .catch(error => dispatch({ type: types.GET_CHALLENGES.ERROR, payload: { error } }));
    };
}

export function getPreviousPersonalGoals(parameters = {}, isReplace = true) {
    const params = { isEnded: 1, isCompleted: 1, isSolo: 1, order: 'end-desc', maxCount: 10, ...parameters };
    return getPersonalGoalsAndChallenges(params, DISPLAY_TYPES.COMPLETED_CAROUSEL, isReplace);
}

export function getPersonalGoalsAndChallengesCompleted(parameters = {}, type = DISPLAY_TYPES.COMPLETED_CAROUSEL, isReplace) {
    const params = _.extend({ order: 'start-asc', start: 0, isCompleted: 1, maxCount: 8 }, parameters);
    return getPersonalGoalsAndChallenges(params, type, isReplace);
}

// Pass in single challenge Id
export function setSingleGoalOrChallengeDismissed(challengeId) {
    return function (dispatch) {
        dispatch({
            type: types.DISMISS_SINGLE_CHALLENGE.REQUEST,
            payload: { requestId: getRequestId(types.DISMISS_SINGLE_CHALLENGE.NAME, challengeId) }
        });
        return api.setSingleChallengeDismissed(challengeId)
            .then(response => {
                dispatch({
                    type: types.DISMISS_SINGLE_CHALLENGE.SUCCESS,
                    payload: {
                        requestId: getRequestId(types.DISMISS_SINGLE_CHALLENGE.NAME, challengeId),
                        challenge: response.data
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.DISMISS_SINGLE_CHALLENGE.ERROR,
                    payload: { requestId: getRequestId(types.DISMISS_SINGLE_CHALLENGE.NAME, challengeId), error }
                });
                throw error;
            }));
    };
}

export function setFeaturedChallengeDismissed(challengeId) {
    return function (dispatch) {
        dispatch({
            type: types.DISMISS_FEATURED_CHALLENGE.REQUEST,
            payload: { requestId: getRequestId(types.DISMISS_FEATURED_CHALLENGE.NAME, challengeId) }
        });
        return api.setFeaturedChallengeDismissed(challengeId)
            .then(response => {
                dispatch({
                    type: types.DISMISS_FEATURED_CHALLENGE.SUCCESS,
                    payload: {
                        requestId: getRequestId(types.DISMISS_FEATURED_CHALLENGE.NAME, challengeId),
                        challenge: response.data
                    }
                });
                dispatch(getPersonalGoalsAndChallenges({ isMember: 0, isFeatured: 1, isEnded: 0, maxCount: 10 }, FEATURED_CHALLENGES));
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.DISMISS_FEATURED_CHALLENGE.ERROR,
                    payload: { requestId: getRequestId(types.DISMISS_FEATURED_CHALLENGE.NAME, challengeId), error }
                });
                throw error;
            }));
    };
}

export function setMultiGoalOrChallengeDismissed(challengeIds) {
    return function (dispatch) {
        dispatch({ type: types.DISMISS_MULTIPLE_CHALLENGE.REQUEST });
        return Promise.all(_.map(challengeIds, challengeId => dispatch(setSingleGoalOrChallengeDismissed(challengeId))))
            .then(() => {
                dispatch({ type: types.DISMISS_MULTIPLE_CHALLENGE.SUCCESS });
                // todo: is it needed? to try to change the store
                dispatch(getPersonalGoalsAndChallengesCompleted());
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.DISMISS_MULTIPLE_CHALLENGE.ERROR, payload: { error } });
            }));
    };
}

export const getChallenge = (challengeId, showLoader = true, isLight = false, isTracking = false) => dispatch => {
    if (showLoader) {
        dispatch({
            type: types.GET_CHALLENGE_INFO.REQUEST,
            payload: { requestId: getRequestId(types.GET_CHALLENGE_INFO.NAME, challengeId) }
        });
    }
    return dispatch(getChallengeOnly(challengeId, isLight))
        .then(() => dispatch(getChallengeAdditionalInfo(challengeId, isTracking)))
        .then(() => dispatch({
            type: types.GET_CHALLENGE_INFO.SUCCESS,
            payload: { requestId: getRequestId(types.GET_CHALLENGE_INFO.NAME, challengeId) }
        }))
        .catch(error => dispatch({
            type: types.GET_CHALLENGE_INFO.ERROR,
            payload: { error, requestId: getRequestId(types.GET_CHALLENGE_INFO.NAME, challengeId), isErrorStore: true }
        }));
};

export const getChallengeLeaderboard = challengeId => dispatch => {
    dispatch({ type: types.GET_CHALLENGE_LEADERBOARD.REQUEST });
    return api.getChallengeLeaderboard(challengeId)
        .then(res => dispatch({
            type: types.GET_CHALLENGE_LEADERBOARD.SUCCESS,
            payload: { leaderboard: { ...res.data[0], challengeId } }
        }))
        .catch(error => validateError(error, error => {
            dispatch({ type: types.GET_CHALLENGE_LEADERBOARD.ERROR, payload: { error } });
        }));
};

export const getChallengeMembership = challengeId => dispatch => {
    const requestId = getRequestId(types.GET_CHALLENGE_MEMBERSHIP.NAME, challengeId);
    dispatch({ type: types.GET_CHALLENGE_MEMBERSHIP.REQUEST, payload: { requestId } });
    return api.getChallengeMembership(challengeId)
        .then(res => dispatch({
            type: types.GET_CHALLENGE_MEMBERSHIP.SUCCESS,
            payload: { requestId, challengeId, membership: res.data }
        }))
        .catch(error => validateError(error, error => {
            dispatch({ type: types.GET_CHALLENGE_MEMBERSHIP.ERROR, payload: { requestId, error } });
        }));
};

export const getChallengeAdditionalInfo = (challengeId, isTracking = false) => (dispatch, getState) => {
    const challenge = selectors.getChallenge(getState(), challengeId);
    const jobs = [];
    if (!isTracking && !isPersonal(challenge)) {
        jobs.push(dispatch(getChallengeMembership(challengeId)));
    }
    if (isMember(challenge) && !(isPersonal(challenge) || isGoalType(challenge))) {
        jobs.push(dispatch(getChallengeLeaderboard(challengeId)));
    }
    if (challenge.userEntity && challenge.userEntity.entityType !== CHALLENGE_ENTITY_TYPES.user) {
        jobs.push(dispatch(getChallengeTeam(challengeId, challenge.userEntity.id)));
    }
    return Promise.all(jobs);
};

export const getChallengeAdditionalInfoForCard = challengeId => (dispatch, getState) => {
    const challenge = selectors.getChallenge(getState(), challengeId);
    return Promise.all([
        ...(challenge.challengeType === CHALLENGE_TYPES.competition
            ? [dispatch(getChallengeLeaderboard(challengeId))] : []),
        ...(challenge.userEntity && challenge.userEntity.entityType !== CHALLENGE_ENTITY_TYPES.user
            ? [dispatch(getChallengeTeam(challengeId, challenge.userEntity.id))] : [])
    ]);
};

export const getChallengeOnly = (challengeId, isLight = false) => dispatch => {
    dispatch({ type: types.GET_CHALLENGE.REQUEST });
    return api.getChallenge(challengeId, isLight)
        .then(response => {
            dispatch({ type: types.GET_CHALLENGE.SUCCESS, payload: { challenge: response.data } });
            return response;
        })
        .catch(error => validateError(error, error => {
            dispatch({
                type: types.GET_CHALLENGE.ERROR,
                payload: { error, toast: { actionType: 'challengeNotFound' } }
            });
            throw error;
        }));
};

export function getChallengeTeam(challengeId, teamId) {
    return function (dispatch) {
        dispatch({ type: types.GET_CHALLENGE_TEAM.REQUEST });
        return api.getChallengeTeam(challengeId, teamId)
            .then(response => {
                dispatch({ type: types.GET_CHALLENGE_TEAM.SUCCESS, payload: { team: { ...response.data, teamId } } });
            })
            .catch(error => validateError(error, error => dispatch({ type: types.GET_CHALLENGE_TEAM.ERROR, payload: { error } })));
    };
}

export function createTeam(challengeId, name, inviteUserIds, picture, title, msg) {
    return function (dispatch) {
        const actionType = 'creatingTeam';
        dispatch({ type: types.CREATE_TEAM.REQUEST });
        return api.createTeam(challengeId, name, inviteUserIds, picture)
            .then(res =>
                dispatch(feedsActions.setStreamViewerMember(challengeId))
                // return dispatch(getChallenge(challengeId));
            )
            .then(() => {
                dispatch({ type: types.CREATE_TEAM.SUCCESS, payload: { toast: { actionType, title, msg } } });
                dispatch(getChallengeOnly(challengeId));
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.CREATE_TEAM.ERROR, payload: { error, toast: { actionType }, isErrorStore: true } });
            }));
    };
}

export function editTeam(challengeId, teamId, name, inviteUserIds, removeUserIds, picture, title, msg) {
    return function (dispatch) {
        const actionType = 'editingTeam';
        dispatch({ type: types.EDIT_TEAM.REQUEST });
        return api.editTeam(challengeId, teamId, name, inviteUserIds, removeUserIds, picture)
            .then(res => {
                dispatch(feedsActions.setStreamViewerMember(challengeId));
                return dispatch(getChallenge(challengeId));
            })
            .then(() => {
                dispatch({ type: types.EDIT_TEAM.SUCCESS, payload: { toast: { actionType, title, msg } } });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.EDIT_TEAM.ERROR, payload: { error, toast: { actionType }, isErrorStore: true } });
            }));
    };
}

export function clearCreateEditTeamError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(types.CREATE_TEAM.NAME));
        dispatch(coreActions.removeError(types.EDIT_TEAM.NAME));
    };
}

export function clearJoinChallengeError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(types.JOIN_CHALLENGE.NAME));
    };
}

export function clearGetChallengeInfoError(challengeId) {
    return function (dispatch) {
        dispatch(coreActions.removeError(getRequestId(types.GET_CHALLENGE_INFO.NAME, challengeId)));
    };
}

export function joinChallenge(challengeId, entityId, isTeam, teamName=null, challengeName=null) {
    // todo: refactor toast vocab

    const successToast = isTeam
        ? getChallengeToastData(true, teamName)
        : getChallengeToastData(false, challengeName);

    const errorToast = isTeam
        ? { title: i18n.t('error_joining_team'), msg: i18n.t('cannot_add_to_team') }
        : { title: i18n.t('challenge_toast.danger.error'), msg: i18n.t('challenge_toast.danger.message') };
    return function (dispatch) {
        dispatch({ type: types.JOIN_CHALLENGE.REQUEST, payload: { requestId: getRequestId(types.JOIN_CHALLENGE.NAME, challengeId) } });
        return api.joinChallenge(challengeId, entityId)
            // todo: check if it is needed to call getChallenge
            .then(() => dispatch(getChallenge(challengeId)))
            .then(() => {
                dispatch({
                    type: types.JOIN_CHALLENGE.SUCCESS,
                    payload: {
                        toast: successToast,
                        requestId: getRequestId(types.JOIN_CHALLENGE.NAME, challengeId),
                        challengeId,
                        isTeam
                    }
                });
                dispatch(feedsActions.setStreamViewerMember(challengeId));
                dispatch(getPersonalGoalsAndChallenges({ isMember: 0, isFeatured: 1, isEnded: 0, maxCount: 10 }, FEATURED_CHALLENGES));
                setTimeout(() => dispatch(coreActions.askUserForAppStoreReviewIfPossible()), coreConstants.APP_RATING_DELAY);
                setTimeout(() => dispatch(getInvitations(), 1000));
            })
            .catch(error => validateError(error, error => {
                if (isTeam) {
                    dispatch(getChallengeMembers(challengeId, true));
                }
                dispatch({
                    type: types.JOIN_CHALLENGE.ERROR,
                    payload: { error, toast: errorToast, isErrorStore: true, requestId: getRequestId(types.JOIN_CHALLENGE.NAME, challengeId) }
                });
            }));
    };
}

export const joinChallengeByTeamId = (teamId, teamName) => dispatch => {
    dispatch({
        type: types.JOIN_CHALLENGE_BY_TEAM_ID.REQUEST,
        payload: { requestId: getRequestId(types.JOIN_CHALLENGE_BY_TEAM_ID.NAME, teamId) }
    });
    let challengeId;
    return api.getChallengeByTeamId(teamId)
        .then(response => {
            challengeId = response.data.id;
            return dispatch(joinChallenge(challengeId, teamId, true, teamName, null));
        })
        .then(() => dispatch({
            type: types.JOIN_CHALLENGE_BY_TEAM_ID.SUCCESS,
            payload: { teamId, challengeId, requestId: getRequestId(types.JOIN_CHALLENGE_BY_TEAM_ID.NAME, teamId) }
        }))
        .catch(error => validateError(error, error => dispatch({
            type: types.JOIN_CHALLENGE_BY_TEAM_ID.ERROR,
            payload: { isErrorStore: true, error, requestId: getRequestId(types.JOIN_CHALLENGE_BY_TEAM_ID.NAME, teamId) }
        })));
};

export const clearJoinChallengeByTeamIdError = teamId => dispatch => {
    dispatch(coreActions.removeError(getRequestId(types.JOIN_CHALLENGE_BY_TEAM_ID.NAME, teamId)));
};

export function leaveChallenge(challengeId) {
    return function (dispatch) {
        const requestId = getRequestId(types.LEAVE_CHALLENGE.NAME, challengeId);
        dispatch({ type: types.LEAVE_CHALLENGE.REQUEST, payload: { requestId } });
        return api.leaveChallenge(challengeId)
            .then(() => dispatch(getChallengeOnly(challengeId)))
            .then(() => {
                dispatch({ type: types.LEAVE_CHALLENGE.SUCCESS, payload: { requestId } });
                dispatch(feedsActions.removeStreamViewerMember(challengeId));
                dispatch(getPersonalGoalsAndChallenges({ isMember: 0, isFeatured: 1, isEnded: 0, maxCount: 10 }, FEATURED_CHALLENGES));
            })
            .catch(error => validateError(error, error => dispatch({ type: types.LEAVE_CHALLENGE.ERROR, payload: { requestId, error } })));
    };
}

export function searchTeamsForGroupChallenge(params) {
    return function (dispatch) {
        const requestId = getRequestId(types.GET_SEARCH_TEAMS_RESULT.NAME, params.challengeId);
        dispatch({ type: types.GET_SEARCH_TEAMS_RESULT.REQUEST, payload: { requestId } });
        return api.searchTeamsForGroupChallenge(params)
            .then(res => {
                dispatch({ type: types.GET_SEARCH_TEAMS_RESULT.SUCCESS, payload: { teams: res.data, requestId } });
            })
            .catch(error => {
                dispatch({ type: types.GET_SEARCH_TEAMS_RESULT.ERROR, payload: { requestId } });
            });
    };
}

export function updateNewChallenge(update) {
    return { type: types.UPDATE_NEW_CHALLENGE, payload: update };
}

export function updateCreateChallengeStep(step, isReset = false, isClear = false) {
    return {
        type: types.UPDATE_CREATE_CHALLENGE_STEP,
        payload: { step, isReset, isClear }
    };
}

export function canceledCreatingChallengeToast(challengeType) {
    return function (dispatch) {
        dispatch(coreActions.addToast(
            coreConstants.TOAST_TYPES.SUCCESS, '',
            i18n.t('canceledCreatingChallenge', { challengeType }),
            i18n.t('generalSuccessTitle')
        ));
    };
}

export function createCustomChallenge(challenge, actionType = 'creatingCustomGoal', isRestartingGoal = false) {
    const msg = isRestartingGoal
        ? i18n.t('restartingCustomGoalToast.success.message')
        : i18n.t('createChallenge.toast.success', { challengeType: challenge.challengeType || i18n.t(challenge.type) });

    const toast = actionType === 'creatingCustomGoal' ? {
        msg,
        title: challenge.name
    } : { actionType };
    return function (dispatch) {
        dispatch({ type: types.CREATE_CHALLENGE.REQUEST });
        return api.createChallenge(challenge)
            .then(res => {
                dispatch({
                    type: types.CREATE_CHALLENGE.SUCCESS,
                    payload: { challenge: res.data, toast }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.CREATE_CHALLENGE.ERROR,
                    payload: { error, toast: { actionType }, isErrorStore: true }
                });
            }));
    };
}

export const clearCreateChallengeError = () => dispatch => {
    dispatch(coreActions.removeError(types.CREATE_CHALLENGE.NAME));
};

export function updateChallenge(challenge) {
    const requestId = getRequestId(types.UPDATE_CHALLENGE.NAME, challenge.challengeId);
    return function (dispatch) {
        dispatch({ type: types.UPDATE_CHALLENGE.REQUEST, payload: { requestId } });
        return api.updateChallenge({ challengeId: challenge.challengeId, picture: challenge.picture }) // as of now, we only support updating picture, will add others when we want to support, old request does not match request format on the backend
            .then(res => {
                dispatch({ type: types.UPDATE_CHALLENGE.SUCCESS, payload: { challenge: res.data, requestId } });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.UPDATE_CHALLENGE.ERROR, payload: { error, requestId } });
            }));
    };
}

export function deleteChallenge(challengeId) {
    const requestId = getRequestId(types.DELETE_CHALLENGE.NAME, challengeId);
    return function (dispatch, getState) {
        const challenge = selectors.getChallenge(getState(), challengeId);
        const titleName = _.get(challenge, 'challengeName', '');
        const actionType = isPersonal(challenge) ? 'deleteGoal' : 'deleteChallenge';
        dispatch({ type: types.DELETE_CHALLENGE.REQUEST, payload: { requestId } });
        return api.deleteChallenge(challengeId)
            .then(() => {
                dispatch({
                    type: types.DELETE_CHALLENGE.SUCCESS,
                    payload: {
                        requestId,
                        challengeId,
                        toast: {
                            actionType,
                            title: i18n.t(`${actionType}Toast.success.title`, { title_name: titleName })
                        }
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.DELETE_CHALLENGE.ERROR, payload: { requestId, error, toast: { actionType } } });
            }));
    };
}

export const getChallengeRecommendations = () => dispatch => {
    dispatch({ type: types.GET_CHALLENGE_RECOMMENDATIONS.REQUEST });
    return api.getChallengeRecommendations()
        .then(res => {
            dispatch({ type: types.GET_CHALLENGE_RECOMMENDATIONS.SUCCESS, payload: { recommendations: res.data } });
        })
        .catch(error => validateError(error, error => {
            dispatch({ type: types.GET_CHALLENGE_RECOMMENDATIONS.ERROR, payload: { error } });
        }));
};

export const getRecommendedGoals = () => dispatch => {
    dispatch({ type: types.GET_RECOMMENDED_GOALS.REQUEST });
    const params = { starter: 0 };
    return api.getRecommendedGoals(params)
        .then(res => {
            dispatch({ type: types.GET_RECOMMENDED_GOALS.SUCCESS, payload: { recommendedGoals: res.data } });
        })
        .catch(error => validateError(error, error => {
            dispatch({ type: types.GET_RECOMMENDED_GOALS.ERROR, payload: { error } });
        }));
};

export const getStarterGoals = () => dispatch => {
    dispatch({ type: types.GET_STARTER_GOALS.REQUEST });
    const params = { starter: 1 };
    return api.getStarterGoals(params)
        .then(res => {
            dispatch({ type: types.GET_STARTER_GOALS.SUCCESS, payload: { starterGoals: res.data } });
        })
        .catch(error => validateError(error, error => {
            dispatch({ type: types.GET_STARTER_GOALS.ERROR, payload: { error } });
        }));
};

export const setGoal = (goal, hasToast = true, getAchievements = false) => dispatch => {
    const actionType = 'settingGoal';
    const requestId = getRequestId(types.SET_GOAL.NAME, goal.name);
    dispatch({ type: types.SET_GOALS.REQUEST }); // need general to know if any is loading
    dispatch({ type: types.SET_GOAL.REQUEST, payload: { requestId } });
    return api.createChallenge(goal)
        .then(res => {
            dispatch({ type: types.SET_GOALS.SUCCESS });
            getAchievements && dispatch(rewardsActions.getAchievements());
            dispatch({
                type: types.SET_GOAL.SUCCESS,
                payload: {
                    requestId,
                    ...normalize.normalizeArray([res.data], 'challengeId'),
                    type: DISPLAY_TYPES.UNCOMPLETED_CAROUSEL,
                    toast: hasToast ? { actionType } : null
                }
            });
        }).catch(error => validateError(error, error => {
            dispatch({ type: types.SET_GOALS.ERROR });
            dispatch({
                type: types.SET_GOAL.ERROR,
                payload: {
                    requestId,
                    error,
                    toast: hasToast ? { actionType } : null
                }
            });
        }));
};

export const getActivityUnits = () => dispatch => {
    dispatch({ type: types.GET_ACTIVITY_UNITS.REQUEST });
    return api.getActivityUnits()
        .then(res => {
            const data = _.map(res.data, activityCategory => ({
                ...activityCategory,
                activities: _.map(activityCategory.activities, activity => ({
                    ...activity,
                    activityCategorySlug: activityCategory.activityCategorySlug,
                    activityUnits: _.map(activity.activityUnits, unit => ({
                        ...unit,
                        activityName: activity.activityName,
                        activityId: activity.activityId,
                        visible: activity.visible,
                        visible_in_challenge_creation: activity.visible_in_challenge_creation,
                        visible_in_log: activity.visible_in_log,
                        activityCategory: activityCategory.activityCategoryDisplayName,
                        activityCategorySlug: activityCategory.activityCategorySlug,
                        activityUnitId: unit.unitId,
                    }))
                }))
            }));
            const normalized = normalize.normalizeArray(data, 'activityCategoryId',
                { entities: 'activities', idAttribute: 'activityId' },
                { entities: 'activityUnits', idAttribute: 'unitId' });
            dispatch({
                type: types.GET_ACTIVITY_UNITS.SUCCESS,
                payload: normalized
            });
        }).catch(error => validateError(error, error => {
            dispatch({ type: types.GET_ACTIVITY_UNITS.ERROR, payload: { error } });
        }));
};

export const getActivityLogs = (action = null) => (dispatch, getState) => {
    dispatch({ type: types.GET_ACTIVITY_LOGS.REQUEST });
    const filterId = selectors.getDefaultTimeFiltersId(getState());
    return api.getActivityLogs(filterId)
        .then(res => {
            dispatch({
                type: types.GET_ACTIVITY_LOGS.SUCCESS,
                payload: { ...normalize.normalizeArray(_.orderBy(res.data, 'date', 'desc'), 'activityLogId'), }
            });
            if (action) dispatch(action);
        })
        .catch(error => validateError(error, error => {
            dispatch({ type: types.GET_ACTIVITY_LOGS.ERROR, payload: { error } });
            if (action) dispatch(action);
        }));
};

export const getFilteredActivityLogs = params => (dispatch, getState) => {
    const filterId = selectors.getDefaultTimeFiltersId(getState());
    const timePeriodId = _.get(params, 'timePeriodId') || filterId;
    const paramsToSend = params || { timePeriodId };
    const requestId = getRequestId(types.GET_FILTERED_ACTIVITY_LOGS.NAME, timePeriodId);
    dispatch({ type: types.GET_FILTERED_ACTIVITY_LOGS.REQUEST, payload: { requestId } });
    return api.getFilteredActivityLogs(paramsToSend)
        .then(res => {
            dispatch({
                type: types.GET_FILTERED_ACTIVITY_LOGS.SUCCESS,
                payload: {
                    requestId,
                    ...normalize.normalizeArray(res.data, 'activityLogId'),
                    timePeriodId,
                    isReplace: true
                }
            });
        })
        .catch(error => validateError(error, error => {
            dispatch({
                type: types.GET_FILTERED_ACTIVITY_LOGS.ERROR,
                payload: { requestId, error }
            });
        }));
};

export const getMoreFilteredActivityLogs = (timePeriodId, idLessThan = 0) => (dispatch, getState) => {
    const filterId = selectors.getDefaultTimeFiltersId(getState());
    const paramTimePeriodId = timePeriodId || filterId;
    const requestId = getRequestId(types.GET_MORE_FILTERED_ACTIVITY_LOGS.NAME, paramTimePeriodId);
    dispatch({ type: types.GET_MORE_FILTERED_ACTIVITY_LOGS.REQUEST, payload: { requestId } });
    const params = { timePeriodId, idLessThan, maxCount: 10 };
    return api.getFilteredActivityLogs(params)
        .then(res => {
            dispatch({
                type: types.GET_MORE_FILTERED_ACTIVITY_LOGS.SUCCESS,
                payload: {
                    requestId,
                    ...normalize.normalizeArray(res.data, 'activityLogId'),
                    timePeriodId,
                    isReplace: !idLessThan
                }
            });
        })
        .catch(error => validateError(error, error => {
            dispatch({
                type: types.GET_MORE_FILTERED_ACTIVITY_LOGS.ERROR,
                payload: { requestId, error }
            });
        }));
};

export const deleteActivityLog = (id, hasToast = true) => dispatch => {
    const actionType = 'deleteActivityLog';
    dispatch({ type: types.DELETE_ACTIVITY_LOG.REQUEST });
    return api.deleteActivityLog(id)
        .then(() => {
            dispatch({
                type: types.DELETE_ACTIVITY_LOG.SUCCESS,
                payload: {
                    id,
                    toast: hasToast ? {
                        actionType,
                        msg: i18n.t('activityLogRemoved.single.success')
                    } : null
                }
            });
            dispatch(rewardsActions.getUserLevels());
        })
        .catch(error => validateError(error, error => {
            dispatch({
                type: types.DELETE_ACTIVITY_LOG.ERROR,
                payload: {
                    error,
                    toast: hasToast ? {
                        actionType,
                        msg: i18n.t('activityLogRemoved.single.danger')
                    } : null
                }
            });
        }));
};

export const deleteActivityLogs = (challengeId, ids, hasToast = true) => dispatch => {
    dispatch({
        type: types.DELETE_ACTIVITY_LOGS.REQUEST,
        payload: { requestId: getRequestId(types.DELETE_ACTIVITY_LOGS.NAME, challengeId) }
    });
    const actionType = 'deleteActivityLogs';
    const onlyOneItemDeleted = ids.length === 1;
    return Promise.all(_.map(ids, id => dispatch(deleteActivityLog(id, onlyOneItemDeleted && hasToast))))
        .then(() => {
            dispatch({
                type: types.DELETE_ACTIVITY_LOGS.SUCCESS,
                payload: {
                    requestId: getRequestId(types.DELETE_ACTIVITY_LOGS.NAME, challengeId),
                    toast: !onlyOneItemDeleted && hasToast ? {
                        actionType,
                        msg: i18n.t('activityLogRemoved.plural.success')
                    } : null
                }
            });
        })
        .catch(error => validateError(error, error => {
            dispatch({
                type: types.DELETE_ACTIVITY_LOGS.ERROR,
                payload: {
                    requestId: getRequestId(types.DELETE_ACTIVITY_LOGS.NAME, challengeId),
                    error,
                    toast: !onlyOneItemDeleted && hasToast ? {
                        actionType,
                        msg: i18n.t('activityLogRemoved.plural.danger')
                    } : null
                }
            });
        }));
};

export const clearActivityLogsExtended = () => ({
    type: types.GET_ACTIVITY_LOGS_EXTENDED.SUCCESS,
    payload: { items: {}, ids: undefined }
});

export const getActivityLogsExtended = (startDate, endDate, maxCount, offset) => dispatch => {
    if (startDate || endDate) {
        dispatch({ type: types.GET_ACTIVITY_LOGS_EXTENDED.REQUEST });
        return api.getActivityLogsFromTo(startDate, endDate, maxCount, offset)
            .then(res => {
                dispatch({
                    type: types.GET_ACTIVITY_LOGS_EXTENDED.SUCCESS,
                    payload: { ...normalize.normalizeArray(_.orderBy(res.data, 'date', 'desc'), 'activityLogId'), }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_ACTIVITY_LOGS_EXTENDED.ERROR, payload: { error } });
            }));
    }
};

export const getFirstChallengeTrackedStatus = userId => async dispatch => {
    const value = await Storage.getItem(`${_.toString(userId)}_hasTracked`);
    if (!_.isNil(value)) dispatch(setFirstChallengeTrackedStatusSuccess(value));
};

export const setFirstChallengeTrackedStatus = userId => dispatch => {
    Storage.setItem(`${_.toString(userId)}_hasTracked`, true);
    dispatch(setFirstChallengeTrackedStatusSuccess(true));
};

export const setFirstChallengeTrackedStatusSuccess = payload => ({
    type: types.SET_USER_TRACKED_CHALLENGE_STATUS,
    payload
});

export const trackActivity = (challengeId, activities, shareWithFilterId = '', text = '', image = '',
    isSingleChallenge = true, isHomeCarousel = false, isSharing = true) => (dispatch, getState) => {
    dispatch({
        type: types.TRACK_ACTIVITY.REQUEST,
        payload: { requestId: getRequestId(types.TRACK_ACTIVITY.NAME, challengeId) }
    });
    return api.trackActivity(activities, shareWithFilterId, text, image)
        .then(response => {
            dispatch({
                type: types.TRACK_ACTIVITY.SUCCESS,
                payload: {
                    requestId: getRequestId(types.TRACK_ACTIVITY.NAME, challengeId),
                    toast: {
                        msg: i18n.t('keepUpTheGoodWork'),
                        title: i18n.t('pts_tracked', {
                            num: getTotalPointsEarned(activities),
                            customPoints: coreSelectors.getCustomPointsUnit(getState()),
                            activityName: getTotalActivityNames(activities)
                        })
                    }
                }
            });
            dispatch(rewardsActions.getUserLevels('week'));
            const { streamItemId } = response.data;
            streamItemId && isSharing && dispatch(feedsActions.getStreamItem(
                streamItemId, feedsConstants.STREAM_ENTITY_TYPES.feed, undefined, true
            ));

            const sortedActivities = _.sortBy(activities, 'date');
            const startDateTrackForChallenge = isSingleChallenge ? selectors.getChallengeStartDate(getState(), challengeId) : _.get(sortedActivities, '0.date', moment().format(DATE_FORMATS.full));
            const startDate = sortedActivities.length === 1 ? null : startDateTrackForChallenge;
            setTimeout(() => dispatch(getActivityLogsExtended(startDate, moment().format(DATE_FORMATS.full))), coreConstants.CHALLENGE_REFRESH_DELAY_FAST);
            setTimeout(() => dispatch(getChallengesAfterTracking(activities, isHomeCarousel)), coreConstants.CHALLENGE_REFRESH_DELAY_FAST);
            setTimeout(() => dispatch(checkChallengesUpdateStatus(activities, 0, isHomeCarousel)), coreConstants.CHALLENGE_REFRESH_DELAY_SLOW);

            setTimeout(() => dispatch(coreActions.askUserForAppStoreReviewIfPossible()), coreConstants.APP_RATING_DELAY);
            //setTimeout(() => SurvicateService.invokeWithEvent(coreConstants.SURVICATE_EVENTS.TRACKED_ACTIVITY), coreConstants.SURVICATE_DELAY);
        })
        .catch(error => validateError(error, error => {
            const inapropriateErrors = getInapropriateErrors(error);
            dispatch({
                type: types.TRACK_ACTIVITY.ERROR,
                payload: {
                    requestId: getRequestId(types.TRACK_ACTIVITY.NAME, challengeId),
                    error: inapropriateErrors ? _.assign(error.response.data, { type: inapropriateErrors }) : error,
                    isErrorStore: inapropriateErrors,
                    toast: {}
                }
            });
        }));
};

export const checkChallengesUpdateStatus = (activities, numTry, isHomeCarousel) => (dispatch, getState) => {
    let stillUpdating=false;
    _.each(selectors.getActiveChallengesForDate(getState(), activities), challengeId => {
        const challenge = selectors.getChallenge(getState(), challengeId);
        const isUpdating = isChallengeUpdating(challenge);
        if (isUpdating) {
            stillUpdating=true;
            dispatch(getChallenge(challengeId, false, isHomeCarousel, true));
        }
    });
    if (stillUpdating && numTry < 5) {
        setTimeout(() => dispatch(checkChallengesUpdateStatus(activities, numTry+1, isHomeCarousel)), coreConstants.CHALLENGE_REFRESH_DELAY_SLOW);
    }
};

export const clearTrackError = (challengeId, isDeleting = true) => dispatch => {
    dispatch(coreActions.removeError(getRequestId(types.TRACK_ACTIVITY.NAME, challengeId)));
    isDeleting && dispatch(coreActions.removeError(getRequestId(types.DELETE_ACTIVITY_LOGS.NAME, challengeId)));
};


export const getChallengesAfterTracking = (activities, isLight) => (dispatch, getState) => {
    dispatch({ type: types.UPDATE_AFTER_TRACKING.REQUEST });
    return Promise.all([
        dispatch(getActivityLogs()),
        ..._.map(selectors.getActiveChallengesForDate(getState(), activities), id => dispatch(getChallenge(id, false, isLight, true)))
    ])
        .then(() => {
            dispatch({ type: types.UPDATE_AFTER_TRACKING.SUCCESS });
        })
        .catch(error => validateError(error, error => {
            dispatch({ type: types.UPDATE_AFTER_TRACKING.ERROR, payload: { error } });
        }));
};

export const getChallengeMembers = (challengeId, isTeamChallenge, parameters) => dispatch => {
    const requestId = getRequestId(types.GET_CHALLENGE_MEMBERS.NAME, challengeId);
    dispatch({ type: types.GET_CHALLENGE_MEMBERS.REQUEST, payload: { requestId } });
    return api.getChallengeMembers(challengeId, isTeamChallenge, parameters)
        .then(res => {
            dispatch({
                type: types.GET_CHALLENGE_MEMBERS.SUCCESS,
                payload: {
                    requestId,
                    challengeId,
                    data: res.data,
                    isReplace: !_.get(parameters, 'start'),
                    isTeamChallenge
                }
            });
        })
        .catch(error => validateError(error, error => {
            dispatch({ type: types.GET_CHALLENGE_MEMBERS.ERROR, payload: { requestId, error } });
        }));
};

export const showFirstActivityTrackModal = () => ({ type: types.SHOW_FIRST_ACTIVITY_TRACK_MODAL });

export const hideActivityInformationText = () => ({ type: types.HIDE_ACTIVITY_INFORMATION_TEXT });

export const getLeaderboards = params => dispatch => {
    dispatch({ type: types.GET_LEADERBOARDS.REQUEST });
    return api.getLeaderboards(params)
        .then(res => {
            dispatch({
                type: types.GET_LEADERBOARDS.SUCCESS,
                payload: { data: res.data }
            });
        })
        .catch(error => validateError(error, error => {
            dispatch({ type: types.GET_LEADERBOARDS.ERROR, payload: { error } });
        }));
};

export const hideChallengeNotification = id => ({
    type: types.UPDATE_CHALLENGE_SUCCESS,
    payload: {
        data: { challengeId: id, isNotificationShown: false },
        isReplace: false
    }
});

export function getInvitations(isReplace = false) {
    return function (dispatch) {
        dispatch({ type: types.GET_INVITES.REQUEST });
        api.getInvitations()
            .then(response => {
                const payload = {
                    items: response.data.challenges,
                    count: response.data.totalNumberOfChallenges
                };

                dispatch({ type: types.GET_INVITES.SUCCESS, payload });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_INVITES.ERROR, payload: { error } });
            }));
    };
}

// LISTS
export function getChallengesList(params, isReplace = false) {
    return function (dispatch) {
        dispatch({ type: types.GET_CHALLENGES_LIST.REQUEST });
        const { filter } = params;
        return api.getChallengesList(params)
            .then(response => {
                const payload = {
                    type: filter,
                    count: response.data.totalNumberOfChallenges,
                    isReplace,
                };
                switch (filter) {
                    case DISPLAY_TYPES.RECOMMENDED:
                        _.assign(
                            payload,
                            normalize.normalizeArray(
                                _.map(response.data.challenges, item => _.omit({
                                    ...item,
                                    isRecommended: true,
                                    numMembers: item.num_members,
                                    numEvents: item.num_events
                                }, 'isInvitation')),
                                'challengeId'
                            )
                        );
                        break;
                    case DISPLAY_TYPES.RECENT:
                        _.assign(
                            payload,
                            normalize.normalizeArray(response.data.challenges, 'challengeId')
                        );
                        break;
                    case DISPLAY_TYPES.ATTENDED:
                        _.assign(
                            payload,
                            normalize.normalizeArray(_.map(response.data.challenges,
                                item => _.omit(item, 'isInvitation')), 'challengeId')
                        );
                        break;
                    case DISPLAY_TYPES.USER:
                        _.assign(
                            payload,
                            normalize.normalizeArray(response.data.challenges, 'challengeId')
                        );
                        break;
                    case DISPLAY_TYPES.INVITATIONS:
                    case DISPLAY_TYPES.COMPANY:
                    case DISPLAY_TYPES.COMPLETED:
                    case DISPLAY_TYPES.BONUS:
                    case DISPLAY_TYPES.ALL:
                    default:
                        _.assign(payload, normalize.normalizeArray(response.data.challenges, 'challengeId'));
                        break;
                }
                dispatch({ type: types.GET_CHALLENGES_LIST.SUCCESS, payload });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_CHALLENGES_LIST.ERROR, payload: { error } });
            }));
    };
}

export function getExploreChallenges(filters, maxCount) {
    return function (dispatch) {
        dispatch({ type: types.GET_EXPLORE_CHALLENGES.REQUEST });
        return Promise.all(_.map(filters, filter => dispatch(getChallengesList({ filter, maxCount, start: 0 }, true))))
            .then(() => {
                dispatch({ type: types.GET_EXPLORE_CHALLENGES.SUCCESS });
            })
            .catch(error => {
                dispatch({ type: types.GET_EXPLORE_CHALLENGES.ERROR });
            });
    };
}

export function dismissCarouselInstructions() {
    return { type: types.DISMISS_CAROUSEL_INSTRUCTIONS };
}

export function declineInvitation(invitationId) {
    const requestId = getRequestId(types.DECLINE_INVITATION.NAME, invitationId);
    return function (dispatch) {
        dispatch({ type: types.DECLINE_INVITATION.REQUEST, payload: { requestId } });
        return api.declineInvitation(invitationId)
            .then(() => {
                setTimeout(() => dispatch(getInvitations(), 1000));
            })
            .catch(error => validateError(error, error => dispatch({ type: types.DECLINE_INVITATION.ERROR, payload: { invitationId, error } })));
    };
}

