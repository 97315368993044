import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LastLocationProvider } from 'react-router-last-location';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import { selectors as authSelectors, actions as authActions } from './modules/auth';
import { ROUTES, AsyncComponent, constants as coreConstants, selectors as coreSelectors, decodeURI } from './modules/core';
import { components as Shared } from './modules/shared';
import { selectors as onboardingSelectors } from './modules/onboarding';
import { HAS_SSO_URL } from './modules/auth/constants';

const isOnboardingPath = path => (path === '/') ? true :
    path.includes('onboarding') || _.find(_.values(coreConstants.DEEP_URLS), item => _.includes(path, item));

const isToken = (path, actions) => {
    const isTokenIncluded = path.includes('jwtLoginToken');
    if (isTokenIncluded) getJsonFromUrl(path, actions);
    return isTokenIncluded;
};

const jwtUnsubscribeToken = history => {
    const { location: { pathname, search } } = history;
    const isTokenIncluded = pathname.includes(coreConstants.DEEP_URLS.unsubscribeEmails);
    return isTokenIncluded ? search.substring(search.lastIndexOf('=') + 1) : null;
};

const isSso = () => localStorage.getItem(HAS_SSO_URL);

const getJsonFromUrl = (path, actions) => {
    const result = decodeURI(path);
    actions.saveLoginQueryParams({ token: result.jwtLoginToken, username: result.username });
};

const LoggedInRoutes = ({ isShowingOnboarding, history, pathname, actions }) => (
    <Shared.Root isLoggedIn={true}>
        <Switch>
            {!isOnboardingPath(history.location.pathname) && isShowingOnboarding &&
                <Route
                    path="*"
                    render={routeProps => {
                        const Component = AsyncComponent(() => import('./modules/onboarding/components/OnboardingFlow'));
                        return <Component {...routeProps} redirectToOnboarding={true} nextPath={pathname} />;
                    }}
                />
            }
            <Route
                exact
                path={[ROUTES.unsubscribeEmailsWithoutLogin()]}
                component={AsyncComponent(() => import('./modules/settings/components/UnsubscribeEmails'))}
            />
            {jwtUnsubscribeToken(history) && (
                <Redirect
                    to={{
                        pathname: ROUTES.unsubscribeEmailsWithoutLogin(),
                        state: { token: jwtUnsubscribeToken(history) }
                    }}
                />
            )}
            {isToken(history.location.pathname, actions) && <Redirect to={ROUTES.generalLoggedIn()} />}
            <Route
                exact
                render={() => {
                    const Component = AsyncComponent(() => import('./modules/auth/components/SignIn'));
                    return <Component isGeneralSignIn={true} isLoggedIn={true} />;
                }}
                path={ROUTES.generalLoggedIn()}
            />
            <Route
                exact
                path={[ROUTES.home(), ROUTES.feeds(), ROUTES.streams()]}
                component={AsyncComponent(() => import('./modules/shared/components/Feeds'))}
            />
            <Route
                exact
                path={ROUTES.explore()}
                component={AsyncComponent(() => import('./modules/shared/components/Explore/Explore'))}
            />
            <Route
                exact
                path={ROUTES.notifications()}
                component={AsyncComponent(() => import('./modules/notifications/components/Notifications'))}
            />
            <Route
                exact
                path={ROUTES.settings()}
                component={AsyncComponent(() => import('./modules/settings/components/Settings'))}
            />
            <Route
                exact
                path={[ROUTES.notificationsEmailsSettings(), ROUTES.emailsUnsubscribe(), ROUTES.userPrivacy()]}
                component={AsyncComponent(() => import('./modules/settings/components/NotificationsSettings'))}
            />
            <Route
                exact
                path={ROUTES.privacySettings()}
                component={AsyncComponent(() => import('./modules/settings/components/PrivacySettings'))}
            />
            <Route
                exact
                path={ROUTES.privacySettingsItem()}
                component={AsyncComponent(() => import('./modules/settings/components/PrivacySettings/PrivacySettingsItem'))}
            />
            <Route
                exact
                path={ROUTES.smartModeSettings()}
                component={AsyncComponent(() => import('./modules/settings/components/SmartModeSettings'))}
            />
            <Route
                exact
                path={ROUTES.homeLegals()}
                component={AsyncComponent(() => import('./modules/settings/components/Legals'))}
            />
            <Route
                exact
                path={ROUTES.legals()}
                component={AsyncComponent(() => import('./modules/settings/components/Legals'))}
            />
            <Route
                exact
                path={ROUTES.feedback()}
                component={AsyncComponent(() => import('./modules/settings/components/Feedback'))}
            />
            <Route
                exact
                path={ROUTES.helpAndSupport()}
                component={AsyncComponent(() => import('./modules/settings/components/HelpAndSupport'))}
            />
            <Route
                exact
                path={[ROUTES.me(), ROUTES.userProf(), ROUTES.userStats(), ROUTES.userWellbeing()]}
                component={AsyncComponent(() => import('./modules/shared/components/Me'))}
            />
            <Route
                exact
                path={ROUTES.wellbeingScore()}
                component={AsyncComponent(() => import('./modules/settings/components/Wellbeing/WellbeingTab'))}
            />
            <Route
                exact
                path={ROUTES.profileEditor()}
                component={AsyncComponent(() => import('./modules/settings/components/Editor/ProfileEditor'))}
            />
            <Route
                exact
                path={ROUTES.nameEditor()}
                component={AsyncComponent(() => import('./modules/settings/components/Editor/NameEditor'))}
            />
            <Route
                exact
                path={ROUTES.personalEditor()}
                component={AsyncComponent(() => import('./modules/settings/components/Editor/PersonalEditor'))}
            />
            <Route
                exact
                path={ROUTES.locationEditor()}
                component={AsyncComponent(() => import('./modules/settings/components/Editor/LocationProfileEditor'))}
            />
            <Route
                exact
                path={ROUTES.biometricsEditor()}
                component={AsyncComponent(() => import('./modules/settings/components/Editor/BiometricsEditor'))}
            />
            <Route
                exact
                path={ROUTES.activityLog()}
                component={AsyncComponent(() => import('./modules/challenges/components/ActivityLog'))}
            />
            <Route
                exact
                path={ROUTES.previousGoals()}
                component={AsyncComponent(() => import('./modules/challenges/components/PreviousGoals'))}
            />
            <Route
                exact
                path={ROUTES.achievementDetails()}
                component={AsyncComponent(() => import('./modules/rewards/components/Achievements/AchievementDetails'))}
            />
            <Route
                exact
                path={[ROUTES.myAchievements(), ROUTES.userAchievementsRewards()]}
                component={AsyncComponent(() => import('./modules/rewards/components/MyAchievements'))}
            />
            <Route
                exact
                path={[ROUTES.appsDevices(), ROUTES.userDevices(), ROUTES.userDevicesRedirect()]}
                component={AsyncComponent(() => import('./modules/appsdevices/components/AppsDevices'))}
            />
            <Route
                exact
                path={ROUTES.deviceDetails()}
                component={AsyncComponent(() => import('./modules/appsdevices/components/DeviceDetails'))}
            />
            <Route
                exact
                path={ROUTES.onboardingDeviceDetails()}
                component={AsyncComponent(() => import('./modules/appsdevices/components/DeviceDetails'))}
            />
            <Route
                exact
                path={ROUTES.deviceActivities()}
                component={AsyncComponent(() => import('./modules/appsdevices/components/DeviceSupportedActivitiesList'))}
            />
            <Route
                exact
                path={ROUTES.onboardingDeviceActivities()}
                component={AsyncComponent(() => import('./modules/appsdevices/components/DeviceSupportedActivitiesList'))}
            />
            <Route
                exact
                path={ROUTES.rewards()}
                component={AsyncComponent(() => import('./modules/rewards/components/Rewards'))}
            />
            <Route
                exact
                path={[ROUTES.rewardsDetails(), ROUTES.rewardsTransactions()]}
                component={AsyncComponent(() => import('./modules/rewards/components/RewardsDetails'))}
            />
            <Route
                exact
                path={ROUTES.howToEarn()}
                component={AsyncComponent(() => import('./modules/rewards/components/HowToEarn'))}
            />
            <Route
                exact
                path={ROUTES.productDetails()}
                component={AsyncComponent(() => import('./modules/rewards/components/ProductDetails'))}
            />
            <Route
                exact
                path={ROUTES.productsList()}
                component={AsyncComponent(() => import('./modules/rewards/components/ProductsList'))}
            />
            <Route
                exact
                path={ROUTES.orderRedemptionConfirmation()}
                component={AsyncComponent(() => import('./modules/rewards/components/OrderRedemptionConfirmation'))}
            />
            <Route
                exact
                path={ROUTES.orderShippingAddress()}
                component={AsyncComponent(() => import('./modules/rewards/components/OrderShippingAddress'))}
            />
            <Route
                exact
                path={ROUTES.orderDetails()}
                component={AsyncComponent(() => import('./modules/rewards/components/OrderDetails'))}
            />
            <Route
                exact
                path={ROUTES.signout()}
                component={AsyncComponent(() => import('./modules/auth/components/SignOut'))}
            />
            <Route
                exact
                path={ROUTES.interests()}
                component={AsyncComponent(() => import('./modules/onboarding/components/Interests'), { hasFullWidthLayout: true })}
            />
            <Route
                exact
                path={[ROUTES.objectives(), ROUTES.userObjectives()]}
                component={AsyncComponent(() => import('./modules/onboarding/components/HealthObjectives'), { hasFullWidthLayout: true })}
            />
            <Route
                exact
                path={[ROUTES.onboardingFlow(), ROUTES.onboardingFlowStep()]}
                render={routeProps => {
                    const Component = AsyncComponent(() => import('./modules/onboarding/components/OnboardingFlow'), { hasFullWidthLayout: true, hasBackground: true });
                    return <Component {...routeProps} />;
                }}
            />
            <Route
                exact
                path={ROUTES.topics()}
                component={AsyncComponent(() => import('./modules/onboarding/components/HealthTopics'), { hasFullWidthLayout: true })}
            />
            <Route
                exact
                path={[ROUTES.healthSurvey(), ROUTES.userWellbeingSurvey()]}
                render={routeProps => {
                    const Component = AsyncComponent(() => import('./modules/onboarding/components/HealthSurvey'));
                    return <Component {...routeProps} isModal={false} showQuit={false} />;
                }}
            />
            <Route
                exact
                path={ROUTES.userGoals()}
                component={AsyncComponent(() => import('./modules/onboarding/components/UserGoals'), { hasFullWidthLayout: true })}
            />
            <Route
                exact
                path={[ROUTES.feedCreate(), ROUTES.feedDetailsEdit()]}
                component={AsyncComponent(() => import('./modules/feeds/components/FeedPostEditor'))}
            />
            <Route
                exact
                path={ROUTES.streamView()}
                component={AsyncComponent(() => import('./modules/feeds/components/FeedDetails'))}
            />
            <Route
                exact
                path={ROUTES.feedDetails()}
                component={AsyncComponent(() => import('./modules/feeds/components/FeedDetails'))}
            />
            <Route
                exact
                path={[ROUTES.communities(), ROUTES.groups()]}
                component={AsyncComponent(() => import('./modules/communities/components/Communities'))}
            />
            <Route
                exact
                path={[ROUTES.createCommunity(), ROUTES.createCommunityStep()]}
                component={AsyncComponent(() => import('./modules/communities/components/CreateCommunity'))}
            />
            <Route
                exact
                path={ROUTES.communitiesList()}
                component={AsyncComponent(() => import('./modules/communities/components/CommunitiesList'))}
            />
            <Route
                exact
                path={ROUTES.groupView()}
                component={AsyncComponent(() => import('./modules/communities/components/CommunityItemDetails'))}
            />
            <Route
                exact
                path={ROUTES.groupAccept()}
                component={AsyncComponent(() => import('./modules/challenges/components/JoinChallengeByTeamId'))}
            />
            <Route
                exact
                path={ROUTES.communityDetails()}
                component={AsyncComponent(() => import('./modules/communities/components/CommunityItemDetails'))}
            />
            <Route
                exact
                path={ROUTES.editCommunity()}
                component={AsyncComponent(() => import('./modules/communities/components/EditCommunity'))}
            />
            <Route
                exact
                path={ROUTES.communityMembers()}
                component={AsyncComponent(() => import('./modules/communities/components/CommunityMembers'))}
            />
            <Route
                exact
                path={ROUTES.inviteToCommunity()}
                component={AsyncComponent(() => import('./modules/invitations/components/InvitationsList'))}
            />
            <Route
                exact
                path={ROUTES.inviteToEvent()}
                component={AsyncComponent(() => import('./modules/invitations/components/InvitationsList'))}
            />
            <Route
                exact
                path={ROUTES.inviteToTeam()}
                component={AsyncComponent(() => import('./modules/invitations/components/InvitationsList'))}
            />
            <Route
                exact
                path={ROUTES.inviteToChallenge()}
                component={AsyncComponent(() => import('./modules/invitations/components/InvitationsList'))}
            />
            <Route
                exact
                path={ROUTES.events()}
                component={AsyncComponent(() => import('./modules/events/components/Events'))}
            />
            <Route
                exact
                path={[ROUTES.createEvent(), ROUTES.editEvent()]}
                component={AsyncComponent(() => import('./modules/events/components/CreateEvent'))}
            />
            <Route
                exact
                path={ROUTES.eventView()}
                component={AsyncComponent(() => import('./modules/events/components/EventDetails'))}
            />
            <Route
                exact
                path={ROUTES.eventDetails()}
                component={AsyncComponent(() => import('./modules/events/components/EventDetails'))}
            />
            <Route
                exact
                path={ROUTES.eventMembers()}
                component={AsyncComponent(() => import('./modules/events/components/EventMembers'))}
            />
            <Route
                exact
                path={ROUTES.eventsAll()}
                component={AsyncComponent(() => import('./modules/events/components/EventsAll'))}
            />
            <Route
                exact
                path={ROUTES.eventsList()}
                component={AsyncComponent(() => import('./modules/events/components/EventsList'))}
            />
            <Route
                exact
                path={ROUTES.score()}
                component={AsyncComponent(() => import('./modules/shared/components/Score'))}
            />
            <Route
                exact
                path={ROUTES.leaderboard()}
                component={AsyncComponent(() => import('./modules/shared/components/Score'))}
            />
            <Route
                exact
                path={ROUTES.yourProgress()}
                component={AsyncComponent(() => import('./modules/shared/components/Score'))}
            />
            <Route
                exact
                path={[ROUTES.linkAccounts(), ROUTES.userAccounts()]}
                component={AsyncComponent(() => import('./modules/auth/components/LinkAccounts'))}
            />
            <Route
                exact
                path={ROUTES.changePassword()}
                component={AsyncComponent(() => import('./modules/auth/components/ChangePassword'))}
            />
            <Route
                exact
                path={[ROUTES.content(), ROUTES.library()]}
                component={AsyncComponent(() => import('./modules/content/components/Content'))}
            />
            <Route
                exact
                path={ROUTES.contentList()}
                component={AsyncComponent(() => import('./modules/content/components/ContentList'))}
            />
            <Route
                exact
                path={ROUTES.libraryView()}
                component={AsyncComponent(() => import('./modules/content/components/ContentItemDetails'))}
            />
            <Route
                exact
                path={ROUTES.contentDetails()}
                component={AsyncComponent(() => import('./modules/content/components/ContentItemDetails'))}
            />
            <Route
                exact
                path={ROUTES.resourcesList()}
                component={AsyncComponent(() => import('./modules/resources/components/ResourceLinksList'))}
            />
            <Route
                exact
                path={ROUTES.challenges()}
                component={AsyncComponent(() => import('./modules/challenges/components/Challenges'))}
            />
            <Route
                exact
                path={ROUTES.challengesByType()}
                component={AsyncComponent(() => import('./modules/challenges/components/ChallengesByType'))}
            />
            <Route
                exact
                path={ROUTES.challengeView()}
                component={AsyncComponent(() => import('./modules/challenges/components/ChallengeDetails'), { hasFullWidthLayout: true })}
            />
            <Route
                exact
                path={ROUTES.challengeDetails()}
                component={AsyncComponent(() => import('./modules/challenges/components/ChallengeDetails'), { hasFullWidthLayout: true })}
            />
            <Route
                exact
                path={ROUTES.onboardingChallengeDetails()}
                component={AsyncComponent(() => import('./modules/challenges/components/ChallengeDetails'), { hasFullWidthLayout: true })}
            />
            <Route
                exact
                path={ROUTES.challengeMembers()}
                component={AsyncComponent(() => import('./modules/challenges/components/ChallengeMembers'))}
            />
            <Route
                exact
                path={ROUTES.goalActivityHistory()}
                component={AsyncComponent(() => import('./modules/challenges/components/GoalActivityHistory'))}
            />
            <Route
                exact
                path={ROUTES.onboardingGoalActivityHistory()}
                component={AsyncComponent(() => import('./modules/challenges/components/GoalActivityHistory'))}
            />
            <Route
                exact
                path={ROUTES.goalActivityHistoryDetails()}
                component={AsyncComponent(() => import('./modules/challenges/components/GoalActivityHistoryDetails'))}
            />
            <Route
                exact
                path={ROUTES.competitionActivityHistory()}
                component={AsyncComponent(() => import('./modules/challenges/components/CompetitionActivityHistory'))}
            />
            <Route
                exact
                path={ROUTES.competitionActivityHistoryDetails()}
                component={AsyncComponent(() => import('./modules/challenges/components/CompetitionActivityHistoryDetails'))}
            />
            <Route
                exact
                path={ROUTES.challengeRewardDetails()}
                component={AsyncComponent(() => import('./modules/challenges/components/BonusRewardDetails'))}
            />
            <Route
                exact
                path={ROUTES.joinTeam()}
                component={AsyncComponent(() => import('./modules/challenges/components/JoinTeamsList'))}
            />
            <Route
                exact
                path={ROUTES.teamCreate()}
                component={AsyncComponent(() => import('./modules/challenges/components/CreateEditTeam'))}
            />
            <Route
                exact
                path={ROUTES.teamEdit()}
                component={AsyncComponent(() => import('./modules/challenges/components/CreateEditTeam'))}
            />
            <Route
                exact
                path={ROUTES.teamDetails()}
                component={AsyncComponent(() => import('./modules/challenges/components/TeamDetails'))}
            />
            <Route
                exact
                path={[ROUTES.createChallenge(), ROUTES.createChallengeStep()]}
                component={AsyncComponent(() => import('./modules/challenges/components/CreateChallenge'))}
            />
            <Route
                exact
                path={ROUTES.setPersonalGoal()}
                component={AsyncComponent(() => import('./modules/challenges/components/CreateChallenge/CreateChallengePersonalGoal'))}
            />
            <Route
                exact
                path={ROUTES.editChallengeImage()}
                component={AsyncComponent(() => import('./modules/challenges/components/EditChallengeImage'))}
            />
            <Route
                exact
                path={ROUTES.userProfile()}
                component={AsyncComponent(() => import('./modules/other/components/UserProfile'))}
            />
            <Route
                exact
                path={ROUTES.teamChallengeActionSheet()}
                component={AsyncComponent(() => import('./modules/challenges/components/TeamChallengeActionSheet'))}
            />
            <Route
                exact
                path={ROUTES.EAPSelectOptions()}
                component={AsyncComponent(() => import('./modules/onboarding/components/EAPSelectOptions'), { hasFullWidthLayout: true })}
            />
            <Route
                exact
                path={ROUTES.EAPContact()}
                component={AsyncComponent(() => import('./modules/onboarding/components/Contact'), { hasFullWidthLayout: true })}
            />
            <Route
                exact
                path={ROUTES.reachGoal()}
                component={AsyncComponent(() => import('./modules/onboarding/components/ReachGoal'), { hasFullWidthLayout: true })}
            />
            <Route
                exact
                path={[ROUTES.earnPartnersList(), ROUTES.partners()]}
                component={AsyncComponent(() => import('./modules/rewards/components/EarnPartners/EarnPartnersList'))}
            />
            <Route
                exact
                path={ROUTES.partnerView()}
                component={AsyncComponent(() => import('./modules/rewards/components/EarnPartners/EarnPartnersDetail'))}
            />
            <Route
                exact
                path={ROUTES.earnPartnersDetails()}
                component={AsyncComponent(() => import('./modules/rewards/components/EarnPartners/EarnPartnersDetail'))}
            />
            <Route
                path={ROUTES.findFriendsAndFamily()}
                component={AsyncComponent(() => import('./modules/invitations/components/FindFriendsAndFamily'))}
            />
            <Route
                path={ROUTES.familyMembers()}
                component={AsyncComponent(() => import('./modules/settings/components/FamilyMembers'))}
            />
            <Route
                path={ROUTES.inviteFamilyMember()}
                component={AsyncComponent(() => import('./modules/settings/components/InviteFamilyMember'))}
            />
            <Route
                path={ROUTES.inviteByEmail()}
                component={AsyncComponent(() => import('./modules/invitations/components/InviteByEmail'))}
            />
            <Route
                path={ROUTES.tourLayout()}
                component={AsyncComponent(() => import('./modules/tours/components/TourLayout'))}
            />
            <Route
                exact
                path={[ROUTES.more(), ROUTES.userProf(), ROUTES.userStats(), ROUTES.userWellbeing()]}
                component={AsyncComponent(() => import('./modules/shared/components/More'))}
            />
            <Route
                path="*"
                component={AsyncComponent(() => import('./modules/links/components/DeepLink'))}
            />
            <Redirect to={ROUTES.home()} />
        </Switch>
    </Shared.Root>
);


const LoggedOutRoutes = ({ isGeneralLoginPage, history, actions }) => (
    <Shared.Root isLoggedIn={false}>
        <Switch>
            <Route
                exact
                render={() => {
                    const Component = AsyncComponent(() => import('./modules/auth/components/SignIn'), { hasFullWidthLayout: true });
                    return <Component isGeneralSignIn={true} />;
                }}
                path={ROUTES.generalLoggedOut()}
            />
            {isToken(history.location.pathname, actions) && <Redirect to={ROUTES.generalLoggedOut()} />}
            <Route
                exact
                path={[ROUTES.unsubscribeEmailsWithoutLogin()]}
                component={AsyncComponent(() => import('./modules/settings/components/UnsubscribeEmails'))}
            />
            {jwtUnsubscribeToken(history) && (
                <Redirect
                    to={{
                        pathname: ROUTES.unsubscribeEmailsWithoutLogin(),
                        state: { token: jwtUnsubscribeToken(history) }
                    }}
                />
            )}
            <Route
                exact
                path={[ROUTES.home()]}
                component={isGeneralLoginPage ? AsyncComponent(() => import('./modules/auth/components/SignIn'), { hasFullWidthLayout: true })
                    : AsyncComponent(() => import('./modules/auth/components/SignInWeb'), { hasFullWidthLayout: true })}
            />
            <Route
                exact
                path={[ROUTES.signUpWeb()]}
                component={AsyncComponent(() => import('./modules/auth/components/SignUpWeb'), { hasFullWidthLayout: true })}
            />
            <Route
                exact
                path={[ROUTES.signup(), ROUTES.signupStep()]}
                component={AsyncComponent(() => import('./modules/auth/components/SignUpFlow'), { hasFullWidthLayout: true })}
            />
            <Route
                exact
                path={ROUTES.resetPassword()}
                component={AsyncComponent(() => import('./modules/auth/components/ResetPassword'), { hasFullWidthLayout: true })}
            />
            <Route
                exact
                path={ROUTES.signoutSSO()}
                component={AsyncComponent(() => import('./modules/auth/components/SignOutSSO'), { hasFullWidthLayout: true })}
            />
            <Route
                exact
                path={ROUTES.employeeID()}
                component={AsyncComponent(() => import('./modules/auth/components/EmployeeIDVerification'), { hasFullWidthLayout: true })}
            />
            <Route
                exact
                path={ROUTES.HRISCountrySelection()}
                component={AsyncComponent(() => import('./modules/auth/components/HRISCountrySelection'), { hasFullWidthLayout: true })}
            />
            {isSso() && (
                <Redirect
                    to={{
                        pathname: ROUTES.signoutSSO(),
                    }}
                />
            )}
            <Route
                path="*"
                component={AsyncComponent(() => import('./modules/links/components/DeepLink'))}
            />
            <Redirect to={ROUTES.home()} />
        </Switch>
    </Shared.Root>
);

const Router = ({ history, isLoggedIn, isLoggedOut, isShowingOnboarding, isGeneralLoginPage, actions }) => {
    const pathName = _.get(history.location, 'pathname', '');
    if (isMobile) {
        if (!pathName.includes(coreConstants.DEEP_URLS.unsubscribeEmails) && !pathName.includes(coreConstants.DEEP_URLS.confirm_user)) {
            return <Shared.InstallMobileApp />;
        }
    }
    let Routes;
    if (isGeneralLoginPage || isLoggedOut) Routes = LoggedOutRoutes;
    else if (isLoggedIn) {
        Routes = LoggedInRoutes;
    }
    return (
        <ConnectedRouter history={history}>
            <LastLocationProvider>
                {Routes ?
                    <Routes
                        pathname={history.location.pathname}
                        history={history}
                        isShowingOnboarding={isShowingOnboarding}
                        isGeneralLoginPage={isGeneralLoginPage}
                        actions={actions}
                    /> : <Shared.Root isLoggedIn={false} />}
            </LastLocationProvider>
        </ConnectedRouter>
    );
};

Router.propTypes = {
    history: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isLoggedOut: PropTypes.bool.isRequired,
    isShowingOnboarding: PropTypes.bool,
    isGeneralLoginPage: PropTypes.bool,
    actions: PropTypes.object.isRequired,
};

Router.defaultProps = {
    isShowingOnboarding: false,
    isGeneralLoginPage: false,
};

LoggedInRoutes.propTypes = {
    history: PropTypes.object.isRequired,
    isShowingOnboarding: PropTypes.bool,
    pathname: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
};

LoggedInRoutes.defaultProps = {
    isShowingOnboarding: false,
};
LoggedOutRoutes.propTypes = {
    isGeneralLoginPage: PropTypes.bool,
    history: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
};

LoggedOutRoutes.defaultProps = {
    isGeneralLoginPage: false
};

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(authActions, dispatch) });

export default connect(state => ({
    isLoggedIn: authSelectors.isLoggedIn(state),
    isLoggedOut: authSelectors.isLoggedOut(state),
    isShowingOnboarding: onboardingSelectors.isShowingOnboarding(state),
    isGeneralLoginPage: coreSelectors.isGeneralLoginPage(state),
}), mapDispatchToProps)(Router);
