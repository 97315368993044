import _ from 'lodash';
import moment from 'moment';
import i18n from '../../../i18n/i18n';
import { CHALLENGE_TYPES, FREQUENCY, CHALLENGE_ENTITY_TYPES, COMPANY_ENTITIES,
    ELIGABLE_ACTIVITY_TYPES, SCORE_DISPLAY_TYPES, FORMAT_GOALS_TYPES, MAX_CARD_DISPLAY_ACTIVITIES, UNIT_TYPES, UNIT_POINTS, UNIT_QUANTITY, POINTS, CREDITS } from '../constants';
import { numbers, DATE_FORMATS, ICON_CATEGORIES, DEFAULT_ICON_CATEGORY as DEFAULT_ICON, getImageProp } from '../../core';
import { getGoalDuration } from './units';
import { pointsForPeriodPercentage } from './challengePeriodHelper';
import { groupActivityLogsByDay } from './activitiesHelper';

export const getChallengeSubtitle = (challenge, programName = '', isSubtitleShown = false, lang) => {
    const { challengeType, challengeEntityType, challengeSubentityType, isRecommended, isSolo,
        challengeInstruction, adminChallengeId } = challenge;

    let subtitle = '';
    if (challengeType === CHALLENGE_TYPES.goal && isSolo === 1) {
        subtitle = challengeType;
    } else {
        let companyProgramString = '';
        if (challengeEntityType === CHALLENGE_ENTITY_TYPES.company) {
            companyProgramString = i18n.t('company');
        }
        if (adminChallengeId) {
            companyProgramString = programName;
        }

        let teamString = '';
        if (challengeEntityType === CHALLENGE_ENTITY_TYPES.group && challengeSubentityType) {
            teamString = i18n.t('challengeCardHeader.team');
        }

        if (companyProgramString.length > 0) {
            subtitle = `${companyProgramString} `;
        }

        if (teamString.length > 0) {
            let string = teamString;
            if (subtitle.length > 0) {
                string = _.toLower(teamString);
            }

            subtitle += `${string} `;
        }

        if (subtitle.length > 0) {
            subtitle += `${_.toLower(i18n.t('challengeCardHeader.challenge'))}`;
            if (challengeEntityType === CHALLENGE_ENTITY_TYPES.group && challengeSubentityType && lang === 'fr_CA') {
                // this is an exception so I didn't add it in translation file
                subtitle = 'Défi d\'équipe';
            }
        } else {
            subtitle = i18n.t('challengeCardHeader.challenge');
        }
    }

    if (challengeType === CHALLENGE_TYPES.goal && isSolo === 1) {
        if (challengeInstruction.length > 0) {
            subtitle = '';
        } else {
            subtitle = isRecommended ? '' : i18n.t('challengeCardHeader.personal_goal');
        }
    }

    return subtitle;
};

export const getRemainingInfo = challenge => {
    if (isAfterDeadline(challenge)) {
        if (challenge.track_deadline.days_left === 0) return {};
        return {
            title: (challenge.challengeType === 'goal') ? i18n.t('goal_ended') : i18n.t('challenge_ended'),
            text: (challenge.track_deadline.days_left > 1)
                ? i18n.t('days_remaining_to_track', { num: challenge.track_deadline.days_left })
                : i18n.t('day_remaining_to_track')
        };
    }
    const days = daysLeft(challenge.challengeDeadline);
    return {
        title: days > 1 ? i18n.t('number_days', { num: days }) : i18n.t('one_day'),
        text: i18n.t('remaining')
    };
};

export const getEligableActivities = challenge => {
    const eligibleActivitiesType = _.toLower(_.get(challenge, 'eligibleActivities.0.type'));
    const activityUnitDetails = _.get(challenge, 'activityUnitDetails', []);
    const isSingleUnit = activityUnitDetails.length === 1 ? true : false;

    if (eligibleActivitiesType === ELIGABLE_ACTIVITY_TYPES.category) {
        return {
            isCategory: true,
            category: _.get(challenge, 'eligibleActivities.0')
        };
    }

    return {
        isCategory: false,
        activities: _.get(challenge, 'eligibleActivities'),
        isSingleUnit
    };
};

export const getPeriodSingleTrackedActivityValue = challenge => {
    const activityUnitDetails = _.get(challenge, 'activityUnitDetails', []);
    const eligibleActivitiesType = _.get(challenge, 'eligibleActivities.0.type');
    if (activityUnitDetails.length !== 1 || eligibleActivitiesType === ELIGABLE_ACTIVITY_TYPES.category) { return {}; }
    // The server sends index sometimes as a string, sometimes as an int!
    // eslint-disable-next-line eqeqeq
    const currentPeriod = _.find(challenge.progress.periods, period => period.index == challenge.progress.currentPeriodIndex);
    const activities = currentPeriod ? _.get(currentPeriod, 'activities', []) : [];

    let trackedValue = '0';
    if (activities.length !== 0 && activities[0].value.length > 0) {
        trackedValue = activities[0].value;
    }

    let challengeTotalUnits = 0;
    let activityUnit = {};
    let unit = '';
    let unit_singular = '';
    if (_.get(challenge, 'activityUnitDetails.length')) {
        activityUnit = challenge.activityUnitDetails[0];
        challengeTotalUnits = _.get(activityUnit, 'target_quantity');
        unit = activityUnit.unit;
        unit_singular = activityUnit.unit_singular;
    }

    return {
        trackedValue,
        unit,
        unit_singular,
        challengeTotalUnits
    };
};

export const getOverallSingleTrackedActivityValue = challenge => {
    const activityLogs = _.get(challenge, 'userActivityLogs', []);

    let trackedValue = 0;
    _.forEach(activityLogs, item => (
        trackedValue += item.quantity
    ));

    let activityUnit = {};
    let unit = '';
    if (_.get(challenge, 'activityUnitDetails.length')) {
        activityUnit = challenge.activityUnitDetails[0];
        unit = activityUnit.unit;
    }

    let challengeTotalUnits = 0;

    const periods = _.get(challenge, 'progress.periods', []);
    if (periods.length === 0) {
        challengeTotalUnits = _.get(activityUnit, 'target_quantity');
    }

    _.forEach(challenge.progress.periods, period => (
        challengeTotalUnits += _.get(activityUnit, 'target_quantity')
    ));

    return {
        trackedValue,
        unit,
        challengeTotalUnits
    };
};

export const getSingleTrackedActivityValueString = challenge => {
    const periodValue = getPeriodSingleTrackedActivityValue(challenge);
    const trackedValue = Math.round(periodValue.trackedValue * 10)/ 10;
    const unit = periodValue.challengeTotalUnits === 1 ? periodValue.unit_singular : periodValue.unit;

    return {
        trackedValue: numbers.numberWithCommas(trackedValue),
        periodValue: periodValue.challengeTotalUnits && numbers.numberWithCommas(periodValue.challengeTotalUnits),
        unit
    };
};

export const getSingleTrackedActivityRemainingProgress = (challenge, isOverallProgress = false, trackedValue) => {
    const periodValue = isOverallProgress ? getOverallSingleTrackedActivityValue(challenge) :
        getPeriodSingleTrackedActivityValue(challenge);
    const value = !_.isUndefined(trackedValue) ? trackedValue : periodValue.trackedValue;
    const result = periodValue.challengeTotalUnits - value;
    return _.isNaN(result) || result < 0 ? 0 : result;
};

export const getPeriodRemainingPoints = period => {
    const result = _removeFormatNumber(period.overallPointsTargetValue) - _removeFormatNumber(period.currentPeriodValue);
    return (_.isNaN(result) || result < 0) ? 0 : result;
};

export const getActivitiesToDisplay = (challenge, eligableActivities) => {
    if (eligableActivities.isCategory === false) {
        const activities = eligableActivities.activities;
        let activitiesLimit = MAX_CARD_DISPLAY_ACTIVITIES;
        if (activities.length > 2) {
            activitiesLimit = 1;
        }
        const displayActivities = _.take(activities, activitiesLimit);
        const moreItemsNumber = activities.length - activitiesLimit;

        let string = '';
        displayActivities.map((item, index) => (
            string += `${item.name}${index === displayActivities.length - 1 ? '' : ', '}`
        ));

        return { string, moreItemsNumber };
    }

    return {};
};

export const getConnectedDeviceName = challenge => {
    const connectedDevice = challenge.recommendedTrackingApps &&
        _.find(challenge.recommendedTrackingApps, { connected: true });
    return connectedDevice && (connectedDevice.displayName || connectedDevice.name);
};

export const getAllConnectedDevicesNames = challenge => {
    if (challenge.recommendedTrackingApps) {
        const devices = _.filter(challenge.recommendedTrackingApps, app => app.connected);
        const sortedArray = _.sortBy(_.map(devices, d => d.displayName || d.name));
        const len = sortedArray.length;
        return len > 1
            ? `${_.join(_.slice(sortedArray, 0, len - 1), ', ')} or ${_.last(sortedArray)}`
            : _.last(sortedArray);
    }
    return null;
};

export function getGoalProgress(challenge = null, isTrackingOver = false) {
    if (challenge === null) return null;
    let isCompleted = isTrackingOver;

    if (isCompletedNotInGracePeriod(challenge)) {
        isCompleted = true;
    }

    let unitsAchieved = 0;
    let challengeTotalUnits = 0;
    let activityUnit = {};

    const periodValue = challenge.frequency === FREQUENCY.total
        ? challenge.progress.overallUserValue : challenge.progress.currentPeriodValue;

    if (_.get(challenge, 'activityUnitDetails.length')) {
        activityUnit = challenge.activityUnitDetails[0];
        challengeTotalUnits = _.round(challenge.progress.overallPointsTargetValue / _.get(activityUnit, 'points', 1));
        unitsAchieved = _.round(challengeTotalUnits * periodValue / 100);
    }
    const frequency = challenge.frequency;

    const { overallPointsTargetValue } = challenge.progress;

    const eligibleActivitiesType = _.toLower(_.get(challenge, 'eligibleActivities.0.type'));

    // Calc Period Progress
    let currentPeriodValue, percentFilled;
    if (eligibleActivitiesType === ELIGABLE_ACTIVITY_TYPES.category) {
        // currentPeriodValue = overallUserValue * overallPointsTargetValue / 100;
        // percentFilled = overallUserValue;
        currentPeriodValue = periodValue * overallPointsTargetValue / 100;
        percentFilled = periodValue;
    } else {
        currentPeriodValue = unitsAchieved * _.get(activityUnit, 'points', 0);
        percentFilled = periodValue;
    }
    currentPeriodValue = _.get(challenge.progress, 'currentPeriodPoints', currentPeriodValue);

    const periodProgress = _.assign(_getGoalInfo(challenge), {
        percentFilled,
        overallPointsTargetValue,
        currentPeriodValue: _formatNumber(currentPeriodValue),
        isOverallProgress: false
    });

    // Calc Overall Progress
    const overallValue = _.reduce(challenge.progress.periods, (sum, period) => ({
        value: sum.value + pointsForPeriodPercentage(period),
        goal: sum.goal + parseFloat(period.goal)
    }), { value: 0, goal: 0 });

    const overallProgress = {
        percentFilled: _.get(challenge, 'progress.overallUserValue', 0),
        overallPointsTargetValue: overallValue.goal === 0 ? challenge.progress.overallPointsTargetValue : overallValue.goal,
        currentPeriodValue: _formatNumber(overallValue.value),
        isOverallProgress: true,
        frequency: challenge.frequency,
        title: i18n.t('my_overall_progress'),
        goalName: i18n.t('challenge_completion_bonus'),
        progressCircleHeading: i18n.t('overall'),
        earned: i18n.t('of_this_overall'),
        remaining: i18n.t('remaining_this_overall'),
        daysLeft: daysLeft(challenge.challengeDeadline),
        ofThisPeriod: 'of_this_overall',
        unitName: _.get(challenge, 'progress.unitName', ''),
        overallUserValue: _.get(challenge, 'progress.overallUserValue', 0)
    };

    //if frequency is total or tracking has finished, show only the overall progress
    return frequency === FREQUENCY.total || isCompleted || isInGracePeriod(challenge) ? [overallProgress] : [periodProgress, overallProgress];
}

function _getGoalInfo(challenge) {
    let title, goalName, progressCircleHeading, earned, remaining, ofThisPeriod;
    let days = daysLeft(challenge.challengeDeadline);
    switch (challenge.frequency) {
        case FREQUENCY.daily:
            title = 'todays_progress';
            goalName = 'daily_goal';
            progressCircleHeading = 'today';
            earned = 'earned_today';
            remaining = 'remaining_this_total';
            ofThisPeriod = 'of_this_day';
            break;
        case FREQUENCY.weekly:
            title = 'this_weeks_progress';
            goalName = 'weekly_goal';
            progressCircleHeading = 'this_week';
            earned = 'earned_this_week';
            remaining = 'remaining_this_week';
            ofThisPeriod = 'of_this_week';
            days = daysLeft(challenge.progress.currentPeriodEndDate);
            break;
        case FREQUENCY.monthly:
            title = 'this_months_progress';
            goalName = 'monthly_goal';
            progressCircleHeading = 'this_month';
            earned = 'earned_this_month';
            remaining = 'remaining_this_month';
            ofThisPeriod = 'of_this_month';
            days = daysLeft(challenge.progress.currentPeriodEndDate);
            break;
        case FREQUENCY.total:
        default:
            title = 'my_total_progress';
            goalName = 'total_goal';
            progressCircleHeading = 'overall';
            earned = 'earned_in_total';
            remaining = 'remaining';
            ofThisPeriod = 'of_this_total';
            break;
    }
    return {
        frequency: challenge.frequency,
        title: i18n.t(title),
        goalName: i18n.t(goalName),
        progressCircleHeading: i18n.t(progressCircleHeading),
        earned: i18n.t(earned),
        remaining: i18n.t(remaining),
        daysLeft: days,
        ofThisPeriod
    };
}

function _formatNumber(number) {
    return numbers.numberWithCommas(_.round(number));
}

function _removeFormatNumber(number) {
    return `${number}`.replace(',', '');
}

export function isTeamChallenge({ challengeEntityType } = {}) {
    return challengeEntityType === CHALLENGE_ENTITY_TYPES.group;
}

export function isRegionChallenge({ challengeEntityType } = {}) {
    return challengeEntityType === CHALLENGE_ENTITY_TYPES.region;
}
export function isDepartmentChallenge({ challengeEntityType } = {}) {
    return challengeEntityType === CHALLENGE_ENTITY_TYPES.department;
}
export function isRegionsOrDepartmentsChallenge({ challengeEntityType } = {}) {
    return isRegionChallenge({ challengeEntityType }) || isDepartmentChallenge({ challengeEntityType });
}

export function getDailyProgress(challenge, activityLogs, isCurrentWeek, challengeUnitIds) {
    const datesPeriod = getTotalDays(challenge, isCurrentWeek);

    const pointsByDate = _.mapValues(_.groupBy(activityLogs, 'date'), value => _.round(_.sumBy(value, 'points')));

    const trackedValuesByDate = _.mapValues(_.groupBy(activityLogs, 'date'), value => _.round(_.sumBy(value, 'quantity')));

    const maxPointsOfDailyProgress = challenge.frequency === FREQUENCY.daily
        ? challenge.progress.overallPointsTargetValue
        : (!_.isEmpty(pointsByDate) && _.max(_.values(pointsByDate))) || 0;

    return _.map(datesPeriod, date => (date.enable && pointsByDate[date.date]
        ? {
            ...date,
            value: getPercentage(pointsByDate[date.date], maxPointsOfDailyProgress),
            trackedQuantity: trackedValuesByDate[date.date]
        } : date));
}

function getTotalDays(challenge, isCurrentWeek = false) {
    const { restartGoalPreviousPeriods } = challenge.progress;
    const isSoloRestartGoal = challenge.isSolo && challenge.restartGoalTs;
    const restartGoalStartDate = restartGoalPreviousPeriods && Object.values(restartGoalPreviousPeriods)[0] ? Object.values(restartGoalPreviousPeriods)[0].startDate : challenge.startDate;
    const formattedStartDate = isSoloRestartGoal ? restartGoalStartDate : challenge.startDate;
    const currentDate = moment().format(DATE_FORMATS.full);
    const totalDays = [];
    if (!challenge) return totalDays;

    let day = isCurrentWeek ? moment().startOf('isoWeek') : moment(formattedStartDate).startOf('isoWeek');
    const end = isCurrentWeek ? moment().endOf('isoWeek') : moment(challenge.challengeDeadline).endOf('isoWeek');
    const value = 0;
    const trackedQuantity = 0;

    while (day <= end) {
        const date = day.format(DATE_FORMATS.full);
        const today = currentDate === date;
        const afterCurrentDate = date > currentDate;
        const beforeStartDate = date < formattedStartDate;
        const afterDeadline = date > challenge.challengeDeadline;
        const enable = !afterCurrentDate && !beforeStartDate && !afterDeadline;

        totalDays.push({ date, value, enable, today, afterCurrentDate, beforeStartDate, afterDeadline, trackedQuantity });

        day = day.clone().add(1, 'd');
    }
    return totalDays;
}

export function getStartOfWeek() {
    return moment().startOf('isoWeek').format(DATE_FORMATS.full);
}

function getPercentage(points, maxPointsOfDailyProgress) {
    const percentage = points / maxPointsOfDailyProgress * 100;
    return points && percentage < 1 ? 1 : percentage;
}

export function isGoalType(challenge) {
    return challenge.challengeType === CHALLENGE_TYPES.goal;
}

export function isGoal(challenge) {
    return isGoalType(challenge) && challenge.isSolo === 1;
}

export function isCompetition(challenge) {
    return challenge.challengeType === CHALLENGE_TYPES.competition;
}

export function isPersonal(challenge) {
    return !!_.get(challenge, 'isSolo');
}

export function isInvite(challenge) {
    return challenge.isMember === 0;
}

export function isMember(challenge) {
    return !!_.get(challenge, 'isMember');
}

export function isUnstarted(challenge) {
    return moment(challenge.startDate) > moment();
}

export function isStarted(challenge) {
    return moment(challenge.startDate) <= moment();
}

export function isFeatured(challenge) {
    return +challenge.isFeatured;
}

export function isInGracePeriod(challenge) {
    return isAfterDeadline(challenge) && !!challenge.track_deadline.days_left;
}

export function isAfterDeadline(challenge) {
    return !!challenge.isEnded;
}

export function isCompleted(challenge) {
    return !!challenge.isCompleted;
}

export function isCompletedNotInGracePeriod(challenge) {
    return isAfterDeadline(challenge) && !challenge.track_deadline.days_left;
}

export function isDateInChallengeRange(date, challenge) {
    return moment(date).isBetween(challenge.startDate, challenge.challengeDeadline, null, '[]');
}

export function isTodayFirstDayOfChallenge(challenge) {
    return moment(challenge.startDate).isSame(moment(), 'day');
}

export const isProgramGoal = item => item.adminChallengeId !== null;
export const isUpcoming = item => moment(item.startDate).isAfter(moment());
export const isCompanyGoal = item => _.includes(COMPANY_ENTITIES, item.challengeEntityType);

export function isBonusChallenge(challenge) {
    return !!_.get(challenge, 'isBonusChallenge');
}

export function isBonusRecommendedGoal(goal) {
    return goal.isBonusChallenge === '1';
}

export const filterProgramCompetitions = items =>
    _.filter(items, item => !item.isSolo && isProgramGoal(item) && isUpcoming(item));

export const filterCompetitions = items => _.sortBy(
    _.filter(items, item => !item.isSolo &&
        ((!isCompanyGoal(item) && !isProgramGoal(item)) || (isProgramGoal(item) && !isUpcoming(item)))),
    item => new Date(item.challengeDeadline));

export const filterPersonalGoals = items => _.sortBy(
    _.filter(items, item => item.isSolo), item => new Date(item.challengeDeadline));

export function isTrackByDevice(challenge) {
    // identify if all activities are tracking by device
    const activityUnits = _.get(challenge, 'activityUnitDetails', []);
    if (!activityUnits.length) return false;

    const activityUnitsTrackedByDeviceArr =
        _.filter(activityUnits, { unit_type: 'activity_unit', show_in_tracker: '0' });

    return activityUnits.length === activityUnitsTrackedByDeviceArr.length;
}

export function isTeamSizeIncorrect(challenge) {
    const { userEntity, minTeamMembers } = challenge;
    return _.get(userEntity, 'entityType') === CHALLENGE_ENTITY_TYPES.group && _.get(userEntity, 'numberOfUsers', 0) < minTeamMembers;
}

// from dateHelper
export function daysLeft(endDate, startDate, measurements = 'days') {
    if (!endDate) return 0;
    return moment(endDate).add(1, 'd').diff(startDate || moment(), measurements);
}

export const getSetGoalsString = count => {
    if (!count) {
        return i18n.t('wellbeing.settedGoals.noGoals');
    } else if (count === 1) {
        return i18n.t('wellbeing.settedGoals.one');
    }
    return i18n.t('wellbeing.settedGoals.n', { count });
};


export function formatRank(rank) {
    switch (rank) {
        case 1: return i18n.t('rank.first');
        case 2: return i18n.t('rank.second');
        case 3: return i18n.t('rank.third');
        default: return i18n.t('rank.n', { rank });
    }
}

export const isUserCompanyPartner = challenge => challenge.challengeEntityType === CHALLENGE_ENTITY_TYPES.user ||
    challenge.challengeEntityType === CHALLENGE_ENTITY_TYPES.company ||
    challenge.challengeEntityType === CHALLENGE_ENTITY_TYPES.partner;

export const trimScore = (scoreDisplayType, score) => {
    if (!score) return score;
    if (scoreDisplayType === SCORE_DISPLAY_TYPES.percentage) {
        return (_.toInteger(score.slice(0, -1).replace(/,/g, '')));
    }
    if (scoreDisplayType === SCORE_DISPLAY_TYPES.score || scoreDisplayType === SCORE_DISPLAY_TYPES.averageScore) {
        return (_.toInteger(score.slice(0, -3).replace(/,/g, '')));
    }
};

export function formatGoalForSetting(goal, userId) {
    const today = new Date();
    const todayString = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    const challenger = _.toString(userId);
    const duration = getGoalDuration(goal.duration, goal.durationUnits);
    const iconURL = _.get(goal, 'icon_url', null);
    const isBonusChallenge = _.get(goal, 'isBonusChallenge', 0);
    const potentialBonusPoints = _.get(goal, 'potentialBonusPoints', 0);
    const potentialBonusCurrency = _.get(goal, 'potentialBonusCurrency', 0);
    const bonusAutoReward = _.get(goal, 'bonusAutoReward', 0);
    const recommendedGoalsId = _.get(goal, 'recommendedGoalsId');
    const restartDay = _.get(goal, 'restartDay');

    let comboPoints = 0;

    let quantityType = '';
    let otherType = '';
    const quantity = [{}];
    if (goal.activity && goal.units) {
        comboPoints = goal.quantity * parseFloat(_.get(goal.units, 'points', 0));
        quantity[0].activityUnitId = goal.units.id;
        quantity[0].quantityUnits = goal.quantity;
        quantityType = FORMAT_GOALS_TYPES.activityQuantity;
        otherType = FORMAT_GOALS_TYPES.categoryQuantity;
    } else if (goal.category) {
        comboPoints = goal.quantity;
        quantity[0].categoryId = goal.category;
        quantity[0].quantityPoints = goal.quantity;
        quantityType = FORMAT_GOALS_TYPES.categoryQuantity;
        otherType = FORMAT_GOALS_TYPES.activityQuantity;
    }

    const formattedGoal = {
        challenger,
        name: goal.goalName,
        description: goal.description,
        instruction: goal.instruction,
        frequency: _.get(goal, 'frequency', 'daily').toLowerCase(),
        comboPoints,
        requirement: 'combo',
        type: 'goal',
        duration,
        start: todayString,
        solo: 1,
        entity: 'user',
        published: 0,
        public: 0,
        recommended: 1,
        imageURL: goal.imageUrl || null,
        iconURL,
        isBonusChallenge,
        potentialBonusCurrency,
        potentialBonusPoints,
        bonusAutoReward,
    };

    if (recommendedGoalsId) {
        formattedGoal.recommendedGoalsId = recommendedGoalsId;
    }

    if (restartDay) {
        formattedGoal.restartDay = restartDay;
    }

    formattedGoal[quantityType] = quantity;
    formattedGoal[otherType] = [];
    return formattedGoal;
}

export function getImage(challenge) {
    return getImageProp(challenge.challengeImageURL);
}

export function getRecommendedGoalIconColor(goal) {
    return _.get(ICON_CATEGORIES, [goal.categorySlug, 'bgr'], DEFAULT_ICON.bgr);
}

export function daysUntil(startDate, measurements = 'days') {
    if (!startDate) return 0;

    const currentDate = moment();
    return moment(startDate).diff(currentDate, measurements);
}

export function daysDuration(startDate, endDate, measurements = 'days') {
    return moment(endDate).diff(startDate, measurements) + 1;
}

export function daysUntilString(startDate) {
    const dayDiff = daysUntil(startDate);
    if (dayDiff === 0) {
        return i18n.t('startsToday');
    } else if (dayDiff === -1) {
        return i18n.t('startedADayAgo');
    } else if (dayDiff < 0) {
        return i18n.t('startedDaysAgo', { dayCount: -dayDiff });
    } else if (dayDiff === 1) {
        return i18n.t('startsInOneDay');
    }
    return i18n.t('startsInDays', { dayCount: dayDiff });
}

export function endsInString(endDate) {
    const dayDiff = daysLeft(endDate);

    if (dayDiff === 1) {
        return i18n.t('endsInOneDay');
    } else if (dayDiff === 0) {
        return i18n.t('endsToday');
    }
    return `${i18n.t('ends_in')} ${i18n.t('number_days', { num: dayDiff })}`;
}


export function getTeamSizeIncorrectMessage(challenge, customPointsName) {
    if (!challenge) return null;
    return isTeamSizeIncorrect(challenge) ?
        i18n.t('teamSizeMessage', { min: challenge.minTeamMembers, customPoints: customPointsName }) : null;
}

export function getChallengeToastData(isTeam, toastTitle, toastMsg) {
    const type = isTeam ? 'joiningTeamChallengeToast': 'challenge_toast';
    const title = toastTitle || i18n.t(`${type}.success.title`);
    const msg = toastMsg || i18n.t(`${type}.success.message`);
    return {
        title,
        msg
    };
}

export function isChallengeUpdating(challenge) {
    if (challenge.isCompleted) {
        return false;
    }

    if (challenge.challengeEntityType === CHALLENGE_ENTITY_TYPES.group && _.get(challenge, 'userEntity.numberOfUsers', 0) < challenge.minTeamMembers) {
        return false;
    }

    const userActivityLogsIds = challenge.userActivityLogs.map(al => al.activityLogId);

    let latest_tracked_activity_id;
    if (userActivityLogsIds.length > 0) {
        latest_tracked_activity_id = Math.max(...userActivityLogsIds);
    }

    //case where there is actually a latest_activity_log_id
    return latest_tracked_activity_id > _.toInteger(challenge.latest_activity_log_id);
}


// for reducer
export function addIfNotPersonal(challenge, ids = []) {
    return isPersonal(challenge) ? ids : _.uniq([...ids, challenge.challengeId]);
}

export function addIfNotPersonalFirst(challenge, ids = []) {
    return isPersonal(challenge) ? ids : _.uniq([challenge.challengeId, ...ids]);
}

export function addOneToCount(count) {
    return _.isUndefined(count) ? count : count + 1;
}

export function getDeletionString(challenge, isGoal = false) {
    let goalString = i18n.t('delete_goal_confirmation');
    if (isBonusChallenge(challenge)) goalString += ` ${i18n.t('deleteGoalBonusPoints')}`;
    return isPersonal(challenge) || isGoal ? goalString : i18n.t('delete_challenge_confirmation');
}

export const getChallengeOptions = (inviteCallback, updateCallback, isExtendedRole, role, challenge, leaveChallengeOption, deleteChallengeOption) => {
    const { challengeType, isCreator, isSolo, isMember, isEnded, isRecommended, adminChallengeId } = challenge;
    if (!challengeType || !role || !!isEnded) return [];
    const buttons = [];
    if (isSolo === 0 && isMember) {
        buttons.push({ title: i18n.t('inviteOthersToChallenge'), onPress: inviteCallback });
    }
    if (((!isSolo && isCreator) || isExtendedRole) && !isRecommended && !adminChallengeId) {
        buttons.push({ title: i18n.t('changeCover'), onPress: updateCallback });
    }
    if (challengeType === CHALLENGE_TYPES.goal) {
        //you may leave a goal if it involves more than one user (non personal)
        if (!isSolo) {
            if (isMember) {
                buttons.push(leaveChallengeOption);
            }
            //you may delete a goal if you were the creator or if you're a moderator or higher
            if (isExtendedRole || isCreator) {
                buttons.push(deleteChallengeOption);
            }
        } else if (isExtendedRole || isMember) {
            //if you're a moderator or higher you should be able to delete the personal goal
            //if you're a member of the personal goal, you created it so you should not be able to delete
            //not using isCreator field because it is not supplied in the response for personal goals
            buttons.push(deleteChallengeOption);
        }
    } else {
        if (isMember) {
            buttons.push(leaveChallengeOption);
        }
        // you may delete a challenge if you're an admin or you created it
        if ((!isExtendedRole && isCreator) || isExtendedRole) {
            buttons.push(deleteChallengeOption);
        }
    }

    return buttons;
};

export const isPreviousNotWeeklyGoal = goal => isAfterDeadline(goal) && !isInGracePeriod(goal) && isGoal(goal) && !Number(goal.restartGoalTs);
export const isUnstartedWeeklyGoal = goal => Number(goal.restartGoalTs) && isUnstarted(goal);
export const isWeeklyGoalGracePeriodEnded = goal => {
    const now = moment().unix();
    const deadline = _.get(goal, 'track_deadline.deadline_timestamp');
    return Number(goal.restartGoalTs) && now > deadline;
};

export const dateRange = (challenge, isRestartGoal, isEnded) => {
    if (!challenge || isRestartGoal) return null;
    const { startDate, challengeDeadline } = challenge;

    const startDateFormatted = moment(startDate).format(DATE_FORMATS.monthDay);
    const endDateFormatted = moment(challengeDeadline).format(DATE_FORMATS.monthDay);

    if (startDateFormatted === endDateFormatted) return startDateFormatted;

    if (isEnded) return endDateFormatted;

    const getDayMonth = date => ({
        day: moment(date).format(DATE_FORMATS.dayShort),
        month: moment(date).format(DATE_FORMATS.month)
    });

    const periodStart = getDayMonth(startDate);
    const periodEnd = getDayMonth(challengeDeadline);

    if (periodStart.month === periodEnd.month) {
        return `${periodStart.month} ${periodStart.day}-${periodEnd.day}`;
    }

    return `${startDateFormatted} - ${endDateFormatted}`;
};

export const inGracePeriodInfo = challenge => {
    const daysLeft = challenge.track_deadline.days_left;
    const dayOrDaysText = _.toLower(i18n.t(daysLeft > 1 ? 'days' : 'day'));
    const info = i18n.t('trackActivity.gracePeriod', { n: daysLeft, text: dayOrDaysText });
    const infoArray = _.split(info, ' ');
    const firstLabel = _.join(_.slice(infoArray, 0, _.indexOf(infoArray, `${daysLeft}`)), ' ');
    const secondLabel = `${daysLeft} ${dayOrDaysText}`;
    const thirdLabel = _.join(_.slice(infoArray, _.indexOf(infoArray, dayOrDaysText) + 1), ' ');

    return { firstLabel, secondLabel, thirdLabel };
};

export const getPeriodsUnit = (frequency, totalPeriods) => {
    switch (true) {
        case frequency === FREQUENCY.monthly && totalPeriods === 1:
            return 'month';
        case frequency === FREQUENCY.monthly && totalPeriods > 1:
            return 'months';
        case frequency === FREQUENCY.weekly && totalPeriods === 1:
            return 'week';
        case frequency === FREQUENCY.weekly && totalPeriods > 1:
            return 'weeks';
        case frequency === FREQUENCY.daily && totalPeriods === 1:
            return 'day';
        case frequency === FREQUENCY.daily && totalPeriods > 1:
            return 'days';
        default:
            return null;
    }
};

export const getTodayProgress = (challenge, customPointsName) => {
    const { userActivityLogs, activityUnitDetails, progress } = challenge;
    if (activityUnitDetails.length) {
        const isToday = moment().format(DATE_FORMATS.full);
        const todayActivitiesLogsArr = _.filter(userActivityLogs, activityLog => activityLog.date === isToday);
        const isFullCategory = activityUnitDetails[0].unit === UNIT_POINTS;
        const isFullActivityCategory = activityUnitDetails[0].unit_type === UNIT_TYPES.category;
        const formatedCustomPoints = getCustomPointsName(customPointsName);
        let todayTotalPoints = 0;
        let todayTotalQuantity = 0;
        _.forEach(todayActivitiesLogsArr, activity => {
            if (activityUnitDetails.length > 1 || isFullCategory) {
                todayTotalPoints += activity.points;
            }
            todayTotalQuantity += activity.quantity;
        });
        if (activityUnitDetails.length > 1 || isFullActivityCategory) {
            return {
                trackedValue: todayTotalPoints,
                unit: formatedCustomPoints,
                periodValue: progress.overallPointsTargetValue,
            };
        }
        return {
            trackedValue: isFullCategory ? todayTotalPoints : todayTotalQuantity,
            unit: activityUnitDetails[0][todayTotalQuantity === 1 ? 'unit_singular' : 'unit'],
            periodValue: activityUnitDetails[0].target_quantity || progress.overallPointsTargetValue,
        };
    }
};

export const percentHelper = (tracked, period) => ((tracked / period) * 100).toFixed();

export const getCompletedPercent = challenge => {
    const { frequency, progress, challengeType } = challenge;
    const isDailyGoal = challengeType === CHALLENGE_TYPES.goal && frequency === FREQUENCY.daily;
    const todayProgress = getTodayProgress(challenge);
    const percentages = !isDailyGoal ? percentHelper(getPeriodTotalPoints(challenge), progress.overallPointsTargetValue) : percentHelper(todayProgress.trackedValue, todayProgress.periodValue);
    return percentages >= 100 ? 100 : parseInt(percentages);
};

export const getPeriodTotalPoints = challenge => {
    const { progress, userActivityLogs } = challenge;
    const periodActivitiesLogsArr = _.filter(userActivityLogs, activityLog => moment(activityLog.date).isBetween(progress.currentPeriodStartDate, progress.currentPeriodEndDate, 'days', '[]'));
    let periodTotalPoints = 0;
    _.forEach(periodActivitiesLogsArr, activity => {
        periodTotalPoints += activity.points;
    });
    return Math.round(periodTotalPoints * 10) / 10;
};

export const oneDecimal = points => {
    const pointsString = points.toString();
    const dotIndex = pointsString.indexOf('.');
    const endIndex = dotIndex + 2;
    return pointsString.substring(0, endIndex);
};

const dailyPointsHelper = (date, challenge) => {
    const { userActivityLogs } = challenge;
    const activityLogsByDay = groupActivityLogsByDay(userActivityLogs, true);
    const foundDate = _.find(activityLogsByDay, item => item.date === date);
    const pointsOrUnitQuantity = hasMultipleActivitiesToTrack(challenge) ? UNIT_POINTS : UNIT_QUANTITY;
    return foundDate ? foundDate[pointsOrUnitQuantity] : 0;
};

export const dailyWeeksProgressHelper = (weeksProgress, weekIndex, isDaily, challenge) => {
    const isRestartGoal = +challenge.restartGoalTs && challenge.isSolo;
    const dailyProgress = [];
    const dailyProgressPoints = [];
    const restartGoalDailyProgress = [];
    _.forEach(weeksProgress[weekIndex], day => {
        const dailyPoints = dailyPointsHelper(day.date, challenge);
        const value = isDaily && day.value > 100 ? 100 : day.value;
        const restartGoalDailyValue = hasMultipleActivitiesToTrack(challenge)
            ? dailyPoints
            : day.trackedQuantity;
        dailyProgress.push(value);
        dailyProgressPoints.push(dailyPoints);
        restartGoalDailyProgress.push(restartGoalDailyValue);
    });

    if (isDaily) return dailyProgress;
    if (isRestartGoal) return restartGoalDailyProgress;
    return dailyProgressPoints;
};

export const hasMultipleActivitiesToTrack = challenge => {
    const { activityUnitDetails, activities } = challenge;
    if (activityUnitDetails) {
        return activities.length > 1
        || (activities.length === 1 && activities[0] === activityUnitDetails[0].activityCategory);
    }
};

export const getCurrentUserLeaderboardInfo = (user, rank, overallUserValue, unit) => {
    const { userId, avatarURL, firstNameDisplay, lastNameDisplay, department, location } = user;
    const name = `${firstNameDisplay} ${lastNameDisplay}`;
    const score = `${overallUserValue} ${unit}`;
    return {
        rank,
        name,
        score,
        avatarURL,
        isViewer: 1,
        itemEntityId: userId,
        detailsDisplay: {
            template: '{text}',
            values: { text: `${department}, ${location}` },
        },
    };
};

export const getCurrentUserLeaderBoardTeamInfo = (team, rank, overallUserValue, unit) => {
    const { imageURL, name, id } = team;
    const score = `${overallUserValue} ${unit}`;
    return {
        rank,
        name,
        score,
        avatarURL: imageURL,
        isViewer: 1,
        itemEntityId: id,
        detailsDisplay: {
            template: '{text}',
            values: { text: '' },
        },
    };
};

export const getSwiperMarginRight = max => {
    const defaultMargin = 20;
    if (!max) return defaultMargin;
    let index = 1;
    let num = 10;
    let margin = 36;
    while (max > num) {
        index % 2 ? num *= 2 : num *= 5;
        const addition = index === 10 ? 2 : 0;
        margin +=5 + addition;
        index += 1;
    }
    return margin;
};

export const getWeeklyProgress = dailyProgress => {
    const weeksProgress = [];
    for (let i = 0; i < dailyProgress.length; i += 7) {
        const week = dailyProgress.slice(i, i + 7);
        weeksProgress.push(week);
    }
    return weeksProgress;
};

export const getCurrentWeekIndex = weeksProgress => {
    let currentWeek = weeksProgress.length - 1;
    _.forEach(weeksProgress, (week, index) => {
        _.forEach(week, day => {
            if (day.today) {
                currentWeek = index;
            }
        });
    });
    return currentWeek;
};

export function challengeDateText(challenge) {
    return challenge.isEnded ? i18n.t('ended') : startEndString(challenge);
}

function startEndString(challenge) {
    const { startDate, challengeDeadline } = challenge;

    const startDateIncludesToday = moment(startDate).add(1, 'days');
    const endDateIncludesToday = moment(challengeDeadline).add(1, 'days');

    if (isUnstarted(challenge) || !isMember(challenge)) {
        return daysUntilString(startDateIncludesToday);
    } else {
        return endsInString(endDateIncludesToday);
    }
}

export const teamSpotsLeft = team => {
    const max = _.get(team, 'groupInfo.maxTeamMembers');
    if (max === undefined) return 0;
    return _.toInteger(max) - _.toInteger(team.userCount);
};

export const isTeamFull = team => teamSpotsLeft(team) === 0;

export const isJoinTeamVisible = (challenge, team) => !isCompleted(challenge) && !isTeamFull(team);

export const teamMembersCountText = team => team.userCount === '1' ? i18n.t('oneMember') : i18n.t('numberOfMembers', { num: team.userCount });

export const teamIsInvitedByVisible = team => {
    if (!_.get(team, 'groupInfo')) return false;

    const { invitedBy } = team.groupInfo;
    const isFull = isTeamFull(team);

    if (invitedBy && !isFull) return true;

    return false;
};

export const teamInvitedByDetails = team => {
    if (!_.get(team, 'groupInfo')) return undefined;

    const { invitedBy } = team.groupInfo;

    return inivitedByDetails(invitedBy);
};

export const inivitedByDetails = invitedBy => {
    if (!invitedBy) return undefined;

    return {
        string: `${_.get(invitedBy, 'fullname')} ${i18n.t('invited_you').toLowerCase()}`,
        name: _.get(invitedBy, 'fullname'),
        image: _.get(invitedBy, 'profile_image_url')
    };
};

export function isFeaturedChallenge(challenge) {
    return (isFeatured(challenge) && !isMember(challenge) && !isAfterDeadline(challenge));
}

export function getCustomPointsName(customPointsName) {
    return (customPointsName === UNIT_POINTS || customPointsName === POINTS) ? POINTS : CREDITS;
}

export function checkItemBounds(parentRef, childRef, itemOffset = 100) {
    if (parentRef?.current && childRef?.current) {
        const parentRect = parentRef?.current?.getBoundingClientRect();
        const childRect = childRef?.current?.getBoundingClientRect();

        const isOutside = childRect.right > parentRect.right + itemOffset ||
        childRect.left < parentRect.left;

        return isOutside ? '-1' : '0';
    }
    return '0';
}