class Apple {
    isRevoked = async () => {
        return false;
    }
}

const apple = new Apple();

export default apple;

