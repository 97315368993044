import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core, Modal } from '../../../core';
import { spacing, layoutStyle, important } from '../../../../styles';
import WithActivityLogItemBase, { styles as baseStyles } from './ActivityLogItemBase';

class ActivityLogItem extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        getPoints: PropTypes.func.isRequired,
        getQuantity: PropTypes.func.isRequired,
        deleteActivityLog: PropTypes.func.isRequired,
        device: PropTypes.object,
        getWarningProps: PropTypes.func.isRequired,
        itemHasSwipeout: PropTypes.bool.isRequired,
        itemShowsDeviceIcon: PropTypes.bool.isRequired,
        itemShowsNewStatus: PropTypes.bool.isRequired
    };

    static defaultProps = {
        device: null
    };

    get deviceIconView() {
        const { device, itemShowsDeviceIcon } = this.props;

        if (!device || !itemShowsDeviceIcon) {
            return null;
        }

        return (
            <Core.Image
                src={device.iconUrl}
                className={css(styles.image)}
                resizeMode="contain"
            />
        );
    }

    openAlert = () => {
        const { getWarningProps, deleteActivityLog } = this.props;

        const onDelete = async () => {
            await deleteActivityLog();
            this.closeWarning();
        };

        const onCloseWarning = () => this.closeWarning();

        this.closeWarning = Modal.open(
            Core.InfoModal,
            getWarningProps(onDelete, onCloseWarning),
            { isContainer: true, isNoPadding: true, isMaxWidthLimited: true, fadeTransition: true, }
        );
    };

    render() {
        const { itemHasSwipeout, title, itemShowsNewStatus, getPoints, getQuantity } = this.props;
        return (
            <Core.ListItem
                className={css(layoutStyle.listItem, layoutStyle.listItemNoLastBorder, styles.listItem)}>
                <div className={css(itemShowsNewStatus ? styles.newActivityCircle : null)} />
                <Core.ListItemText
                    primary={<span className={css(styles.rightTitle)}>{title}</span>}
                    secondary={this.deviceIconView}
                    classes={{
                        secondary: css(styles.paragraph),
                        root: css(layoutStyle.flexAlignCenter)
                    }}
                />
                <div className={css(styles.container)}>
                    <span className={css(styles.quantity)}>{getQuantity}</span>
                    <span className={css(styles.points)}>{getPoints()}</span>
                </div>
                {itemHasSwipeout ? (
                    <Core.IconButton
                        size={spacing.s4}
                        onClick={this.openAlert}
                        type="fa"
                        color="danger"
                        name="trash-alt"
                    />
                ) : null}
            </Core.ListItem>
        );
    }
}

export default WithActivityLogItemBase(ActivityLogItem);

const styles = StyleSheet.create({
    ...baseStyles,
    image: {
        height: spacing.s3,
        width: important(spacing.s3),
        marginRight: spacing.s0,
        display: important('inline-block', false),
        resizeMode: 'contain',
        marginLeft: spacing.s1
    },
    paragraph: {
        display: 'flex'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    listItem: {
        borderBottomWidth: 0,
        paddingLeft: spacing.s3,
        paddingTop: important(0),
    }
});
