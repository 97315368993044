import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { REGISTRATION_STEPS as STEPS } from '../../constants';
import { ROUTES, AsyncComponent } from '../../../core';
import WithSignUpFlowBase from './SignUpFlowBase';
import AuthWrapperWeb from '../AuthWrapperWeb';

class SignUpFlow extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        step: PropTypes.string,
        openNextPage: PropTypes.func.isRequired,
        updateLocalRegistrationStep: PropTypes.func.isRequired,
    };

    static defaultProps = {
        step: undefined,
    };

    static COMPONENTS = {
        [STEPS.programDomain]: AsyncComponent(() => import('../ProgramDomain')),
        [STEPS.findProgramDomain]: AsyncComponent(() => import('../FindDomainName')),
        [STEPS.corporatePin]: AsyncComponent(() => import('../CorporatePin')),
        [STEPS.signUpSelection]: AsyncComponent(() => import('../SignUpSelection')),
        [STEPS.register]: AsyncComponent(() => import('../Register')),
        [STEPS.location]: AsyncComponent(() => import('../CDLSelection')),
        [STEPS.disclaimer]: AsyncComponent(() => import('../Disclaimer')),
        [STEPS.verify]: AsyncComponent(() => import('../Verify')),
        [STEPS.partnerCustomSSO]: AsyncComponent(() => import('../PartnerCustomSSO')),
        [STEPS.HRISCountrySelection]: AsyncComponent(() => import('../HRISCountrySelection'))
    };

    _redirect = () => {
        this.props.history.replace(ROUTES.signup());
    };

    get component() {
        return SignUpFlow.COMPONENTS[this.props.step];
    }

    // is used from base
    openNextPage = (nextStep, userSelection = '', props = {}) => {
        if (!SignUpFlow.COMPONENTS[nextStep]) this._redirect();
        this.props.updateLocalRegistrationStep(nextStep);
        this.props.history.push(ROUTES.signupStep(nextStep), { ...props, step: nextStep });
    };

    render() {
        return this.component ?
            <AuthWrapperWeb>
                <this.component callback={this.props.openNextPage} step={this.props.step} />
            </AuthWrapperWeb> : null;
    }
}

export default withRouter(WithSignUpFlowBase(SignUpFlow));