import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { appFonts, baseColors, spacing } from '../../../../styles';
import * as rewardsActions from '../../actions';
import { PRODUCT_TYPES } from '../../constants';
import * as selectors from '../../selectors';
import { translate, Storage, Platform, PLATFORMS, ENTITIES_ACTIVE_TAB, numbers } from '../../../core';


export default function WithRewardsBase(WrappedComponent) {
    class RewardsBase extends Component {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            rewards: PropTypes.object,
            allProductsCurrentAmount: PropTypes.number,
            featuredProducts: PropTypes.array,
            featuredProductsCount: PropTypes.number,
            productsAmount: PropTypes.number,
            categories: PropTypes.array,
            categoriesWithIDs: PropTypes.array,
            externalRewardName: PropTypes.string.isRequired,
            i18n: PropTypes.object.isRequired,
            isLoading: PropTypes.bool
        };

        static defaultProps = {
            rewards: {},
            allProductsCurrentAmount: 0,
            featuredProducts: [],
            featuredProductsCount: 0,
            productsAmount: 0,
            categories: [],
            categoriesWithIDs: [],
            isLoading: false
        };

        constructor(props) {
            super(props);
            const { i18n } = props;
            this.TABS = {
                FEATURED: { id: 'featured', label: i18n.t('all') }
            };
            this.state = {
                activeTab: this.TABS.FEATURED,
                tabDidChange: false
            };

            this.props.actions.getRewards();
            this.props.actions.getRewardsCategories();
            if (!this.isExternalCatalog && this.props.rewards.isEligible) {
                this.props.actions.getRewardsProducts();
            }
        }

        async componentDidMount() {
            if (Platform.OS === PLATFORMS.web) {
                const activeTab = await Storage.getItem(ENTITIES_ACTIVE_TAB.REWARDS);
                if (activeTab) {
                    this.setState({ activeTab });
                }
            }
        }

        get isExternalCatalog() {
            return !this.props.rewards.rewardType && this.props.rewards.catalogUrl;
        }

        get isVisibleMarketingUrl() {
            return !this.props.rewards.isEligible && this.marketingUrl;
        }

        get tabsFromCategories() {
            return _.map(this.props.categories, item => ({ id: item, label: _.capitalize(item) })) || [];
        }

        get tabs() {
            return this.props.isLoading ? [] : [this.TABS.FEATURED, ...this.tabsFromCategories];
        }

        get carouselData() {
            return { externalRewardName: this.props.externalRewardName };
        }

        get numRedeemable() {
            const value = _.get(this.props.rewards, 'numRedeemable', 0);
            return numbers.numberWithCommas(parseInt(value));
        }

        get userBalance() {
            return `${this.numRedeemable} ${this.props.externalRewardName}`;
        }

        get listCategory() {
            return this.state.activeTab.id !== this.TABS.FEATURED.id ? this.state.activeTab.id : undefined;
        }

        get categoryId() {
            let categoryId;
            if (this.state.activeTab.id && this.state.activeTab.id !== this.TABS.FEATURED.id) {
                const category = _.find(this.props.categoriesWithIDs.items, category => category.name === this.state.activeTab.id);
                categoryId = category ? category.id : undefined;
            }

            return categoryId;
        }

        get listType() {
            return this.state.activeTab.id === this.TABS.FEATURED.id ? PRODUCT_TYPES.ALL : undefined;
        }

        get marketingUrl() {
            return this.props.rewards.marketingUrl;
        }

        onTabPress = tab => {
            this.setState(() => ({ activeTab: tab, tabDidChange: true }));

            if (Platform.OS === PLATFORMS.web) {
                Storage.setItem(ENTITIES_ACTIVE_TAB.REWARDS, tab);
            }
        };

        get isAllListLoaded() {
            if (this.categoryId) {
                return this.productsCountByCategory >= this.props.productsAmount;
            }
            return this.props.allProductsCurrentAmount >= this.props.productsAmount;
        }

        get productsCountByCategory() {
            return this.props.products.length;
        }

        get linksText() {
            const { externalRewardName, i18n } = this.props;
            return {
                howToEarn: i18n.t('rewardsHowDoIEarn', { currencyName: externalRewardName }),
                balanceHistory: i18n.t('balanceHistory'),
                learnMore: i18n.t('wellbeing.learnMore'),
            };
        }

        get title() { return this.props.i18n.t('rewards'); }

        loadMoreProducts = () => {
            const { isLoading, allProductsCurrentAmount, actions } = this.props;
            if ((!isLoading && !this.isAllListLoaded) || this.state.tabDidChange === true) {
                this.setState(() => ({ tabDidChange: false }));
                let params;
                if (this.categoryId) {
                    params = { offset: this.productsCountByCategory, categoryId: this.categoryId };
                } else {
                    params = { offset: allProductsCurrentAmount };
                }
                actions.getRewardsProducts(params, false);
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    tabs={this.tabs}
                    onTabPress={this.onTabPress}
                    loadMoreProducts={this.loadMoreProducts}
                    isVisibleMarketingUrl={this.isVisibleMarketingUrl}
                    activeTab={this.state.activeTab}
                    carouselData={this.carouselData}
                    numRedeemable={this.numRedeemable}
                    userBalance={this.userBalance}
                    listCategory={this.listCategory}
                    categoryId={this.categoryId}
                    listType={this.listType}
                    marketingUrl={this.marketingUrl}
                    isExternalCatalog={this.isExternalCatalog}
                    isAllListLoaded={this.isAllListLoaded}
                    headerTitle={this.title}
                    linksText={this.linksText}
                />
            );
        }
    }

    function mapStateToProps(state) {
        let products;
        if (this.listCategory) {
            products = selectors.getProductByCategory(state, this.listCategory);
        } else {
            products = selectors.getProducts(state);
        }
        return {
            rewards: selectors.getRewards(state),
            allProductsCurrentAmount: selectors.getProductsCurrentAmount(state),
            featuredProducts: selectors.getFeaturedProducts(state, true),
            featuredProductsCount: selectors.getFeaturedProductsCount(state),
            productsAmount: selectors.getProductsAmount(state),
            categories: selectors.getProductRewardsCategories(state),
            categoriesWithIDs: selectors.getRewardsCategories(state),
            isLoading: selectors.isLoadingRewards(state) || selectors.isLoadingProducts(state),
            externalRewardName: selectors.externalRewardName(state),
            products
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(rewardsActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(RewardsBase));
}

export const styles = {
    tabsContainer: {
        marginBottom: spacing.s5
    },
    indicator: {
        flex: 1,
        alignItems: 'center',
        marginTop: spacing.s3,
        marginBottom: spacing.s3,
    },
    headerContainer: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
    },
    headerMain: {
        marginTop: spacing.s0,
        marginBottom: spacing.s5,
        marginLeft: 0
    },
    headerTopPoints: {
        alignItems: 'flex-end'
    },
    headerTopNumPoints: {
        ...appFonts.mdBold
    },
    headerTopBalance: {
        ...appFonts.xsRegular,
        color: baseColors.grey40
    },
    webView: {
        flex: 1,
        backgroundColor: baseColors.white
    },
    emptyList: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
    },
    carouselTitle: {
        marginTop: 0
    },
    externalCatalogContainer: {
        flex: 1,
        justifyContent: 'center',
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        marginBottom: spacing.s7,
    },
    autoLink: {
        paddingTop: spacing.s9,
        paddingBottom: spacing.s1,
        paddingRight: spacing.s3,
        paddingLeft: spacing.s3,
        color: baseColors.grey40,
        textAlign: 'center',
        ...appFonts.xsRegular
    },
    linkText: {
        ...appFonts.mdRegular,
        marginLeft: spacing.s1,
        color: baseColors.secondary,
    },
    iconContainer: {
        paddingTop: spacing.s0,
    },
    link: {
        marginBottom: spacing.s5,
    },
};
