//import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import _ from 'lodash';
import * as types from './actionTypes';
import { NAME, LOGIN_STATUSES } from './constants';
import welcome from './assets/welcome';
import global from '../../config/globals';
import { actionTypes as coreTypes, Storage, tracker } from '../core';

export const initialState = {
    ...welcome,
    globalSettings: {
        subdomain: global.systemSubdomain,
        subdomainRequired: global.subdomainRequired,
        pin: global.pin
    },
    user: {
        username: '',
        rememberMe: true,
        login_info: {
            token: '',
            expirationDateTime: '',
            programName: ''
        },
        profileFields: [],
        otherInfo: {},
        sso: {}
    },
    login: {
        error: {},
        emailResent: false,
        registrationStep: '',
        temporaryAuthentication: {},
        registrationStatus: '',
        ssoLoginURL: ''
    },
    loginStatus: '',
    termsOfService: {},
    privacyPolicy: {},
    policyPopups: [],
    disclaimer: {},
    userLocations: [],
    currentLocation: {},
    company: {
        configurations: {},
        employeeIDStatus: '',
        employeeID: '',
        externalApps: []
    },
    corporateAccountLink: {
        data: {}
    },
    partner: {},
    locations: {},
    departments: [],
    regions: [],
    externalAccounts: [],
    loginQueryParams: {},
    HRISLocations: {},
    savedHRISLocation: {}
};

export const formFieldsBase = [
    {
        stateKey: 'firstName',
        name: 'first_name',
        required: true
    },
    {
        stateKey: 'lastName',
        name: 'last_name',
        required: true
    },
    {
        stateKey: 'preferredName',
        name: 'preferredNameOptional',
        required: false
    },
];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_LOGIN_INFO:
            return {
                ...state,
                user: {
                    ...state.user,
                    login_info: _.omit(action.payload, ['token'])
                },
                loginStatus: LOGIN_STATUSES.loggedIn
            };
        case types.SET_LOGIN_STATUS:
            return {
                ...state,
                loginStatus: action.payload
            };
        case types.UPDATE_REMEMBER_EMAIL:
            return {
                ...state,
                user: {
                    ...state.user,
                    username: action.payload.username,
                    rememberMe: action.payload.rememberMe
                }
            };
        case types.TOGGLE_REMEMBER_ME:
            return {
                ...state,
                user: {
                    ...state.user,
                    rememberMe: !state.user.rememberMe
                }
            };
        case types.LOGOUT:
            return {
                ...initialState,
                user: {
                    ...initialState.user,
                    username: state.user.username,
                    rememberMe: state.user.rememberMe,
                },
                loginStatus: LOGIN_STATUSES.loggedOut
            };
        case types.INTERNAL_STATE_RESET:
            return {
                ...initialState,
                loginStatus: state.loginStatus,
                user: {
                    ...initialState.user,
                    username: state.user.username,
                    rememberMe: state.user.rememberMe
                }
            };
        case types.GET_COMPANY_CONFIGURATION.SUCCESS: {
            const { data, isPin } = action.payload;
            return {
                ...state,
                company: { ...data, isPin },
                programName: data.enteredProgramName ? data.enteredProgramName : state.programName
            };
        }
        case types.GET_PROGRAM_DETAILS.SUCCESS: {
            return { ...state, partner: action.payload.data };
        }
        case types.CLEAR_COMPANY_CONFIGURATION:
            return {
                ...state,
                company: initialState.company
            };
        case types.LOGIN_ERROR_REGISTRATION:
            return {
                ...state,
                login: {
                    ...state.login,
                    temporaryAuthentication: action.payload.data,
                    registrationStep: action.payload.data.registration
                },
                localRegistrationStep: action.payload.data.registration
            };
        case types.CHECK_EMAIL_REGISTRATION_STATUS.SUCCESS:
            return {
                ...state,
                login: {
                    ...state.login,
                    registrationStatus: action.payload.registrationStatus,
                    ssoLoginURL: action.payload.ssoLoginURL
                },
            };
        case types.CLEAR_EMAIL_REGISTRATION_STATUS:
            return {
                ...state,
                login: {
                    ...state.login,
                    registrationStatus: ''
                },
            };
        case types.CHECK_EMPLOYEE_ID.SUCCESS:
            return {
                ...state,
                company: {
                    ...state.company,
                    employeeIDStatus: action.payload.status,
                    employeeID: action.payload.employeeId
                }
            };
        case types.CHECK_EMPLOYEE_ID.ERROR: {
            return {
                ...state,
                company: {
                    ...state.company,
                    employeeIDStatus: action.payload.status,
                }
            };
        }
        case types.SET_USER_SSO.SUCCESS:
            return {
                ...state,
                company: {
                    ...state.company,
                    companyId: action.payload.company_id || state.company.companyId
                },
                user: {
                    ...state.user,
                    sso: {
                        ...state.user.sso,
                        ...action.payload
                    }
                }
            };
        case types.CLEAR_USER_SSO:
            return {
                ...state,
                user: {
                    ...state.user,
                    sso: {}
                }
            };
        case coreTypes.GET_CURRENT_USER.SUCCESS: {
            const externalAccounts = _.has(action.payload, 'data.externalAccounts')
                ? _.filter(action.payload.data.externalAccounts, acc => !_.isEmpty(acc))
                : state.externalAccounts;
            return {
                ...state,
                externalAccounts,
                currentLocation: { ...state.currentLocation, department_name: action.payload.data.department || _.get(state.currentLocation, 'department_name') }
            };
        }
        case types.LINK_EXTERNAL_ACCOUNT.SUCCESS: {
            return {
                ...state,
                externalAccounts: [
                    ...state.externalAccounts,
                    { external_account_type: action.payload.accountLinkType, active: '1' }
                ]
            };
        }
        case types.UNLINK_EXTERNAL_ACCOUNT.SUCCESS: {
            return {
                ...state,
                externalAccounts: _.filter(state.externalAccounts,
                    acc => acc.external_account_type !== action.payload.accountLinkType)
            };
        }
        case types.UNLINK_CORPORATE_ACCOUNT.SUCCESS: {
            return {
                ...state,
                externalAccounts: _.filter(state.externalAccounts,
                    acc => acc.external_account_type !== action.payload.accountLinkType)
            };
        }
        case types.GET_EMAIL_PROGRAM_NAME.SUCCESS:
            return {
                ...state,
                emailProgramName: action.payload
            };
        case types.GET_TERMS_OF_SERVICE.SUCCESS:
            return {
                ...state,
                termsOfService: action.payload.data
            };
        case types.GET_PRIVACY_POLICY.SUCCESS:
            return {
                ...state,
                privacyPolicy: action.payload.data
            };
        case types.GET_POLICY_POPUPS.SUCCESS:
            return {
                ...state,
                policyPopups: action.payload.data
            };
        case types.GET_DISCLAIMER.SUCCESS:
            return {
                ...state,
                disclaimer: action.payload.data
            };
        case types.CREATE_ACCOUNT.SUCCESS:
            return {
                ...state,
                createAccount: action.payload.data
            };
        case types.GET_LOCATIONS.SUCCESS:
            return {
                ...state,
                locations: action.payload.data
            };
        case types.GET_DEPARTMENTS.SUCCESS:
            return {
                ...state,
                departments: action.payload.data
            };
        case types.GET_REGIONS.SUCCESS:
            return {
                ...state,
                regions: action.payload.data
            };
        case types.GET_USER.SUCCESS: {
            const profileFields = formFieldsBase.map(item => ({
                ...item,
                // originalFirstName is only there if the user has the 'Preferred First Name (optional)' set
                value: item.stateKey === 'firstName' ? action.payload.data.originalFirstName || action.payload.data[item.stateKey] : action.payload.data[item.stateKey],
                readOnly: _.includes(action.payload.data.readOnly || [], item.stateKey)
            }));
            return {
                ...state,
                user: {
                    ...state.user,
                    otherInfo: {
                        ...state.user.otherInfo,
                        ...action.payload.data
                    },
                    profileFields
                }
            };
        }
        case types.VERIFY_EMAIL.ERROR: {
            return {
                ...state,
                user: {
                    ...state.user,
                    otherInfo: {
                        ...state.user.otherInfo,
                        isVerified: false
                    }
                }
            };
        }
        case types.UPDATE_USER.SUCCESS: {
            return {
                ...state,
                user: {
                    ...state.user,
                    otherInfo: {
                        ...state.user.otherInfo,
                        ...action.payload.data
                    }
                }
            };
        }
        case types.SET_SSO_DETAILS_FROM_CURRENT_USER.SUCCESS: {
            return {
                ...state,
                user: {
                    ...state.user,
                    sso: {
                        ...state.user.sso,
                        ...action.payload.data
                    }
                }
            };
        }
        case types.GET_USER_LOCATIONS.SUCCESS:
        case types.SET_USER_LOCATIONS.SUCCESS: {
            const currentLocation = _.find(action.payload.data, location => location.current === true);
            return {
                ...state,
                userLocations: action.payload.data,
                currentLocation: {
                    ...state.currentLocation,
                    ...currentLocation
                }
            };
        }
        case types.UPDATE_LOCAL_REGISTRATION_STEP:
            return {
                ...state,
                localRegistrationStep: action.payload.step
            };
        case types.UPDATE_LOCAL_LOGIN_STEP:
            return {
                ...state,
                localLoginStep: action.payload.step
            };
        case types.UPDATE_CORPORATE_ACCOUNT_LINK_DATA:
            return {
                ...state,
                corporateAccountLink: {
                    data: (action.payload.replace === true) ? { ...action.payload.data } : { ...state.corporateAccountLink.data, ...action.payload.data }
                }
            };
        case types.SAVE_LOGIN_QUERY_PARAMS:
            return {
                ...state,
                loginQueryParams: action.payload
            };
        case types.GET_HRIS_LOCATIONS.SUCCESS:
            return {
                ...state,
                HRISLocations: action.payload.data
            };
        case types.SET_HRIS_LOCATION: {
            const { hrisLocation, registrationFields } = action.payload;
            return {
                ...state,
                savedHRISLocation: hrisLocation,
                company: {
                    ...state.company,
                    configurations: {
                        ...state.company.configurations,
                        registrationFields
                    }
                }
            };
        }
        case types.GET_COMPANY_EXTERNAL_APPS.SUCCESS: {
            const { externalApps } = action.payload;
            return {
                ...state,
                company: {
                    ...state.company,
                    externalApps
                }
            };
        }
        default:
            return state;
    }
};

const serialize = data => {
    try {
        return JSON.stringify(data);
    } catch (err) {
        const message = 'Failed to serialize auth data';
        const serializeError = JSON.stringify({
            message,
            err
        });
        tracker.logError(serializeError);
        return JSON.stringify({});
    }
};

const persistConfig = {
    key: NAME,
    storage: Storage.storageType(),
    whitelist: ['user', 'company'],
    serialize
};

export default persistReducer(persistConfig, reducer);
