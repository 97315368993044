import moment from 'moment';
import _ from 'lodash';
import global from '../../config/globals';
import * as types from './actionTypes';
import * as api from './api';
import * as selectors from './selectors';
import {
    actions as coreActions, constants as coreConstants, normalize, getRequestId, getUniqueId, healthKit, fetchBlob, DATE_FORMATS,
    getBaseUrl, Platform, PLATFORMS
} from '../core';
import { actions as challengesActions } from '../challenges';
import { selectors as authSelectors } from '../auth';
import { actions as rewardActions } from '../rewards';
import i18n from '../../i18n/i18n';
import { appsDevicesHelper } from './services';
import { validateError } from '../../config';
import systemBuildVariant, { BUILDS } from '../../config/buildVariant';

const { addToast } = coreActions;

const isLiveBetter = () => systemBuildVariant === BUILDS.mylivebetter;

export function startConnecting() {
    return function (dispatch) {
        dispatch({
            type: types.START_CONNECTING,
            payload: {
                isConnecting: true
            }
        });
    };
}

export function finishConnecting(isSuccess = true) {
    return function (dispatch) {
        dispatch({
            type: types.FINISH_CONNECTING,
            payload: {
                isConnecting: false,
                isSuccessfullyConnected: isSuccess
            }
        });
    };
}

export function getAppsDevices() {
    return function (dispatch) {
        dispatch({ type: types.GET_APPS_DEVICES.REQUEST });
        return api.getAppsDevices()
            .then(res => {
                dispatch({
                    type: types.GET_APPS_DEVICES.SUCCESS,
                    payload: normalize.normalizeArray(res.data, 'vendorId')
                });
                _.forEach(res.data, device => {
                    if (!appsDevicesHelper.deviceHasWarning(device) && !appsDevicesHelper.deviceHasError(device)) {
                        dispatch(updateDeviceNextWarningDate(device.vendorId, true));
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_APPS_DEVICES.ERROR });
            }));
    };
}

export function getDevice(id = null) {
    return function (dispatch) {
        dispatch({ type: types.GET_DEVICE.REQUEST });
        return api.getAppsDevices()
            .then(res => {
                dispatch({
                    type: types.GET_DEVICE.SUCCESS,
                    payload: normalize.normalizeArray(res.data, 'vendorId')
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_DEVICE.ERROR });
            }));
    };
}

export function disconnectDevice(device, actionType='disconnectingDevice') {
    return function (dispatch) {
        dispatch({ type: types.DISCONNECT_DEVICE.REQUEST });
        return api.disconnectDevice(device.vendorId)
            .then(res => {
                dispatch({
                    type: types.DISCONNECT_DEVICE.SUCCESS,
                    payload: {
                        vendorId: device.vendorId,
                        toast: {
                            actionType,
                            type: coreConstants.TOAST_TYPES.SUCCESS,
                            msg: i18n.t(`${actionType}Toast.success.message`, { name: device.displayName })
                        }
                    }
                });
                dispatch(getAppsDevices());
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.DISCONNECT_DEVICE.ERROR,
                    payload: {
                        error,
                        isErrorStore: true,
                        toast: {
                            actionType,
                            type: coreConstants.TOAST_TYPES.DANGER,
                            msg: i18n.t(`${actionType}Toast.danger.message`, { name: device.displayName })
                        }
                    }
                });
            }));
    };
}

export function syncDevice(device, actionType='syncingDevice') {
    return function (dispatch) {
        const { vendorId } = device;
        dispatch({
            type: types.SYNC_DEVICE.REQUEST,
            payload: { requestId: getRequestId(types.SYNC_DEVICE.NAME, vendorId) }
        });
        return api.syncDevice(device)
            .then(res => {
                dispatch({
                    type: types.SYNC_DEVICE.SUCCESS,
                    payload: {
                        requestId: getRequestId(types.SYNC_DEVICE.NAME, vendorId),
                        vendorId,
                        data: res.data,
                        toast: {
                            actionType,
                            type: coreConstants.TOAST_TYPES.SUCCESS,
                            msg: i18n.t(`${actionType}Toast.success.message`, { name: device.displayName })
                        }
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.SYNC_DEVICE.ERROR,
                    payload: {
                        error,
                        toast: {
                            actionType,
                            type: coreConstants.TOAST_TYPES.DANGER,
                            msg: i18n.t(`${actionType}Toast.danger.message`, { name: device.displayName })
                        }
                    }
                });
            }));
    };
}

export function addIosDevice(device, actionType='connectingDevice', callback = null) {
    return function (dispatch) {
        const params = {
            vendorId: device.vendorId,
            serviceUserId: getUniqueId()
        };
        return api.addIosDevice(params)
            .then(res => {
                dispatch(connectDevice());
                if (callback) { callback(false); }
                dispatch(submitHealthKitData(device, false, true, actionType));
                dispatch(initiateAppleHealthBackgroundSync(device, getBaseUrl(''), getUniqueId()));
            })
            .catch(error => validateError(error, error => {
                dispatch(addToast(
                    coreConstants.TOAST_TYPES.DANGER,
                    actionType,
                    i18n.t(`${actionType}Toast.danger.message`, { name: device.displayName })
                ));
                if (callback) { callback(false); }
            }));
    };
}

export function submitHealthKitData(device, isManualSync=false, showToast = true, actionType='syncingDevice') {
    return function (dispatch, getState) {
        const { vendorId } = device;
        dispatch({
            type: types.SYNC_DEVICE.REQUEST,
            payload: { requestId: getRequestId(types.SYNC_DEVICE.NAME, vendorId) }
        });
        healthKit.getHealthKitData().then(res => {
            const params = {
                vendorId: device.vendorId,
                serviceUserId: getUniqueId(),
                data: res
            };
            const isLoggedOut = authSelectors.isLoggedOut(getState());
            return api.submitHealthKitData(params)
                .then(res => {
                    let payload = {
                        requestId: getRequestId(types.SYNC_DEVICE.NAME, vendorId),
                        vendorId,
                        data: res.data
                    };
                    if (isManualSync) {
                        payload = {
                            ...payload,
                            ...showToast && {
                                toast: {
                                    actionType,
                                    msg: i18n.t(`${actionType}Toast.success.message`, { name: device.displayName })
                                }
                            }
                        };
                    }
                    dispatch(getAppsDevices());
                    dispatch(challengesActions.getActivityLogs({ type: types.SYNC_DEVICE.SUCCESS, payload }));
                    dispatch(challengesActions.getPersonalGoalsAndChallenges());
                    dispatch(rewardActions.getRewards());
                })
                .catch(error => validateError(error, error => {
                    dispatch({
                        type: types.SYNC_DEVICE.ERROR,
                        payload: {
                            error,
                            toast: !isLoggedOut ? {
                                actionType,
                                msg: i18n.t(`${actionType}Toast.danger.message`, { name: device.displayName })
                            } : null
                        }
                    });
                }));
        });
    };
}

// TODO: finish connectDevice()
export function connectDevice(actionType='connectingDevice') {
    return async function (dispatch, getState) {
        dispatch(getAppsDevices());
        dispatch(addToast(coreConstants.TOAST_TYPES.SUCCESS, actionType));
        dispatch(finishConnecting(true));
        // if (selectors.hasAppDevicesCard(getState())) {
        //     await dispatch(setUserTaskComplete(APPS_DEVICES_INFO));
        //     await dispatch(setUserTaskComplete(APPS_DEVICES));
        //     dispatch(getUserTasks());
        // }
    };
}

export function clearDisconnectionError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(types.DISCONNECT_DEVICE.NAME));
    };
}

export function hideAppsDevicesInformationText() {
    return function (dispatch) {
        dispatch({ type: types.HIDE_APPS_DEVICES_INFORMATION_TEXT });
    };
}

// from system
export function deviceConnectOauthReceiveCode(url) {
    return function (dispatch) {
        if (!fetchBlob) return;
        dispatch(startConnecting());
        fetchBlob.fetch('GET', url)
            .then(res => {
                const info = res.info();
                if (info.headers['Content-Type'].includes('text/html')) {
                    const text = res.text();
                    if (text.includes('status=success') || text.includes('Success - Device Added')) {
                        dispatch(finishConnecting(true));
                        dispatch(addToast(coreConstants.TOAST_TYPES.SUCCESS, 'connectingDevice'));
                    } else {
                        dispatch(finishConnecting(false));
                        console.log(text);
                    }
                    dispatch(getAppsDevices());
                }
                else {
                    dispatch(finishConnecting(false));
                }
            })
            .catch(error => validateError(error, error => {
                dispatch(finishConnecting(false));
                console.log(error);
            }));
    };
}

export function updateDeviceNextWarningDate(vendorId, resetWarnings = false) {
    return function (dispatch, getState) {
        if (resetWarnings) {
            const nextWarningDate = undefined;
            const nextWarningInterval = 0;
            dispatch({ type: types.UPDATE_DEVICE_WARNING_DATE, payload: { vendorId, nextWarningDate, nextWarningInterval } });
        } else {
            const deviceWarning = selectors.getDeviceWarningDatesByVendorId(getState(), vendorId);

            let nextWarningInterval = 0;

            if (deviceWarning) {
                if (deviceWarning.nextWarningInterval !== undefined) {
                    nextWarningInterval = deviceWarning.nextWarningInterval;
                }
            }

            if (nextWarningInterval !== 30) {
                nextWarningInterval += 5;
            }

            const nextWarningDate = moment().add(nextWarningInterval, 'd').format(DATE_FORMATS.full);
            dispatch({ type: types.UPDATE_DEVICE_WARNING_DATE, payload: { vendorId, nextWarningDate, nextWarningInterval } });
        }
    };
}

export function initNativeApps() {
    return function (dispatch, getState) {
        const state = getState();
        if (!selectors.isAppsOrDevicesAvailable(state)) {
            dispatch(getAppsDevices())
                .then(() => {
                    if (Platform.OS === PLATFORMS.ios) {
                        dispatch(initHealthKit());
                    }
                });
        }
        else if (Platform.OS === PLATFORMS.ios) {
            dispatch(initHealthKit());
        }
    };
}

export function initHealthKit() {
    return function (dispatch, getState) {
        if (Platform.OS !== PLATFORMS.ios) { return; }
        const connectedDevices = selectors.getConnectedDevices(getState());
        const deviceItem = _.find(connectedDevices, { vendor: coreConstants.HEALTH_KIT });
        if (deviceItem) {
            healthKit.isAvailable((err, available) => {
                if (err || !available) {
                    console.log('error initializing Healthkit or not available');
                    return;
                }
                healthKit.initHealthKit(isLiveBetter() ? healthKit.liveBetterHealthKitPermissions : healthKit.sproutHealthKitPermissions, (err, results) => {
                    if (err) {
                        console.log('error initializing Healthkit');
                        return;
                    }
                    dispatch(submitHealthKitData(deviceItem));
                    dispatch(initiateAppleHealthBackgroundSync(deviceItem, getBaseUrl(''), getUniqueId()));
                });
            });
        }
    };
}

export function initiateAppleHealthBackgroundSync(item, baseurl, deviceId) {
    return function (dispatch) {
        // It's healthkit and it's this device being connected
        // It's possible that the user login to another iOS device on the same account
        if (Platform.OS === PLATFORMS.ios) {
            healthKit.initSproutBackgroundTask({
                token: global.token,
                url: baseurl,
                vendorId: item.vendorId,
                vendorName: 'iOS Health',
                deviceId
            }, () => {
            });
        }
    };
}
