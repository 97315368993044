import { serviceIsEnabledForPlatform } from './SurvicateServiceBase';

// eslint-disable-next-line no-unused-vars
const survicateSrc = 'https://survey.survicate.com/workspaces/6df39e0296e31d3aeb009247e1bd2d3f/web_surveys.js';

export function initializeWithDetails(companyName, userLanguage) {
    if (serviceIsEnabledForPlatform()) {
        // Set user traits like companyName, and language
        /* eslint-disable quotes, quote-props */
        // (function (d, s, id) {
        //     if (d.getElementById(id)) return;
        //     const js = d.createElement(s);
        //     js.id = id;
        //     js.src = survicateSrc;
        //     js.onload = () => {
        //         // window._sva.setVisitorTraits({
        //         //     "companyName": companyName,
        //         //     "userLanguage": userLanguage,
        //         // });
        //     };
        //     document.body.appendChild(js);
        // }(document, 'script', 'survicateTraits'));
        /* eslint-disable quotes, quote-props */
    }
}

/*
Note: The ID string below needs to be set to the Survicate survey ID once it is created.
It can be copied from the URL when editing: the string before /editor (https://panel.survicate.com/#/o/112987/w/72879/surveys/1bc477ce74a3c67f/editor/create/questions).
*/
export function invokeWithEvent(surveyID) {
    if (serviceIsEnabledForPlatform()) {
        // (function (d, s, id) {
        //     if (d.getElementById(id)) return;
        //     const js = d.createElement(s);
        //     js.id = id;
        //     js.src = survicateSrc;
        //     js.onload = () => {
        //         window._sva.showSurvey(surveyID);
        //     };
        //     document.body.appendChild(js);
        // }(document, 'script', 'survicate'));
    }
}
