import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';

import { NAME, GOOGLE_FIT, HEALTH_KIT, TABS } from './constants';
import { selectors as coreSelectors, getRequestId, PLATFORMS } from '../core';
import * as types from './actionTypes';
import I18n from '../../i18n/i18n';
import Platform from '../core/services/Platform';
import { appsDevicesHelper } from './services';

export const getAllDevicesItems = state => state[NAME].items;
export const getAllDevicesIds = state => state[NAME].ids;
export const getAllDevices = createSelector(getAllDevicesItems, getAllDevicesIds, (items, ids) => _.orderBy(_.filter(_.map(ids, id => items[id]), _.identity), ['displayName'], ['asc']));
export const getDeviceByVendorId = createSelector(
    getAllDevicesItems,
    coreSelectors.getSecondParam,
    (devices, vendorId) => devices[vendorId]
);

export const getAppsDevicesCategories = createSelector(getAllDevices, devices => _.union(...(_.map(devices, 'categories'))));
export const getCategoriesTabs = createSelector(getAppsDevicesCategories, categories => _.map(categories, item => ({ id: item, label: item })));
export const getTabs = createSelector(
    getCategoriesTabs,
    categories => [{ id: TABS.all, label: I18n.t('all') }, ...categories]
);

export const getConnectedDevices = createSelector(getAllDevices, devices => _.filter(devices, { connected: true }));
export const getSyncedDevices = createSelector(getConnectedDevices, devices => {
    return _.filter(devices, device => moment(device.lastSyncDate).isValid());
});
export const getSortedConnectedDevices = createSelector(getConnectedDevices, devices => _.sortBy(devices, item => item.displayName));
export const getConnectedDevicesNames = createSelector(getConnectedDevices, devices => _.join(_.map(devices, item => item.displayName)));
export const getNumberOfConnectedDevices = createSelector(getConnectedDevices, devices => devices.length);

export const getLastSyncedDevice = createSelector(getConnectedDevices, devices => _.maxBy(devices, 'lastSyncDate'));
export const getSyncStatus = createSelector(getLastSyncedDevice, device => {
    if (device) {
        const timestamp = moment(device.lastSyncDate).fromNow();
        return `${device.displayName} ${I18n.t('last_synced_lc')}: ${timestamp}`;
    }
    return I18n.t('no_connected_devices');
});
export const getLastSyncDate = createSelector(getSyncedDevices, devices => {
    const lastSyncedDevice = _.maxBy(devices, 'lastSyncDate');
    return _.get(lastSyncedDevice, 'lastSyncDate');
});

export const getAvailableDevices = createSelector(
    getAllDevices,
    devices => (
        _.sortBy(
            _.filter(
                devices,
                device => !device.connected
            ), device => device.displayName
        )
    )
);

export const getDisconnectionError = state => coreSelectors.getError(state, types.DISCONNECT_DEVICE.NAME);

export const isConnecting = state => state[NAME].isConnecting;
export const isSuccessfullyConnected = state => state[NAME].isSuccessfullyConnected;

export const isLoadingDevices = state => coreSelectors.isLoading(state, types.GET_APPS_DEVICES.NAME);
export const isLoadingDevice = state => coreSelectors.isLoading(state, types.GET_DEVICE.NAME);
export const isDisconnectingDevice = state => coreSelectors.isLoading(state, types.DISCONNECT_DEVICE.NAME);
export const isSyncingDevice = (state, vendorId) => coreSelectors.isLoading(state, getRequestId(types.SYNC_DEVICE.NAME, vendorId));

export const getPlatformDevice = createSelector(getAllDevices, devices => {
    if (Platform.OS === PLATFORMS.ios) {
        return _.find(devices, device => _.get(device, 'vendor') === HEALTH_KIT);
    } else if (Platform.OS === PLATFORMS.android) {
        return _.find(devices, device => _.get(device, 'vendor') === GOOGLE_FIT);
    }
    return null;
});

export const getRecommendedDevices = createSelector(getAllDevices, coreSelectors.getSecondParam, getPlatformDevice, coreSelectors.isLiveBetter,
    (allDevices, recommended, platformDevice) => {
        let devices = recommended;
        if (!devices) return [];
        devices = appsDevicesHelper.filterUnsupportedDevices(devices);
        const connectedDevices = _.filter(devices, device => device.connected);
        const filteredDevices = connectedDevices.length ? connectedDevices : devices;

        const recommendedAppIds = _.map(filteredDevices, a => a.vendorId);
        const filtered = _.sortBy(_.filter(allDevices, a => _.includes(recommendedAppIds, a.vendorId)), item => item.displayName);
        const isPlatformIncluded = _.includes(filtered, platformDevice) &&_.get(platformDevice, 'connected');
        return _.uniq(_.compact([isPlatformIncluded && platformDevice, ...filtered]));
    }
);

export const isAnyRecommendedConnected =
    createSelector(getRecommendedDevices, devices => _.some(devices, device => device.connected));


export const getDevicesList = createSelector(
    getSortedConnectedDevices,
    getAvailableDevices,
    (sorted, available) => [
        ...(sorted.length ? [{ data: sorted }] : []),
        { data: _.filter(available, device => {
            const filterConditionBase = (Platform.OS !== PLATFORMS.android || _.get(device, 'vendor') !== HEALTH_KIT);
            return filterConditionBase;
        })
        }
    ]
);
export const isHiddenAppsDevicesInformationText = state => state[NAME].isHiddenAppsDevicesInformationText;

export const getDevicesSupportedActivites = createSelector(getDeviceByVendorId, device => _.orderBy(device.syncedData));

export const getIsAtLeastOneDeviceInError = state => {
    const devices = getAllDevices(state);
    const errorDevices = _.filter(devices, device => {
        const warning = getDeviceWarningDatesByVendorId(state, device.vendorId);
        return appsDevicesHelper.deviceHasError(device) && appsDevicesHelper.deviceStatusMuteHasExpired(device, warning);
    });
    return errorDevices.length >= 1;
};

export const getIsAtLeastOneDeviceInWarning = state => {
    const devices = getAllDevices(state);
    const warnDevices = _.filter(devices, device => {
        const warning = getDeviceWarningDatesByVendorId(state, device.vendorId);
        return appsDevicesHelper.deviceHasWarning(device) && appsDevicesHelper.deviceStatusMuteHasExpired(device, warning);
    });
    return warnDevices.length >= 1;
};

export const isAppsOrDevicesAvailable = createSelector(getAllDevices, devices => devices.length);

export const getAllDeviceWarningDates = state => state[NAME].warningDates;
export const getDeviceWarningDatesByVendorId = createSelector(
    getAllDeviceWarningDates,
    coreSelectors.getSecondParam,
    (devices, vendorId) => devices[vendorId]
);
