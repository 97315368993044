import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { translate, DATE_FORMATS, selectors as coreSelectors } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { selectors as rewardSelectors } from '../../../rewards';
import { filteredActivities, filterDeletedActivities, getDateName, getDateString } from '../../services/activitiesHelper';

const LOAD_NUMBER = 10;
const MAX_COUNT = 100;

export default function WithActivityLogListBase(WrappedComponent) {
    class ActivityLogListBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            activities: PropTypes.array,
            actions: PropTypes.object.isRequired,
            externalRewardName: PropTypes.string,
            isRewardsEnabled: PropTypes.bool.isRequired,
            customPointsUnit: PropTypes.string.isRequired,
        };

        static defaultProps = {
            activities: [],
            externalRewardName: '',
        };

        constructor(props) {
            super(props);
            const endDate = moment().format(DATE_FORMATS.full);
            props.actions.getActivityLogsExtended(undefined, endDate, MAX_COUNT, 0);
            this.state = this.initState;
        }

        componentDidUpdate(prevProps, prevState) {
            if (!prevProps.activities.length && this.props.activities.length) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState(() => this.initState);
            }

            if (prevProps.isLoading === true && this.props.isLoading === false) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState(() => ({ isLoadingMore: false }));
            }
        }

        get initState() {
            return {
                activitiesToRender: _.take(this.activities, LOAD_NUMBER),
                loadsQuantity: this.activities && this.activities.length ? 1 : 0,
                offset: 1,
                isLoadingMore: false,
            };
        }

        onEndReached = () => {
            // If there is nothing else to read, just return
            if (!this.props.hasMore) {
                return;
            }

            this.setState(prevState => {
                const renderedLength = prevState.activitiesToRender.length;
                const end = renderedLength + LOAD_NUMBER;
                const nextChunk = _.slice(this.activities, renderedLength, end);
                if (!nextChunk.length && !this.props.isLoading) {
                    const endDate = moment().format(DATE_FORMATS.full);
                    this.props.actions.getActivityLogsExtended(undefined, endDate, MAX_COUNT, MAX_COUNT * this.state.offset);
                    this.setState(prevState => ({
                        offset: prevState.offset + 1,
                        isLoadingMore: true,
                    }));
                }
                return {
                    activitiesToRender: [...prevState.activitiesToRender, ...nextChunk],
                    loadsQuantity: prevState.loadsQuantity + 1,
                    isLoadingMore: true,
                };
            });
        };

        getDateString = date => getDateString(date);

        getDateName = date => getDateName(date, this.props.i18n);

        get activities() {
            return filteredActivities(this.props.activities);
        }

        get activitiesToRender() {
            return filterDeletedActivities(this.state.activitiesToRender, this.activities);
        }

        get canLoadMore() {
            return this.props.activities.length >= this.state.activitiesToRender.length;
        }

        get noActivitiesTitle() {
            return this.props.i18n.t('no_tracked_activities');
        }

        get trackButtonText() {
            return this.props.i18n.t('track_activity');
        }

        get isLoadingMore() {
            return this.state.isLoadingMore;
        }

        get encouragementText() {
            const { i18n, isRewardsEnabled, customPointsUnit, externalRewardName } = this.props;
            return isRewardsEnabled
                ? i18n.t('earn_withRewards', { pts: customPointsUnit, currency: externalRewardName })
                : i18n.t('earn_noRewards', { pts: customPointsUnit });
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    loadMore={this.loadMore}
                    canLoadMore={this.canLoadMore}
                    onEndReached={this.onEndReached}
                    isLoadingMore={this.isLoadingMore}
                    getDateString={this.getDateString}
                    getDateName={this.getDateName}
                    trackButtonText={this.trackButtonText}
                    noActivitiesTitle={this.noActivitiesTitle}
                    activitiesToRender={this.activitiesToRender}
                    encouragementText={this.encouragementText}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        activities: selectors.getGroupedActivityLogsExtended(state),
        isLoading: selectors.isLoadingActivityLogsExtended(state),
        hasMore: selectors.hasMoreActivityLogsExtended(state),
        isRewardsEnabled: rewardSelectors.isRewardsEnabled(state),
        customPointsUnit: coreSelectors.getCustomPointsUnit(state),
        externalRewardName: rewardSelectors.getExternalRewardName(state),
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ActivityLogListBase));
}

const ENCOURAGEMENT_TEXT_MAX_WIDTH = 468;

export const styles = {
    subtitleContainer: {
        backgroundColor: baseColors.grey85,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        flexDirection: 'row',
    },
    dateName: {
        ...appFonts.smBold,
        paddingRight: spacing.s1
    },
    date: {
        color: baseColors.grey40,
        ...appFonts.smRegular,
    },
    listContainer: {
        borderColor: baseColors.white,
        marginTop: 0
    },
    emptyComponent: {
        paddingRight: spacing.s5,
        paddingLeft: spacing.s5,
        paddingTop: spacing.s7,
        paddingBottom: spacing.s7,
        marginTop: spacing.s3,
        borderRadius: spacing.s1,
        backgroundColor: baseColors.grey90,
    },
    emptyStateTitle: {
        ...appFonts.mdRegular,
        color: baseColors.grey40,
        paddingTop: spacing.s4
    },
    encouragementText: {
        ...appFonts.smRegular,
        marginTop: spacing.s3,
        color: baseColors.grey40,
        marginBottom: spacing.s5,
        textAlign: 'center',
        maxWidth: ENCOURAGEMENT_TEXT_MAX_WIDTH
    },
    plusCircle: {
        marginRight: spacing.s1
    },
};