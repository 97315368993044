import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
// eslint-disable-next-line no-unused-vars
import { translate, tracker, LANGUAGES, selectors as coreSelectors, color, BRAND_COLOR } from '../../../core';
import { selectors as authSelectors, actions as authActions } from '../../../auth';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function withUpdatedPolicyPopupBase(WrappedComponent) {
    class UpdatedPolicyPopupBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            isTelus: PropTypes.bool,
            updatedPolicies: PropTypes.array,
            currentUser: PropTypes.object,
            company: PropTypes.object.isRequired,
            isRetail: PropTypes.bool,
        };

        static defaultProps = {
            isTelus: false,
            updatedPolicies: [],
            currentUser: {},
            isRetail: false
        };

        componentDidMount() {
            this.getPolicies();
        }

        getPolicies = () => {
            const { currentUser, actions } = this.props;

            const params = (currentUser.companyId) ? { companyId: currentUser.companyId } :
                { partnerId: currentUser.partnerId, isRetail: true };

            actions.getTermsOfService(params);
            actions.getPrivacyPolicy(params);
        };

        getDisclaimer = () => {
            if (this.props.isRetail) {
                this.props.actions.getDisclaimer({ disclaimerId: this.props.company.partnerId, isRetail: true });
            } else {
                this.props.actions.getDisclaimer({ disclaimerId: this.props.company.companyId });
            }
        };

        saveRef = ref => (this.wrapped = ref);

        dismissModal = isFooterBtn => _.has(this, 'wrapped.dismissModal') && this.wrapped.dismissModal(isFooterBtn);

        onButtonPress = (isFooterBtn = true) => {
            const { updatedPolicies, actions } = this.props;

            _.map(updatedPolicies, policy => (
                actions.markUpdatedPolicyAsSeen(policy)
            ));

            this.dismissModal(isFooterBtn);
        };

        get title() {
            return this.props.i18n.t('updatedPoliciesPopup.title');
        }

        get details() {
            return this.props.i18n.t('updatedPoliciesPopup.details');
        }

        get disclaimerText() {
            return this.props.i18n.t('updatedPoliciesPopup.disclaimer');
        }

        get buttonText() {
            return this.props.i18n.t('continue');
        }

        get image() {
            return this.props.isTelus
                ? require('../../../../image/updatedPolicies/updatedPolicies_Telus.jpg')
                : require('../../../../image/updatedPolicies/updatedPolicies_Sprout.jpg');
        }

        get updatedPolicies() {
            const { updatedPolicies } = this.props;

            if (updatedPolicies.length > 0) return updatedPolicies;

            return [];
        }

        policyTitleByType = type => {
            const { i18n } = this.props;
            let titleString;

            switch (type) {
                case 'policy':
                    titleString = i18n.t('auth.signUp.privacyPolicy');
                    break;
                case 'terms':
                    titleString = i18n.t('auth.signUp.termsOfService');
                    break;
                case 'disclaimer':
                    titleString = i18n.t('disclaimer');
                    break;
                default:
                    titleString = i18n.t('auth.signUp.termsOfService');
                    break;
            }

            return titleString;
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onButtonPress={this.onButtonPress}
                    title={this.title}
                    image={this.image}
                    details={this.details}
                    disclaimerText={this.disclaimerText}
                    buttonText={this.buttonText}
                    ref={this.saveRef}
                    updatedPolicies={this.updatedPolicies}
                    policyTitleByType={this.policyTitleByType}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) =>
        ({
            updatedPolicies: authSelectors.getUpdatedPoliesForPopup(state),
            isTelus: coreSelectors.isTelus(state),
            currentUser: coreSelectors.getCurrentUser(state),
            company: authSelectors.getCompany(state),
            isRetail: authSelectors.isRetail(state),
        });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ ...authActions }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(UpdatedPolicyPopupBase));
}

export const styles = {
    button: {
        height: spacing.s12-2,
        marginTop: spacing.s1,
        ...appFonts.mdMedium
    },
    buttonSection: {
        paddingTop: spacing.s2,
        paddingLeft: spacing.s2,
        paddingRight: spacing.s2,
    },
    mainContainer: {
        paddingLeft: spacing.s2,
        paddingRight: spacing.s2,
        paddingBottom: spacing.s3,
        paddingTop: spacing.s4,
    },
    title: {
        ...appFonts.xlBold,
        paddingBottom: spacing.s3
    },
    subtitle: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
        paddingBottom: spacing.s5
    },
    disclaimer: {
        ...appFonts.smRegular,
        color: baseColors.grey20,
        textAlign: 'center',
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1,
        paddingBottom: spacing.s3
    },
    policyRow: {
        ...appFonts.mdBold,
        color: baseColors.secondary,
        paddingBottom: spacing.s2
    }
};