import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import * as actions from '../../actions';
import { getActivityLogById } from '../../selectors';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { selectors as deviceSelectors } from '../../../appsdevices';
import { pointsHelper, translate, selectors as coreSelectors, getTimeString } from '../../../core';

export default function WithActivityLogItemBase(WrappedComponent) {
    class ActivityLogItemBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            activityLog: PropTypes.object.isRequired,
            activityLogId: PropTypes.number.isRequired,
            i18n: PropTypes.object.isRequired,
            device: PropTypes.object,
            itemHasSwipeout: PropTypes.bool,
            itemShowsDeviceIcon: PropTypes.bool,
            itemShowsNewStatus: PropTypes.bool,
            customPointsUnit: PropTypes.string.isRequired
        };

        static defaultProps = {
            itemHasSwipeout: true,
            itemShowsDeviceIcon: true,
            itemShowsNewStatus: false,
            device: null
        };

        get getQuantity() {
            const { quantity, unit } = this.props.activityLog;
            return Number.isInteger(quantity) ? `${quantity} ${unit}` : getTimeString(quantity, unit);
        }

        get title() {
            const { activityName } = this.props.activityLog;
            return `${activityName}`;
        }

        getPoints = () => pointsHelper.formatPoints(_.round(_.get(this.props.activityLog, 'points', 0), 1), this.props.customPointsUnit);

        get deleteTitle() {
            const { activityName, quantity, unit } = this.props.activityLog;
            return this.props.i18n.t('delete_activity_title', {
                measurement: `${quantity} ${unit}`,
                activity_name: activityName
            });
        }

        deleteActivityLog = () => {
            const { actions, activityLog } = this.props;
            actions.deleteActivityLog(activityLog.activityLogId);
        };

        getWarningProps = (okHandler, cancelHandler) => {
            const { i18n } = this.props;
            return {
                isButtonVisible: false,
                title: i18n.t('areYouSure'),
                text: this.deleteTitle,
                checkboxLabel: i18n.t('deleteCommunityCheckbox'),
                buttons: [
                    { text: i18n.t('button_delete_activity'), onPress: () => okHandler(), isDangerText: true, isDisabled: true },
                    { text: i18n.t('button_cancel'), onPress: cancelHandler ? () => cancelHandler() : null },
                ],
            };
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    getQuantity={this.getQuantity}
                    getPoints={this.getPoints}
                    getWarningProps={this.getWarningProps}
                    deleteActivityLog={this.deleteActivityLog}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const activityLog = getActivityLogById(state, ownProps.activityLogId);
        let device = null;
        if (activityLog) {
            device = activityLog.vendorId ? deviceSelectors.getDeviceByVendorId(state, activityLog.vendorId) : null;
        }
        return {
            activityLog,
            device,
            customPointsUnit: coreSelectors.getCustomPointsUnit(state)
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ActivityLogItemBase));
}

const CIRCLE_SIZE = spacing.s2;

export const styles = {
    titleStyle: {
        ...appFonts.mdBold,
        marginLeft: spacing.s0,
        marginBottom: spacing.s0,
        top: spacing.s1
    },
    subtitle: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
    },
    subtitleSection: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        marginLeft: spacing.s0
    },
    rightTitle: {
        ...appFonts.mdBold,
        color: baseColors.black
    },
    listItemRightContainer: {
        flex: 0
    },
    marginLeftNone: {
        marginLeft: 0,
    },
    newActivityCircle: {
        width: CIRCLE_SIZE,
        height: CIRCLE_SIZE,
        borderRadius: CIRCLE_SIZE/2,
        marginRight: spacing.s1,
        backgroundColor: baseColors.secondary
    },
    deviceIcon: {
        height: spacing.s3,
        width: spacing.s3,
        marginRight: spacing.s0,
        marginLeft: spacing.s1,
    },
    quantity: {
        ...appFonts.smBold,
        justifyContent: 'flex-end',
        alignSelf: 'flex-end'
    },
    points: {
        color: baseColors.grey40,
        alignSelf: 'flex-end',
        ...appFonts.smRegular
    }
};